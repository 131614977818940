import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteValue,
} from "@mui/material";

type SelectItem = {
  label: string;
  value: string;
};

interface CustomAutocompleteProps<
  T extends SelectItem,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
> extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    "options" | "value" | "renderInput"
  > {
  sx?: any;
  label?: string;
  options: readonly T[]; // You can customize this if you want it to be optional or different
  value: Multiple extends true
    ? T[]
    : FreeSolo extends true
    ? string | T | null
    : T | null; // Explicitly match the type for `value`
  onChange?: (a: any, e: any) => void;
  renderInput: (params: any) => JSX.Element;
}

const AutoComplete = <
  T extends SelectItem,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>(
  props: CustomAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
) => {
  const {
    value,
    label,
    options = [],
    onChange: handleChange,
    multiple,
    renderInput,
    ...rest
  } = props;

  return (
    <Autocomplete
      {...rest}
      fullWidth
      disablePortal
      value={
        value as AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>
      }
      onChange={handleChange}
      id="combo-box-demo"
      options={options}
      renderInput={renderInput}
      multiple={multiple}
    />
  );
};

export default AutoComplete;
