import { useState } from "react";
import {
  Box,
  MenuItem,
  Select,
  SelectProps,
  Typography,
  Checkbox,
  OutlinedInput,
  ListItemText,
  SelectChangeEvent,
  Chip,
} from "@mui/material";
import { FieldErrors } from "react-hook-form";
import isEmpty from "lodash/isEmpty";

import styles from "./selectinput.module.css";
import { colors } from "../../theme/colors";

interface SelectItem {
  id: number;
  label: string;
  value: string;
}

interface SelectInputProps extends SelectProps {
  register: any;
  label?: string;
  errors?: FieldErrors;
  data?: SelectItem[];
}

function MutliSelectInput(props: SelectInputProps) {
  const {
    label,
    data,
    placeholder,
    required,
    register,
    name = "",
    error,
    errors = {},
  } = props;

  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    setSelectedValues(typeof value === "string" ? value.split(",") : value);
  };

  const handleDelete = (value: string) => {
    setSelectedValues(
      selectedValues.filter((selectedValue) => {
        return selectedValue !== value;
      })
    );
  };

  return (
    <Box sx={{ flex: 1, flexDirection: "column" }}>
      <Typography className={styles.inputLabel} sx={{ display: "flex" }}>
        {label}
        {required && (
          <Typography sx={{ color: "red", marginLeft: 0.7 }}>{" *"}</Typography>
        )}
      </Typography>
      <Select
        sx={{ display: "flex" }}
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        displayEmpty
        multiple
        {...register(name)}
        value={selectedValues}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selected: string[]) => {
          if (isEmpty(selected)) {
            return (
              <Typography sx={{ color: colors.grey }}>{placeholder}</Typography>
            );
          }
          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((item) => (
                <Chip
                  key={item}
                  label={item}
                  onDelete={() => {
                    handleDelete(item);
                  }}
                  clickable={false}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                />
              ))}
            </Box>
          );
        }}
        error={error}
      >
        {data?.map((item) => (
          <MenuItem key={item.id} value={item.value}>
            <Checkbox checked={selectedValues.indexOf(item.value) > -1} />
            <ListItemText primary={item.value} />
          </MenuItem>
        ))}
      </Select>
      {errors[name] && (
        <Typography sx={{ color: colors.red, fontSize: 12, marginTop: 0.5 }}>
          {errors[name].message?.toString() ?? ""}
        </Typography>
      )}
    </Box>
  );
}

export default MutliSelectInput;
