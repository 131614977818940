import { Box, Tab, Tabs } from "@mui/material";

type TabProp = {
  selectedTab: string | undefined;
  handleChange: any;
  options: any;
};

const CustomTab = ({ selectedTab, handleChange, options }: TabProp) => {
  return (
    <Box sx={{ backgroundColor: "#fff" }}>
      <Tabs value={selectedTab} onChange={handleChange}>
        {options.map((option: any) => {
          return (
            <Tab
              sx={{ marginRight: 5, fontSize: 14 }}
              value={option.value}
              label={option.label}
              key={option.value}
              wrapped
            />
          );
        })}
      </Tabs>
    </Box>
  );
};

export default CustomTab;
