import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import style from "./specimen.module.css";

// custom component imports
import { colors } from "../../theme/colors";
import Table from "../../components/DataTable/Table";
import { CustomTab } from "../../components";
import { CustomMenu } from "../../components/Menu";

//Icons imports
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { ReactComponent as Qr } from "../../assets/svg/Qr.svg";
import { ReactComponent as Lab } from "../../assets/svg/Lab.svg";
import { ReactComponent as Signature } from "../../assets/svg/Signature.svg";
import { ReactComponent as Disease } from "../../assets/svg/Disease.svg";
import { ReactComponent as Specimens } from "../../assets/svg/Specimens.svg";
import { ReactComponent as Bucket } from "../../assets/svg/EmptyBucket.svg";

const SpecimenForm = () => {
  const [query] = useState("");
  const [selectedTab, setSelectedTab] = useState("Request Form");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const tabs = [
    { label: "Request Form", value: "Request Form" },
    { label: "Event Log", value: "Event Log" },
    { label: "Discrepancy Details", value: "Discrepancy Details" },
  ];

  // for request form
  const doctorInfo = [
    { label: "Doctor Full Name", value: "Dr. Roberta FOX" },
    { label: "Room", value: "Room Name" },
    { label: "Hospital ID", value: "15294829" },
  ];

  const patientInfo = [
    { label: "Full Name", value: "James NEWTON" },
    { label: "Date Of Birth", value: "22/07/1989" },
    { label: "Surgeon", value: "Dr. Robertax Fox" },
    { label: "Gender", value: "Male" },
    { label: "MRN", value: "87903603" },
  ];

  const laboratoryInfo = [
    { label: "Laboratory Name", value: "Douglass Hanly Moir Pathology Sydney" },
    { label: "Work Address", value: "333/339 George St, Sydney NSW 2000" },
    { label: "Email", value: "georgestreet@dhm.com.au" },
    { label: "Phone", value: "(02) 9221 5560" },
  ];

  // for event log table
  const fields = [
    { id: "name", value: "Specimen", flex: 1 },
    { id: "mrn", value: "Timestamp", type: "address", flex: 1 },
    {
      id: "drop_off_time",
      value: "User",
      flex: 1.2,
    },
    { id: "action", value: "Action", flex: 1 },
  ];

  const logData = {
    items: [
      {
        name: "Patient Name",
        mrn: "89003899",
        drop_off_time: "12-10-2015",
        action: "Printed Label",
      },
      {
        name: "Patient Name",
        mrn: "89003899",
        drop_off_time: "12-10-2015",
        action: "Printed Label",
      },
      {
        name: "Patient Name",
        mrn: "89003899",
        drop_off_time: "12-10-2015",
        action: "Printed Label",
      },
      {
        name: "Patient Name",
        mrn: "89003899",
        drop_off_time: "12-10-2015",
        action: "Printed Label",
      },
      {
        name: "Patient Name",
        mrn: "89003899",
        drop_off_time: "12-10-2015",
        action: "Printed Label",
      },
      {
        name: "Patient Name",
        mrn: "89003899",
        drop_off_time: "12-10-2015",
        action: "Printed Label",
      },
      {
        name: "Patient Name",
        mrn: "89003899",
        drop_off_time: "12-10-2015",
        action: "Printed Label",
      },
      {
        name: "Patient Name",
        mrn: "89003899",
        drop_off_time: "12-10-2015",
        action: "Printed Label",
      },
    ],
    count: 21,
  };

  // Discrepancy details
  const hospitalDetail = [
    {
      label: "Hospital",
      value: "Royal North Shore Hospital",
    },
    {
      label: "Patient Name",
      value: "Charlie, Garcia",
    },
    {
      label: "Patient MRN",
      value: "87349955",
    },
    {
      label: "Patient DOB",
      value: "29/07/1987",
    },
    {
      label: "Doctor",
      value: "Dr. Antonio Farnandes",
    },
  ];

  const discrepancyField = [
    { id: "name", value: "Specimen", flex: 2 },
    { id: "storage", value: "Storage" },
    {
      id: "collection_time",
      value: "Collection Date & Time",
    },
    { id: "overall_status", value: "Status", type: "statusBadge" },
  ];

  const discrepancyDetail = {
    items: [
      {
        name: "Biopsy, GIT, Oesophagus",
        storage: <Bucket />,
        collection_time: "26/07/2023   14:23",
        overall_status: "Ready For Pickup",
      },
      {
        name: "Biopsy, GIT, Gastric Antrum",
        storage: <Bucket />,
        collection_time: "26/07/2023   14:25",
        overall_status: "No Specimens",
      },
      {
        name: "Biopsy, Stomach, Body, MCS, H pylori ",
        storage: <Bucket />,
        collection_time: "26/07/2023   14:24",
        overall_status: "No Specimens",
      },
      {
        name: "Biopsy, Stomach, Antrum, MCS, H pylori ",
        storage: <Bucket />,
        collection_time: "26/07/2023   14:55",
        overall_status: "No Specimen",
      },
      {
        name: "Biopsy, Small intestine, Dissaccardiase",
        storage: <Bucket />,
        collection_time: "26/07/2023   14:44",
        overall_status: "No Specimens",
      },
    ],
    count: 21,
  };

  // for custom menu
  const handleEdit = () => {
    console.log("Edit clicked");
  };

  const handleArchive = () => {
    console.log("Archive clicked");
  };

  const menuItems = [
    {
      icon: <EditIcon />,
      text: "Edit",
      onClick: handleEdit,
    },
    {
      icon: <ArchiveIcon />,
      text: "Archive",
      onClick: handleArchive,
    },
  ];

  return (
    <Box mt="20px">
      <CustomTab
        options={tabs}
        selectedTab={selectedTab}
        handleChange={handleChange}
      />

      {/* Request form  */}
      {selectedTab === "Request Form" && (
        <Box className={style.boxContainer}>
          <Typography p={2} variant="h4" fontWeight="bold">
            Request Form: 23GWPHFA001
          </Typography>
          <Grid container spacing={4}>
            <Grid item textAlign="center" mt={1} xs={2.2}>
              <Typography className={style.lightHeading}>
                Request Form QR Code
              </Typography>
              <Qr className={style.qrContainer} />
              <Typography variant="h5" fontWeight="bold">
                23GWPHFA001
              </Typography>
            </Grid>

            <Grid item xs={9.8}>
              <Typography variant="h5" fontWeight="bold">
                Laboratory
              </Typography>
              <Box
                border="none"
                display="flex"
                minHeight="184px"
                className={style.container}
              >
                {/* first grid colums  */}
                <Box mt={2}>
                  <Lab />
                </Box>
                <Grid container mx={3}>
                  {laboratoryInfo.map((e) => {
                    return (
                      <Grid item key={e.label} xs={6}>
                        <Typography mt={2} className={style.lightHeading}>
                          {e.label}
                        </Typography>
                        <Typography className={style.heading}>
                          {e.value}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Grid>
          </Grid>
          {/* Patient detail and operating details  */}
          <Grid container spacing={3} mt={0}>
            <Grid xs={6} item>
              <Typography variant="h5" fontWeight="bold">
                Patient Details
              </Typography>
              <Box minHeight="230px" className={style.container}>
                <Grid container>
                  {patientInfo.map((e) => {
                    return (
                      <Grid item key={e.label} xs={6}>
                        <Typography mt={2} className={style.lightHeading}>
                          {e.label}
                        </Typography>
                        <Typography className={style.heading}>
                          {e.value}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Grid>
            <Grid xs={6} item>
              <Typography variant="h5" fontWeight="bold">
                Operating Details
              </Typography>
              <Box minHeight="230px" className={style.container}>
                <Grid container>
                  {doctorInfo.map((e) => {
                    return (
                      <Grid item key={e.label} xs={6}>
                        <Typography mt={2} className={style.lightHeading}>
                          {e.label}
                        </Typography>
                        <Typography className={style.heading}>
                          {e.value}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Grid>
          </Grid>
          {/* Clinic notes and copy to section  */}
          <Grid container spacing={3} mt={0}>
            <Grid xs={6} item>
              <Typography variant="h5" fontWeight="bold">
                Client Notes:
              </Typography>
              <textarea className={clsx(style.textArea, style.container)} />
            </Grid>
            <Grid xs={6} item>
              <Typography variant="h5" fontWeight="bold">
                Copy to:
              </Typography>
              <Box className={style.container}>
                <Typography mt={2} className={style.lightHeading}>
                  Dr Robert Smith Plastic Surgeon 1529 Opus AvenueSydney, NSW
                  2000ddrrob@familydoctors.com.au Medical Records, North Shore
                  Private Hospital Records
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" fontWeight="bold">
                Tests
              </Typography>
              <textarea
                defaultValue={
                  "Biopsy, GIT, Oesophagus Biopsy, GIT, Gastric AntrumBiopsy, Stomach, Body, MCS, H pylori Biopsy, Stomach, Antrum, MCS, H pylori Biopsy, Small intestine, Disaccardiase"
                }
                className={clsx(style.textArea, style.container)}
              />
            </Grid>
          </Grid>
          <FormControlLabel
            sx={{ mt: 1 }}
            control={<Checkbox defaultChecked />}
            label={
              <Typography className={style.heading}>Urgent report </Typography>
            }
          />
          <Typography my={1} className={style.heading}>
            Report needed by 27/12/2023
          </Typography>
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label={
              <>
                <Typography className={style.heading}>
                  Send reports to{" "}
                  <Link sx={{ color: colors.lightBlack }}>
                    My Health Record
                  </Link>
                </Typography>
              </>
            }
          />
          <Typography my={1} variant="h5" fontWeight="bold">
            Doctor’s Signature
          </Typography>
          <Signature />
          <Typography my={1} variant="body1" fontWeight="light">
            Date Signed: 27 / 07 / 2023
          </Typography>
          <Typography my={3} variant="h5" fontWeight="bold">
            This Document has an attachments
          </Typography>
          <Disease />
          <Disease />
          <Disease />
        </Box>
      )}

      {/* Event log */}
      {selectedTab === "Event Log" && (
        <Box className={style.tableContainer}>
          <Table<any>
            fields={fields}
            data={logData}
            filters={{ title: query }}
            onChange={() => ""}
            defaultOrderBy="name"
            paginationRequired={false}
          />
        </Box>
      )}
      {/* Discrepancy Details  */}
      {selectedTab === "Discrepancy Details" && (
        <Box bgcolor={colors.white} className={style.boxContainer}>
          <Box className={style.flexTop}>
            {hospitalDetail.map((e: any) => (
              <Box key={e.label} className={style.row}>
                <Typography width="15%">{e.label}</Typography>
                <Typography fontWeight="bold">{e.value}</Typography>
              </Box>
            ))}
            <Box className={style.row}>
              <Typography width="15%">Overall Status</Typography>
              <CustomMenu label="Action Required" menuItems={menuItems} />
            </Box>
          </Box>
          <Box py={2}>
            <Table<any>
              fields={discrepancyField}
              data={discrepancyDetail}
              paginationRequired={false}
              filters={{ title: query }}
              onChange={() => ""}
              defaultOrderBy="name"
            />
          </Box>
          <Box display="flex" alignItems="center">
            <AttachFileIcon fontSize="medium" sx={{ rotate: "45deg" }} />
            <Typography px={2} className={style.mediumHeading}>
              Notes
            </Typography>
          </Box>
          <Box
            justifyContent="space-between"
            className={clsx(style.boxContainer, style.row)}
          >
            <Typography width="40%">
              <Link fontWeight="bold" sx={{ color: colors.dark }}>
                5. Biopsy, Small intestine, Disaccardiase <br />
              </Link>
              Label is Printed but no specimen taken 2 in bag not 3
            </Typography>
            <Typography fontWeight="bold">User/Nurse Shalini Shinde</Typography>
            <Specimens className={style.imgBorder} />
          </Box>
          <Box
            justifyContent="space-between"
            className={clsx(style.boxContainer, style.row)}
          >
            <Typography width="40%">
              <Link fontWeight="bold" sx={{ color: colors.dark }}>
                5. Biopsy, Small intestine, Disaccardiase <br />
              </Link>
              Label is Printed but no specimen taken 2 in bag not 3
            </Typography>
            <Typography fontWeight="bold">User/Nurse Shalini Shinde</Typography>
            <Box>No image found !</Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SpecimenForm;
