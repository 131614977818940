export const Prefix = [
  { id: 1, label: "Mr", value: "Mr" },
  { id: 2, label: "Ms", value: "Ms" },
  { id: 3, label: "Mrs", value: "Mrs" },
];

export const adminPrefix = [
  { id: 1, label: "Mr", value: "Mr" },
  { id: 2, label: "Ms", value: "Ms" },
  { id: 3, label: "Dr", value: "Dr" },
  { id: 4, label: "Prof", value: "Prof" },
  { id: 5, label: "A/Prof", value: "A/Prof" },
];

export const surgeonPrefix = [
  { id: 1, label: "Dr.", value: "Dr." },
  { id: 2, label: "Prof.", value: "Prof." },
  { id: 3, label: "A/Prof.", value: "A/Prof." },
];
