import { createTheme } from "@mui/material/styles";

import inputStyle from "../components/UserInput/input.module.css";
import { colors } from "./colors";

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      dark: colors.dark,
      light: colors.white,
    },
    secondary: {
      main: colors.lighterBlack,
      dark: colors.black,
      light: colors.grey,
    },
    error: {
      main: colors.red,
    },
  },
  typography: {
    allVariants: {
      fontFamily: "-apple-system, system-ui, BlinkMacSystemFont",
      textTransform: "none",
    },
  },
  components: {
    MuiInput: {
      defaultProps: {
        className: inputStyle.inputField,
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          "&:before": {
            border: `1px solid ${colors.modalBackground}`,
          },
          color: colors.lightYellow,
        },
        tooltip: {
          color: colors.lightBlack,
          backgroundColor: colors.lightYellow,
          border: `1px solid ${colors.darkModalBackground}`,
        },
      },
    },
  },
});

export default theme;
