export const colors = {
  primary: "#004A95",
  white: "#ffffff",
  hightlight: "#ffffff1a",
  dark: "rgb(38, 38, 38)",
  black: "#000000",
  modalHeading: "#303F9F",
  lightYellow: "#faeaa7",
  modalBackground: "#FED7AA",
  darkModalBackground: "#FAC473",
  error: "#F10917",
  success: "#15803D",
  grey: "#A3A3A3",
  lightPrimary: "#6D9AC7",
  lightGrey: "#E5E5E5",
  lighterGrey: "#CFCFCF",
  lightestGrey: "#FAFAFA",
  lightBlack: "#262626",
  lighterBlack: "#171717",
  darkRed: "#B22222",
  darkerRed: "#8B0000",
  red: "#D32F2F",
  lightRed: "#FECACA",
  lightGreen: "#BBF7D0",
  darkGreen: "#4DA154",
};
