import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

// importing custom component
import { CustomInput } from "../../components/UserInput";
import { Button, Message } from "../../components";
import { resetPasswordSchema } from "../../yup";

// importing icons and colors
import { ReactComponent as Pegasus } from "../../assets/svg/PegasusLogo.svg";
import style from "../../components/UserInput/input.module.css";
import { colors } from "../../theme/colors";

// importing services
import { useResetPasswordMutation } from "../../services/api";

type ShowType = "password" | "confirmPassword";
type FormState = typeof resetPasswordSchema.__outputType;

// Reset password component
function ResetPassword() {
  // Navigation hook to handle page navigation
  const navigate = useNavigate();
  // Reset password mutation hook
  const [resetPassword] = useResetPasswordMutation();

  // React hook from react-router-dom for accessing the current location
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<LabelMessageType | null>(null);

  // Extract query parameters from the location's search string using URLSearchParams
  const query = new URLSearchParams(location.search);

  // Retrieve the value of the 'token' parameter from the query string
  const token = query.get("token");

  const [show, setShow] = useState<Record<ShowType, boolean>>({
    password: false,
    confirmPassword: false,
  });

  /*
   * useForm hook from react-hook-form
   * Manages form state, validation, and submission logic.
   * - register: Registers input fields and gathers their values.
   * - handleSubmit: Triggers the provided callback on form submission.
   * - formState: Provides access to form state, such as errors
   * - yupResolver(resetPasswordSchema): Restrict the type of payload data for required, min length etc
   */
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormState>({
    resolver: yupResolver(resetPasswordSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  // Handle form submission
  const onSubmit = async (values: FormState) => {
    try {
      if (token) {
        setIsLoading(true);
        const { password, confirmPassword } = values;
        const result = await resetPassword({
          password,
          confirm_password: confirmPassword,
          token,
        }).unwrap();
        setIsLoading(false);
        toast(result?.message ?? "");
        setTimeout(() => {
          navigate("/login", { replace: true });
        }, 1000);
      } else {
        setMessage({
          type: "error",
          text: "Something went wrong !",
        });
      }
    } catch (error: any) {
      setMessage({
        type: "error",
        text: error?.data?.detail?.toString() || "Please enter valid details",
      });
      setIsLoading(false);
    }
  };

  const handleShow = (fieldType: ShowType) => {
    setShow({
      ...show,
      [fieldType]: !show[fieldType],
    });
  };

  // Render the reset password form
  return (
    <Box
      sx={{
        display: "grid",
        placeItems: "center",
        height: "calc(100vh - 97px)",
      }}
    >
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
        }}
        className={style.boxShadow}
      >
        <Box
          sx={{
            width: "395px",
          }}
          noValidate
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box textAlign="center" paddingBlockEnd={"20px"}>
            <Pegasus />
          </Box>

          <Typography
            textAlign="center"
            noWrap
            component="p"
            fontSize={16}
            fontWeight={700}
            paddingBlockEnd="20px"
          >
            Reset Your Password
          </Typography>
          <Box>
            <Box>
              <CustomInput
                name="password"
                label="Password"
                type={show["password"] ? "text" : "password"}
                value={watch("password")}
                isError={errors.password}
                isLoading={false}
                register={register}
                // icon props
                icon="eye"
                showIcon={true}
                handleIconClick={() => handleShow("password")}
              />
            </Box>
            <Box mt="20px">
              <CustomInput
                name="confirmPassword"
                label="Confirm Password"
                type={show["confirmPassword"] ? "text" : "password"}
                value={watch("confirmPassword")}
                isError={errors.confirmPassword}
                isLoading={false}
                register={register}
                // icon props
                icon="eye"
                showIcon={true}
                handleIconClick={() => handleShow("confirmPassword")}
              />
            </Box>
          </Box>
          {message && <Message message={message} />}

          <Button
            type="submit"
            variant="contained"
            sx={{
              width: "100%",
              textAlign: "center",
              fontSize: "16px",
              fontWeight: "500",
              height: "60px",
              marginTop: "40px",
              ":hover": { backgroundColor: colors.primary },
            }}
            label="Reset Password"
            loading={isLoading}
          />
        </Box>
      </Box>
    </Box>
  );
}
export default ResetPassword;
