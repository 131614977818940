import { Box, Typography, CircularProgress } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import style from "./operating-theatre.module.css";
import { useGetOperatingTheatreDetailsQuery } from "../../services/api";
import { Button } from "../../components";
import { colors } from "../../theme/colors";

const OperatingTheatreDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: operatingTheatreDetails,
    isLoading,
    isFetching,
  } = useGetOperatingTheatreDetailsQuery(id ?? "");

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "30px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          border: `1px solid ${colors.grey}`,
          borderRadius: "4px",
          height: "100%",
        }}
      >
        {isLoading || isFetching ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "calc(100vh - 97px)",
            }}
          >
            <CircularProgress size={40} sx={{ marginLeft: "10px" }} />
          </Box>
        ) : (
          <Box sx={{ height: "100%", padding: "0px 20px" }}>
            <Typography className={style.operatingTheatreDetailHeading}>
              Operating Theatre Details
            </Typography>
            <Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>Name</Typography>
                <Typography className={style.value}>
                  {operatingTheatreDetails?.name}
                </Typography>
              </Box>

              {(operatingTheatreDetails?.room_number ||
                operatingTheatreDetails?.floor ||
                operatingTheatreDetails?.building) && (
                <Box className={style.detailsRow}>
                  <Typography className={style.title}>
                    Theatre Location
                  </Typography>
                  <Typography className={style.value}>
                    {`${
                      operatingTheatreDetails?.room_number
                        ? operatingTheatreDetails?.room_number +
                          (operatingTheatreDetails?.floor ? "," : "")
                        : ""
                    } ${
                      operatingTheatreDetails?.floor
                        ? operatingTheatreDetails?.floor +
                          (operatingTheatreDetails?.building ? "," : "")
                        : ""
                    } ${operatingTheatreDetails?.building ?? ""}`}
                  </Typography>
                </Box>
              )}

              {operatingTheatreDetails?.reason_to_deactivate && (
                <Box>
                  <Typography className={style.operatingTheatreDetailHeading}>
                    Deactivation Details
                  </Typography>
                  <Box>
                    <Box className={style.detailsRow}>
                      <Typography className={style.title}>
                        Reason for deactivation
                      </Typography>
                      <Typography className={style.value}>
                        {operatingTheatreDetails?.reason_to_deactivate}
                      </Typography>
                    </Box>
                    <Box className={style.detailsRow}>
                      <Typography className={style.title}>
                        Date of Deactivation
                      </Typography>
                      <Typography className={style.value}>
                        {operatingTheatreDetails?.last_deactivated_at
                          ? dayjs(
                              operatingTheatreDetails?.last_deactivated_at
                            ).format("MMMM DD YYYY, h:mm:ss a")
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              <Box className={style.buttons} sx={{ marginBottom: "20px" }}>
                <Button
                  sx={{
                    width: "200px",
                    height: "55px",
                    ":hover": { backgroundColor: "transparent" },
                  }}
                  variant="outlined"
                  onClick={() => navigate(-1)}
                  label={"Close"}
                />
                <Button
                  sx={{
                    width: "200px",
                    height: "55px",
                    ":hover": { backgroundColor: colors.primary },
                  }}
                  variant="contained"
                  label={"Edit"}
                  onClick={() => {
                    navigate(`/operating-theatres/edit/${id}`);
                  }}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OperatingTheatreDetails;
