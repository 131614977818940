import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Input as MuiInput, Typography } from "@mui/material";

import { CustomTable } from "../../components/DataTable";
import { ReactComponent as SearchIcon } from "../../assets/svg/Search.svg";
import { ReactComponent as WarningIcon } from "../../assets/svg/WarningRed.svg";
import { RootState, useAppSelector } from "../../store";
import { Button } from "../../components";
import { colors } from "../../theme/colors";
import { CustomActionMenu } from "../../components/Menu";
import { useGetPatientSpecimenRecordsQuery } from "../../services/api";
import style from "./specimen.module.css";
import { ReactComponent as EyeIcon } from "../../assets/svg/eye.svg";

// Functional component representing the list of specimen information
const Specimens = () => {
  const navigate = useNavigate();
  const { userRole } = useAppSelector((state: RootState) => state.auth);

  const [selectedFilter, setSelectedFilter] = useState("All");

  const fields = [
    {
      id: "patient_last_name",
      label: "Patient Name",
      flex: 1.5,
      type: "name",
      sortable: true,
      render: (item: PatientRecord) => (
        <Typography
          className={style.cell}
          sx={{
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {`${item["patient_last_name"].toUpperCase() ?? ""}, ${
            item["patient_first_name"] ?? ""
          }`}
        </Typography>
      ),
    },
    {
      id: "laboratory",
      label: "Laboratory",
      flex: 1.5,
      type: "name",
      sortable: true,
      render: (item: PatientRecord) => (
        <Typography
          className={style.cell}
          sx={{
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {`${item["laboratory"] ?? ""}`}
        </Typography>
      ),
    },
    {
      id: "surgeon_last_name",
      label: "Surgeon",
      flex: 1.5,
      type: "name",
      sortable: true,
      render: (item: PatientRecord) => (
        <Typography
          className={style.cell}
          sx={{
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {`${item["surgeon_last_name"].toUpperCase() ?? ""}, ${
            item["surgeon_first_name"] ?? ""
          } ${item["surgeon_prefix"] ?? ""}`}
        </Typography>
      ),
    },
    {
      id: "mrn",
      label: "MRN",
      flex: 1,
      type: "name",
      sortable: true,
      render: (item: PatientRecord) => (
        <Typography
          className={style.cell}
          sx={{
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {`${item["mrn"] ?? ""}`}
        </Typography>
      ),
    },
    {
      id: "overall_status",
      label: "Status",
      type: "badge",
      flex: 1.8,
      sortable: true,
      render: (item: PatientRecord) =>
        item?.overall_status !== "None" ? (
          <Box
            className={style.statusContainer}
            sx={{
              backgroundColor:
                item?.overall_status === "READY FOR PICKUP"
                  ? colors.lightGreen
                  : colors.modalBackground,
            }}
          >
            {item?.is_discrepancy && <WarningIcon width={16} height={16} />}
            <Typography className={style.cell} sx={{ fontSize: "12px" }}>
              {item?.overall_status !== "None" ? item?.overall_status : ""}
            </Typography>
          </Box>
        ) : (
          <></>
        ),
    },
    {
      id: "logo_s3_key_name",
      label: "Actions",
      type: "action",
      flex: 0.5,
      render: (item: Surgeon) => (
        <CustomActionMenu
          item={item}
          isActive={item?.is_active ?? false}
          handleChange={handleMenu}
          actions={[
            {
              label: "View",
              value: "view",
              icon: <EyeIcon height={24} width={24} />,
            },
          ]}
        />
      ),
    },
  ];

  // all query parameters and pagination params
  const [queryParam, setQueryParam] = useState({
    search_key: "",
    is_discrepancy: selectedFilter !== "All",
    limit: 10,
    offset: 0,
    is_request_form_printed: true,
    order_by_columns: "created_at",
    order: "asc",
  });

  // for searching in the query
  const handleSearch = useCallback(
    (e: any) => {
      setQueryParam((prevParams) => ({
        ...prevParams,
        search_key: e.target.value === "" ? undefined : e.target.value,
        offset: 0, // Reset offset when performing a new search
      }));
    },
    [setQueryParam]
  );

  // for handling the specimen state
  const handleMenu = (value: string, item: PatientRecord) => {
    if (value === "view") {
      navigate(`/specimens/details/${item?.id}`);
    }
  };

  const { data: patientRecords } =
    useGetPatientSpecimenRecordsQuery(queryParam);

  // handle the page change
  const handlePageChange = useCallback(
    (e: any, newOffset: number) => {
      setQueryParam((prevParams) => ({
        ...prevParams,
        offset: prevParams.limit * newOffset,
      }));
    },
    [setQueryParam]
  );

  // handle the page size change
  const handleSizeChange = useCallback(
    (e: any) => {
      setQueryParam((prevParams) => ({
        ...prevParams,
        limit: e.target.value,
      }));
    },
    [setQueryParam]
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <MuiInput
          disableUnderline
          placeholder={
            userRole === "OT_ADMIN"
              ? "Search by Patient/MRN/Laboratory/Surgeon"
              : "Search by name...."
          }
          type="search"
          startAdornment={<SearchIcon width={20} height={20} />}
          value={queryParam.search_key}
          onChange={handleSearch}
          sx={{
            width: "500px",
            gap: "8px",
            borderRadius: "50px",
            marginTop: "15px",
          }}
        />
        <Button
          sx={{
            width: "140px",
            marginLeft: "15px",
            height: "40px",
            border: `1px solid ${colors.black}`,
            color: colors.black,
            backgroundColor:
              selectedFilter === "All" ? colors.lightGrey : colors.white,
            ":hover": {
              backgroundColor: colors.lightGrey,
              border: `1px solid ${colors.black}`,
            },
          }}
          variant="outlined"
          onClick={() => {
            setSelectedFilter("All");
            setQueryParam((prevParams) => ({
              ...prevParams,
              offset: 0, // Reset offset when performing a new search
              is_discrepancy: false,
            }));
          }}
          label={"All Specimens"}
        />
        <Button
          sx={{
            width: "190px",
            marginLeft: "15px",
            height: "40px",
            border: `1px solid ${colors.black}`,
            color: colors.black,
            backgroundColor:
              selectedFilter === "Discrepancy"
                ? colors.lightGrey
                : colors.white,
            ":hover": {
              backgroundColor: colors.lightGrey,
              border: `1px solid ${colors.black}`,
            },
          }}
          variant="outlined"
          onClick={() => {
            setSelectedFilter("Discrepancy");
            setQueryParam((prevParams) => ({
              ...prevParams,
              offset: 0, // Reset offset when performing a new search
              is_discrepancy: true,
            }));
          }}
          label={"Discrepant Specimens"}
        />
      </Box>
      <Box style={{ maxHeight: "calc(100vh - 195px)", overflow: "auto" }}>
        <CustomTable<PatientRecord>
          fields={fields}
          paginationRequired={true}
          count={patientRecords?.count}
          data={patientRecords?.items || []}
          handlePageChange={handlePageChange}
          onSizeChange={handleSizeChange}
          pagination={queryParam}
          onDoubleClick={(patientRecord: PatientRecord) => {
            navigate(`/specimens/details/${patientRecord?.id}`);
          }}
          defaultOrderBy={"created_at"}
          defaultOrder={queryParam?.order === "dsc" ? "desc" : "asc"}
          onOrderChange={() => {
            setQueryParam((prevParams) => ({
              ...prevParams,
              order: prevParams?.order === "asc" ? "dsc" : "asc",
            }));
          }}
          onOrderByChange={(column: string) => {
            setQueryParam((prevParams) => ({
              ...prevParams,
              order_by_columns: column,
            }));
          }}
        />
      </Box>
    </Box>
  );
};
export default Specimens;
