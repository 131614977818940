import { useState } from "react";
import { Box, Typography, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CardContent from "@mui/material/CardContent";

import { colors } from "../../../theme/colors";
import style from "../surgeon.module.css";
import { ExpandMore } from "./ExpandMore";
import { ReactComponent as EditIcon } from "../../../assets/svg/edit.svg";
import AddressForm from "./AddressForm";

interface AddressDetailsProps {
  address?: AddressDetails;
  isDefault?: boolean;
  onSave?: (args: DeliveryAddressPayload) => void;
  isLoading?: boolean;
  onUpdateDefaultAddress?: () => void;
  onCancel?: () => void;
}

const AddressDetails = ({
  address,
  isDefault,
  onSave,
  isLoading,
  onUpdateDefaultAddress,
  onCancel,
}: AddressDetailsProps) => {
  const [expanded, setExpanded] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box
      sx={{
        border: `1px solid ${colors.grey}`,
        borderRadius: "4px",
        padding: "7px",
        marginBottom: "10px",
      }}
    >
      <Box
        className={style.addressRowContainer}
        sx={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            flexDirection: "row",
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ExpandMore>
          <Typography className={style.addressLabel} color={colors.black}>
            {`${
              address?.additional_address_detail
                ? address?.additional_address_detail + ", "
                : ""
            }${address?.street_address ? address?.street_address + ", " : ""}${
              address?.suburb ?? ""
            } ${address?.state ?? ""} ${address?.postcode ?? ""}`}
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box
            className={style.defaultContainer}
            sx={{
              backgroundColor: colors.lightGreen,
              border: `1px solid ${colors.darkGreen}`,
              borderRadius: "4px",
              marginRight: "10px",
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
            }}
            onClick={() => {
              if (!isDefault) {
                if (onUpdateDefaultAddress) {
                  onUpdateDefaultAddress();
                }
              }
            }}
          >
            <Typography
              className={style.cell}
              sx={{ fontSize: "12px", color: colors.darkGreen }}
            >
              {isDefault ? "Default" : "Set As Default"}
            </Typography>
          </Box>
          <Box
            sx={{ padding: "5px", cursor: "pointer" }}
            onClick={() => {
              setIsEdit(!isEdit);
              if (onCancel) {
                onCancel();
              }
            }}
          >
            <EditIcon height={20} width={20} />
          </Box>
        </Box>
      </Box>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        sx={{ marginLeft: "25px" }}
      >
        <CardContent sx={{ marginTop: "-25px" }}>
          {address?.email && (
            <Box
              className={style.addressRowContainer}
              sx={{
                flexDirection: "row",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <Typography className={style.addressLabel} color={colors.black}>
                Email:
              </Typography>
              <Typography
                className={style.addressValue}
                color={colors.black}
                sx={{ marginLeft: "5px" }}
              >
                {address?.email}
              </Typography>
            </Box>
          )}
          {address?.telephone && (
            <Box
              className={style.addressRowContainer}
              sx={{
                flexDirection: "row",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <Typography className={style.addressLabel} color={colors.black}>
                Tel:
              </Typography>
              <Typography
                className={style.addressValue}
                color={colors.black}
                sx={{ marginLeft: "5px" }}
              >
                {address?.telephone}
              </Typography>
            </Box>
          )}
          {address?.fax_number && (
            <Box
              className={style.addressRowContainer}
              sx={{
                flexDirection: "row",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <Typography className={style.addressLabel} color={colors.black}>
                Fax:
              </Typography>
              <Typography
                className={style.addressValue}
                color={colors.black}
                sx={{ marginLeft: "5px" }}
              >
                {address?.fax_number}
              </Typography>
            </Box>
          )}
          {address?.health_link && (
            <Box
              className={style.addressRowContainer}
              sx={{
                flexDirection: "row",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <Typography className={style.addressLabel} color={colors.black}>
                Health Link:
              </Typography>
              <Typography
                className={style.addressValue}
                color={colors.black}
                sx={{ marginLeft: "5px" }}
              >
                {address?.health_link}
              </Typography>
            </Box>
          )}
          {address?.provider_number && (
            <Box
              className={style.addressRowContainer}
              sx={{
                flexDirection: "row",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <Typography className={style.addressLabel} color={colors.black}>
                Provider No:
              </Typography>
              <Typography
                className={style.addressValue}
                color={colors.black}
                sx={{ marginLeft: "5px" }}
              >
                {address?.provider_number}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Collapse>
      {isEdit && (
        <Box sx={{ padding: "10px" }}>
          <AddressForm
            address={address}
            onCancel={() => {
              setIsEdit(false);
              if (onCancel) {
                onCancel();
              }
            }}
            onSave={(address) => {
              if (onSave) {
                onSave(address);
                setTimeout(() => {
                  setIsEdit(false);
                }, 1000);
              } else {
                setIsEdit(false);
              }
            }}
            isLoading={isLoading}
          />
        </Box>
      )}
    </Box>
  );
};

export default AddressDetails;
