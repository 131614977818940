import { useState } from "react";
import { Box, Tabs, Tab, CircularProgress } from "@mui/material";
import { Document, pdfjs, Page } from "react-pdf";
import { useParams } from "react-router-dom";

import Details from "./components/Details";
import {
  useGetPatientRequestFormQuery,
  useGetPatientSpecimenRecordDetailsQuery,
} from "../../services/api";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

// Functional component representing the list of specimen details information
const SpecimenDetails = () => {
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useState("request_form");
  const [numPages, setNumPages] = useState<number>();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const { data, isLoading } = useGetPatientRequestFormQuery(id ?? "");

  const {
    data: patientSpecimenRecordDetails,
    isLoading: isPatientDetailsLoading,
    isFetching,
  } = useGetPatientSpecimenRecordDetailsQuery(id ?? "");

  // const fields = [
  //   { id: "specimen", value: "Specimen", flex: 2 },
  //   { id: "timestamp", value: "TimeStamp", flex: 2 },
  //   { id: "user", value: "User", flex: 1.5 },
  //   { id: "action", value: "Action", flex: 1 },
  // ];

  return (
    <Box sx={{ marginTop: "5px" }}>
      <Tabs value={selectedTab} onChange={handleChange}>
        <Tab value="request_form" label="Request Form" />
        {/* <Tab value="event_log" label="Event Log" /> */}
        <Tab value="specimen_details" label="Specimen Details" />
      </Tabs>
      <Box
        style={{
          maxHeight: "calc(100vh - 195px)",
          overflow: "auto",
          marginTop: "10px",
        }}
        width={"100%"}
      >
        {selectedTab === "request_form" ? (
          isLoading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "calc(100vh - 97px)",
              }}
            >
              <CircularProgress size={40} sx={{ marginLeft: "10px" }} />
            </Box>
          ) : (
            data?.request_form_pre_signed_url && (
              <Document
                file={data?.request_form_pre_signed_url}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.apply(null, Array(numPages))
                  .map((x, i) => i + 1)
                  .map((page) => (
                    <Page pageNumber={page} scale={1.3} />
                  ))}
              </Document>
            )
          )
        ) : (
          // : selectedTab === "event_log" ? (
          //   <Table<Hospital>
          //     fields={fields}
          //     filters={{}}
          //     onChange={() => ""}
          //     onDoubleClick={(hospital: any) => {}}
          //     defaultOrderBy={"name"}
          //   />
          // )
          <Details
            details={patientSpecimenRecordDetails}
            isLoading={isPatientDetailsLoading || isFetching}
          />
        )}
      </Box>
    </Box>
  );
};
export default SpecimenDetails;
