import { useState } from "react";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import Typography from "@mui/material/Typography";
import { yupResolver } from "@hookform/resolvers/yup";

// importing custom components
import { useForgotPasswordMutation } from "../services/api";
import { CustomInput } from "../components/UserInput";
import { forgotPasswordSchema } from "../yup";
import { Button, Message } from "../components";

// import styles and colors
import { ReactComponent as Pegasus } from "../assets/svg/PegasusLogo.svg";
import style from "../components/UserInput/input.module.css";
import { colors } from "../theme/colors";

type FormState = typeof forgotPasswordSchema.__outputType;

function ForgotPassword() {
  /*
   * useForm hook from react-hook-form
   * Manages form state, validation, and submission logic.
   * - register: Registers input fields and gathers their values.
   * - handleSubmit: Triggers the provided callback on form submission.
   * - formState: Provides access to form state, such as errors
   * - yupResolver(loginSchema): Restrict the type of payload data for required, min length etc
   */
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormState>({
    resolver: yupResolver(forgotPasswordSchema),
    defaultValues: {
      email: "",
    },
  });

  // Forgot Password mutation hook
  const [forgotPassword] = useForgotPasswordMutation();

  const [message, setMessage] = useState<LabelMessageType | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  // Handle form submission
  const onSubmit = async (values: FormState) => {
    try {
      setIsLoading(true);
      const { email } = values;
      const result = await forgotPassword({
        email,
      }).unwrap();
      setIsLoading(false);
      if (result) {
        setMessage({
          type: "success",
          text: result.message,
        });
      }
    } catch (error: any) {
      setMessage({
        type: "error",
        text: error?.data?.detail?.toString() || "Please enter valid details",
      });
      setIsLoading(false);
    }
  };

  // Render the forgot password form
  return (
    <Box
      sx={{
        display: "grid",
        placeItems: "center",
        height: "calc(100vh - 97px)",
      }}
    >
      <Box className={style.boxShadow}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "auto",
            width: "395px",
            height: "100%",
          }}
          noValidate
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box textAlign="center" paddingBlockEnd={"20px"}>
            <Pegasus />
          </Box>

          <Typography
            paddingBlockEnd={2}
            textAlign="center"
            noWrap
            component="p"
            fontSize={16}
            fontWeight={700}
          >
            Forgot Password
          </Typography>
          <Box className={style.inputs}>
            <CustomInput
              name="email"
              label="Email"
              type="text"
              value={watch("email")}
              isLoading={isLoading}
              isError={errors.email}
              register={register}
              showIcon={false}
            />
            {message && <Message message={message} />}
          </Box>

          <Button
            label="Request reset link"
            loading={isLoading}
            type="submit"
            variant="contained"
            sx={{
              width: "100%",
              textAlign: "center",
              fontSize: "16px",
              fontWeight: "500",
              height: "60px",
              marginTop: "60px",
              ":hover": { backgroundColor: colors.primary },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
export default ForgotPassword;
