import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Chip,
  Checkbox,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

import { userRoleSchema } from "../../yup";
import style from "./user.module.css";
import { Button, LabelBox } from "../../components";
import { colors } from "../../theme/colors";
import { SingleSelectInput } from "../../components/SelectInput";
import {
  useGetUserGroupQuery,
  useGetServicesQuery,
  useAddUserRoleMutation,
  useGetUserTypeQuery,
} from "../../services/api";

type FormState = typeof userRoleSchema.__outputType;

function AddUserRole() {
  const [addUserRole] = useAddUserRoleMutation();
  const navigation = useNavigate();

  const [message, setMessage] = useState<LabelMessageType | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { data: types = [] } = useGetUserTypeQuery("");

  const { data: groups = [] } = useGetUserGroupQuery("");

  const { data: services = [] } = useGetServicesQuery("");

  const userTypes = types.map((type: string, index: number) => {
    return {
      id: index,
      label: type,
      value: type,
    };
  });

  const userGroups = groups.map((group: string, index: number) => {
    return {
      id: index,
      label: group,
      value: group,
    };
  });

  const apiServices = services.map((service: Service) => {
    return {
      id: service?.id,
      label: service?.code_name,
      value: service?.code_name,
    };
  });

  /*
   * useForm hook from react-hook-form
   * Manages form state, validation, and submission logic.
   * - register: Registers input fields and gathers their values.
   * - handleSubmit: Triggers the provided callback on form submission.
   * - formState: Provides access to form state, such as errors
   * - yupResolver(userRoleSchema): Restrict the type of payload data for required, min length etc
   */
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    control,
  } = useForm<FormState>({
    resolver: yupResolver(userRoleSchema),
    mode: "onChange",
  });

  const onSubmit = async (values: FormState) => {
    try {
      setIsLoading(true);
      setMessage(null);
      const { role_name, group, services_id_list } = values;

      const payload: AddUserRolePayload = {
        name: role_name,
        user_group: group,
        services_id_list: services_id_list?.map((service) => service.id) ?? [],
      };
      await addUserRole(payload).unwrap();
      setIsLoading(false);
      navigation(-1);
    } catch (error: any) {
      setMessage({
        type: "error",
        text: error?.data?.detail?.toString() || "Please enter valid details",
      });
      setIsLoading(false);
    }
  };

  return (
    <Box className={style.container}>
      <Typography className={style.heading}>Create a new user role</Typography>
      <Typography className={style.description}>
        Enter the below details to start adding a new user role to Pegasus
        Cybersoft.
      </Typography>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box className={style.flexTop}>
          <Box className={style.row}>
            <SingleSelectInput
              placeholder="Eg. Super Admin"
              label="Role Name"
              required
              register={register}
              name="role_name"
              data={userTypes ?? []}
              error={!!errors.group}
              errors={errors}
            />
          </Box>
          <Box className={style.row} sx={{ flexDirection: "column" }}>
            <SingleSelectInput
              placeholder="Eg. Hospital"
              label="Group"
              required
              register={register}
              name="group"
              data={userGroups ?? []}
              error={!!errors.group}
              errors={errors}
            />
          </Box>
          <Box className={style.row} sx={{ flexDirection: "column" }}>
            <Typography className={style.inputLabel} sx={{ marginBottom: -2 }}>
              Services
            </Typography>
            <Controller
              control={control}
              name={"services_id_list"}
              rules={{ required: false }}
              render={({ field: { onChange }, ...props }) => (
                <Autocomplete
                  {...props}
                  sx={{
                    width: "100%",
                  }}
                  multiple
                  disablePortal
                  disableCloseOnSelect
                  id="tags-outlined"
                  options={apiServices ?? []}
                  disableClearable={true}
                  onChange={(e, data) => onChange(data)}
                  getOptionLabel={(option: any) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={
                        isEmpty(getValues("services_id_list"))
                          ? "Select api services"
                          : ""
                      }
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                    />
                  )}
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: any, index: number) => (
                      <Chip
                        variant="outlined"
                        label={option.label}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderOption={(props, option: any, { selected }) => (
                    <li {...props}>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      {option.label}
                    </li>
                  )}
                />
              )}
            />
          </Box>
        </Box>
        {message && (
          <Box mt={2}>
            <LabelBox
              boxProps={{ height: 50, display: "flex", alignItems: "center" }}
              data={message}
              onClose={() => setMessage(null)}
            />
          </Box>
        )}
        <Box className={style.buttons} sx={{ marginBottom: 3 }}>
          <Button
            sx={{
              width: "200px",
              height: "55px",
              ":hover": { backgroundColor: "transparent" },
            }}
            variant="outlined"
            onClick={() => navigation(-1)}
            label={"Cancel"}
          />
          <Button
            sx={{
              width: "200px",
              height: "55px",
              ":hover": { backgroundColor: colors.primary },
            }}
            variant="contained"
            type="submit"
            label={"Save"}
            loading={isLoading}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default AddUserRole;
