import { useState, useEffect } from "react";
import { Box, Typography, Checkbox, CircularProgress } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

import style from "./laboratory.module.css";
import { useGetLaboratoryDetailsQuery } from "../../services/api";
import { Button } from "../../components";
import { colors } from "../../theme/colors";

function LaboratoryDetails() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [faxNumberSelected, setFaxNumberSelected] = useState(false);
  const [healthLinkSelected, setHealthLinkSelected] = useState(false);
  const [deliveryEmailSelected, setDeliveryEmailSelected] = useState(false);
  const [webLinkSelected, setWebLinkSelected] = useState(false);

  const {
    data: laboratoryDetails = {},
    isLoading,
    isFetching,
  } = useGetLaboratoryDetailsQuery(id ?? "");

  useEffect(() => {
    setFaxNumberSelected(laboratoryDetails?.is_delivery_by_fax ?? false);
    setHealthLinkSelected(
      laboratoryDetails?.is_delivery_by_health_link ?? false
    );
    setDeliveryEmailSelected(laboratoryDetails?.is_delivery_mail ?? false);
    setWebLinkSelected(laboratoryDetails?.is_delivery_by_web_link ?? false);
  }, [laboratoryDetails]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "30px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          border: `1px solid ${colors.grey}`,
          borderRadius: "4px",
          height: "100%",
        }}
      >
        {isLoading || isFetching ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "calc(100vh - 97px)",
            }}
          >
            <CircularProgress size={40} sx={{ marginLeft: "10px" }} />
          </Box>
        ) : (
          <Box sx={{ height: "100%", padding: "15px 40px" }}>
            <Typography className={style.laboratoryDetailHeading}>
              Laboratory Details
            </Typography>
            <Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>Laboratory</Typography>
                <Typography className={style.value}>
                  {laboratoryDetails?.name}
                </Typography>
              </Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>Address</Typography>
                <Typography className={style.value}>
                  {`${laboratoryDetails?.street_address} ${
                    laboratoryDetails?.suburb ?? ""
                  } ${laboratoryDetails?.state ?? ""} ${
                    laboratoryDetails?.postcode ?? ""
                  }`}
                </Typography>
              </Box>
            </Box>
            <Typography className={style.laboratoryDetailHeading}>
              Contact Details
            </Typography>
            <Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>
                  General Enquiries
                </Typography>
                <Typography className={style.value}>
                  {laboratoryDetails?.general_enquiries_contact_number}
                </Typography>
              </Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>
                  Additional Contacts
                </Typography>
                <Typography className={style.value} sx={{ display: "flex" }}>
                  {"For department-specific contacts, please refer to the"}
                  <Typography
                    onClick={() => {
                      navigate("/users", {
                        state: { selectedTab: "laboratory" },
                      });
                    }}
                    color={colors.primary}
                    display={"block"}
                    sx={{
                      textDecoration: "none",
                      paddingX: "5px",
                      cursor: "pointer",
                    }}
                  >
                    'User List'
                  </Typography>
                  {"section."}
                </Typography>
              </Box>
            </Box>
            <Typography className={style.laboratoryDetailHeading}>
              Report Delivery (Medical Records Department) Details
            </Typography>
            <Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>Email</Typography>
                <Typography className={style.value}>
                  {laboratoryDetails?.delivery_email}
                </Typography>
              </Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>Fax Number</Typography>
                <Typography className={style.value}>
                  {laboratoryDetails?.fax_number}
                </Typography>
              </Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>Health Link</Typography>
                <Typography className={style.value}>
                  {laboratoryDetails?.health_link}
                </Typography>
              </Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>Web link</Typography>
                <Typography className={style.value}>
                  {laboratoryDetails?.web_link}
                </Typography>
              </Box>
            </Box>
            <Typography className={style.laboratoryDetailHeading}>
              Mode of Delivery
            </Typography>
            <Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>Email</Typography>
                <Checkbox
                  checked={deliveryEmailSelected}
                  disableTouchRipple
                  sx={{
                    transform: "scale(0.92)",
                    padding: "0px",
                  }}
                  disabled
                />
              </Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>Fax Number</Typography>
                <Checkbox
                  checked={faxNumberSelected}
                  disableTouchRipple
                  sx={{
                    transform: "scale(0.92)",
                    padding: "0px",
                    color: colors.primary,
                  }}
                  disabled
                />
              </Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>Health Link</Typography>
                <Checkbox
                  checked={healthLinkSelected}
                  disableTouchRipple
                  sx={{
                    transform: "scale(0.92)",
                    padding: "0px",
                  }}
                  disabled
                />
              </Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>Web link</Typography>
                <Checkbox
                  checked={webLinkSelected}
                  disableTouchRipple
                  sx={{
                    transform: "scale(0.92)",
                    padding: "0px",
                    color: colors.primary,
                  }}
                  disabled
                />
              </Box>
            </Box>
            {laboratoryDetails.reason_to_deactivate && (
              <Box>
                <Typography className={style.laboratoryDetailHeading}>
                  Deactivation Details
                </Typography>
                <Box>
                  <Box className={style.detailsRow}>
                    <Typography className={style.title}>
                      Reason for deactivation
                    </Typography>
                    <Typography className={style.value}>
                      {laboratoryDetails?.reason_to_deactivate}
                    </Typography>
                  </Box>
                  <Box className={style.detailsRow}>
                    <Typography className={style.title}>
                      Date of Deactivation
                    </Typography>
                    <Typography className={style.value}>
                      {laboratoryDetails?.last_deactivated_at
                        ? moment(laboratoryDetails?.last_deactivated_at).format(
                            "MMMM DD YYYY, h:mm:ss a"
                          )
                        : "-"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
            <Box className={style.buttons} sx={{ marginBottom: "20px" }}>
              <Button
                sx={{
                  width: "200px",
                  height: "55px",
                  ":hover": { backgroundColor: "transparent" },
                }}
                variant="outlined"
                onClick={() => navigate(-1)}
                label={"Close"}
              />
              {laboratoryDetails?.is_active && (
                <Button
                  sx={{
                    width: "200px",
                    height: "55px",
                    ":hover": { backgroundColor: colors.primary },
                  }}
                  variant="contained"
                  label={"Edit"}
                  onClick={() =>
                    navigate(`/laboratories/edit/${laboratoryDetails?.id}`)
                  }
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default LaboratoryDetails;
