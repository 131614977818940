import { Box, Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import style from "./dialog.module.css";
import { Button } from "../.";
import { colors } from "../../theme/colors";

type DialogProps = {
  visible: boolean;
  imageUrl: string;
  onClose?: () => void;
};

export default function PreviewDialog({
  visible,
  imageUrl,
  onClose,
}: DialogProps) {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: colors.modalBackground,
          maxWidth: "600px",
          width: "100%",
          border: `1px solid ${colors.black}`,
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              padding: "15px",
            }}
          >
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => handleClose()}
              onMouseDown={() => handleClose()}
              edge="end"
            >
              <CloseIcon sx={{ cursor: "pointer", height: 20, width: 20 }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              padding: "0px 15px",
            }}
          >
            <Box
              component="img"
              sx={{
                height: "400px",
                width: "550px",
                backgroundColor: colors.white,
                borderRadius: "5px",
                border: "1px solid #a3a3a3",
                padding: "1px",
              }}
              src={imageUrl}
            />
          </Box>

          <Box className={style.buttons}>
            <Button
              sx={{
                width: "200px",
                height: "35px",
                backgroundColor: colors.white,
                ":hover": { backgroundColor: colors.white },
              }}
              variant="outlined"
              onClick={() => {
                handleClose();
              }}
              label={"Close"}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
