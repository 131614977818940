import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const StorageKeys = {
  authToken: "authToken",
  refreshToken: "refreshToken",
  userRole: "userRole",
};

function setTokens({
  authToken,
  refreshToken,
}: {
  authToken: string;
  refreshToken: string;
}) {
  localStorage.setItem(StorageKeys.authToken, authToken);
  localStorage.setItem(StorageKeys.refreshToken, refreshToken);
}

function setUserRole(userRole: string) {
  localStorage.setItem(StorageKeys.userRole, userRole);
}

function removeTokens() {
  localStorage.removeItem(StorageKeys.authToken);
  localStorage.removeItem(StorageKeys.refreshToken);
}

function removeUserRole() {
  localStorage.removeItem(StorageKeys.userRole);
}

interface AuthState {
  authToken: string;
  refreshToken: string;
  userRole?: string;
  userProfileDetails?: UserProfile;
}

const initialState: AuthState = {
  authToken: localStorage.getItem(StorageKeys.authToken) || "",
  refreshToken: localStorage.getItem(StorageKeys.refreshToken) || "",
  userRole: localStorage.getItem(StorageKeys.userRole) || "",
  userProfileDetails: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    login: (state, action: PayloadAction<AuthState>) => {
      setTokens(action.payload);
      setUserRole(action.payload.userRole ?? "");
      state.authToken = action.payload.authToken;
      state.refreshToken = action.payload.refreshToken;
      state.userRole = action.payload.userRole;
    },
    logout: (state) => {
      removeTokens();
      removeUserRole();
      state.authToken = "";
      state.refreshToken = "";
      state.userRole = "";
    },
    setProfileDetails: (state, action) => {
      state.userProfileDetails = action.payload.profileDetails;
    },
  },
});

export const { login, logout, setProfileDetails } = authSlice.actions;
export default authSlice.reducer;
