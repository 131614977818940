import {
  Button as MuiButton,
  CircularProgress,
  ButtonProps as MuiButtonProps,
} from "@mui/material";

interface ButtonProps extends MuiButtonProps {
  loading?: boolean;
  label?: string;
}

export default function Button({
  loading = false,
  label,
  ...restProps
}: ButtonProps) {
  return (
    <MuiButton disabled={loading} {...restProps}>
      {label}
      {loading && <CircularProgress size={22} sx={{ marginLeft: "10px" }} />}
    </MuiButton>
  );
}
