import { Box, Dialog, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import style from "./dialog.module.css";
import { Button } from "../.";
import { colors } from "../../theme/colors";

type DialogProps = {
  visible: boolean;
  message: string;
  onClose?: () => void;
  confirmButtonTitle?: string;
  cancelButtonTitle?: string;
  onConfirm?: () => void;
  loading?: boolean;
};

export default function ConfirmationDialog({
  visible,
  message,
  onClose,
  confirmButtonTitle = "Yes",
  cancelButtonTitle = "No",
  onConfirm,
  loading = false,
}: DialogProps) {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: colors.modalBackground,
          maxWidth: "486px",
          width: "100%",
          border: `1px solid ${colors.black}`,
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              padding: "15px",
            }}
          >
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => handleClose()}
              onMouseDown={() => handleClose()}
              edge="end"
            >
              <CloseIcon sx={{ cursor: "pointer", height: 20, width: 20 }} />
            </IconButton>
          </Box>

          <Typography
            variant="h2"
            component="div"
            fontSize={16}
            fontWeight={700}
            color={colors.modalHeading}
            paddingX={"30px"}
            textAlign={"center"}
          >
            {message}
          </Typography>
          <Box className={style.buttons}>
            <Button
              sx={{
                width: "200px",
                height: "35px",
                ":hover": { backgroundColor: colors.primary },
              }}
              variant="contained"
              type="submit"
              onClick={() => {
                handleConfirm();
              }}
              label={confirmButtonTitle}
              loading={loading}
            />

            <Button
              sx={{
                width: "200px",
                height: "35px",
                backgroundColor: colors.white,
                ":hover": { backgroundColor: colors.white },
              }}
              variant="outlined"
              onClick={() => {
                handleClose();
              }}
              label={cancelButtonTitle}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
