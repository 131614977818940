import { Box } from "@mui/material";

// Functional component representing the Technology page
function Technology() {
  return (
    <Box>
      <h1>Technology</h1>
    </Box>
  );
}
export default Technology;
