import { InputAdornment, TextField, Typography } from "@mui/material";
import { FieldError } from "react-hook-form";

// importing custom component
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

import { colors } from "../../theme/colors";
import style from "./input.module.css";
import { Loader } from "..";

interface InputProps {
  label: string;
  type?: string;
  value?: string | number;
  isLoading: boolean;
  name: string;
  focus?: boolean;
  register: any; // Pass register function from react-hook-form
  errors?: FieldError;
  isError?: boolean | any;
  showIcon?: boolean;
  icon?: string;
  handleIconClick?: () => void;
}
interface RenderIconProps {
  name: string;
  type?: string;
  textType?: string;
  handleClick?: () => void;
}

const RenderIcon: React.FC<RenderIconProps> = ({
  name,
  textType,
  handleClick,
}) => {
  switch (name) {
    case "eye":
      if (textType === "password") {
        return (
          <VisibilityOffOutlinedIcon
            onClick={handleClick}
            className={style.iconStyle}
          />
        );
      } else {
        return (
          <VisibilityOutlinedIcon
            onClick={handleClick}
            className={style.iconStyle}
          />
        );
      }
    case "rightArrow":
      return (
        <ArrowCircleRightOutlinedIcon
          onClick={handleClick}
          className={style.iconStyle}
        />
      );
    default:
      return null;
  }
};

const CustomInput: React.FC<InputProps> = ({
  name,
  type,
  value,
  isError,
  register,
  focus = false,
  isLoading = false,
  showIcon = false,
  icon,
  handleIconClick,
  ...property
}) => {
  return (
    <>
      <TextField
        fullWidth
        type={type}
        {...property}
        // overriding default styles
        sx={{
          marginTop: "10px",
          border: `1px solid ${isError ? colors.red : colors.primary}`,
          borderRadius: "8px",
          outline: "none",
          "& .MuiInputBase-root": {
            background: "transparent",
            borderRadius: "8px",
            backgroundColor: "none",
          },
          "& .MuiInputBase-input": {
            padding: "18px 0px 6px 10px",
            borderRadius: "8px",
          },
          "& .MuiFormLabel-root": {
            color: isError ? colors.red : colors.primary,
            margin: "-4px 0px",
          },
          "&.Mui-focused": {
            background: "transparent",
          },
        }}
        autoFocus={focus}
        inputProps={{
          form: {
            autoComplete: "off",
          },
        }}
        InputProps={{
          disableUnderline: true,
          endAdornment: showIcon && (
            <InputAdornment position="end">
              {isLoading ? (
                <Loader logoStyle={style.loader} />
              ) : (
                icon && (
                  <RenderIcon
                    name={icon}
                    textType={type}
                    handleClick={handleIconClick}
                  />
                )
              )}
            </InputAdornment>
          ),
        }}
        {...register(name, { required: true })}
        variant="filled"
      />
      {isError && (
        <>
          <Typography color={colors.red} component="a" fontSize="10px">
            {isError.message}
          </Typography>
        </>
      )}
    </>
  );
};

export default CustomInput;
