import React, { useState, useRef, useEffect, ReactNode } from "react";
import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Stack,
  IconButton,
  Typography,
} from "@mui/material";

import { ReactComponent as DotsIcon } from "../../assets/svg/dot_options.svg";
import { colors } from "../../theme/colors";

interface ActionItem {
  label: string;
  value: string;
  icon: ReactNode;
  disabled?: boolean;
}

type Props = {
  onChange?: (value: string) => void;
  actionItems: ActionItem[];
};

export default function ActionMenu(props: Props) {
  const { onChange, actionItems } = props;

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const menuItemSx = { ":hover": { background: colors.lightPrimary } };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose =
    (value: string) => (event: Event | React.SyntheticEvent) => {
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      ) {
        return;
      }
      if (onChange) {
        onChange?.(value);
      }
      setOpen(false);
    };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack direction="row" spacing={2}>
      <IconButton
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        sx={{ zIndex: 1 }}
        onClick={handleToggle}
      >
        <DotsIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{ zIndex: 2 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose("away")}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                  sx={{
                    border: `1px solid ${colors.grey}`,
                    borderRadius: "4px",
                  }}
                >
                  {actionItems?.map((actionItem: ActionItem) => (
                    <MenuItem
                      sx={menuItemSx}
                      onClick={handleClose(actionItem.value)}
                      value={"view"}
                      disabled={actionItem.disabled ?? false}
                    >
                      {actionItem.icon}
                      <Typography ml={1}>{actionItem.label}</Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  );
}
