import React from "react";
import clsx from "clsx"; // Import clsx for conditional class names
import { Box, Typography } from "@mui/material";

import tableStyle from "./table.module.css";

interface StatusBadgeProps {
  isActive: boolean;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ isActive }) => {
  return (
    <Box
      className={clsx(
        tableStyle.badge,
        tableStyle[isActive ? "active" : "inactive"]
      )}
    >
      <Typography className={tableStyle.cell}>
        {isActive ? "Active" : "Inactive"}
      </Typography>
    </Box>
  );
};

export default StatusBadge;
