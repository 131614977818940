import { useState, useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";

import { Button } from "../../../components";
import { colors } from "../../../theme/colors";
import style from "./../specimen.module.css";
import { RootState, useAppSelector } from "../../../store";
import { CustomTable } from "../../../components/DataTable";
import { ReactComponent as Basket } from "../../../assets/svg/basket.svg";
import { ReactComponent as Fridge } from "../../../assets/svg/fridge.svg";
import { ReactComponent as Freezer } from "../../../assets/svg/freezer.svg";
import { ReactComponent as Attachment } from "../../../assets/svg/attachments.svg";
import { PreviewDialog } from "../../../components/Dialog";

interface PatientSpecimenRelProps {
  details?: PatientSpecimenRel;
  isLoading?: boolean;
}

const Details = ({ details, isLoading }: PatientSpecimenRelProps) => {
  const { userProfileDetails } = useAppSelector(
    (state: RootState) => state.auth
  );
  const navigate = useNavigate();

  const [specimens, setSpecimens] = useState<Specimen[]>([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string>();

  const getSpecimenName = (specimen: Specimen) => {
    const sutureTimeDetails =
      (specimen?.suture_time_detail
        ? Object.keys(specimen?.suture_time_detail).map((key) => {
            // @ts-ignore
            return `, ${specimen?.suture_time_detail[key]}`;
          })
        : "") +
      (specimen?.additional_suture_detail
        ? `, ${specimen?.additional_suture_detail}`
        : "");

    const procedureDetails =
      (specimen?.procedure_name ?? "") +
      (specimen?.additional_procedure_detail
        ? `, ${specimen?.additional_procedure_detail}`
        : "");

    return `${procedureDetails}${sutureTimeDetails}, ${specimen?.organ_detail}`;
  };

  const fields = [
    {
      id: "organ_detail",
      label: "Specimen",
      flex: 2.5,
      type: "name",
      render: (item: Specimen) => (
        <Typography
          className={style.cell}
          sx={{
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {getSpecimenName(item)}
        </Typography>
      ),
    },
    {
      id: "storage",
      label: "Storage",
      flex: 1,
      type: "name",
      render: (specimen: Specimen) =>
        specimen?.placed_in === "FREEZER" ? (
          <Freezer width={34} height={34} />
        ) : specimen?.placed_in === "FRIDGE" ? (
          <Fridge width={34} height={34} />
        ) : (
          <Basket width={34} height={34} />
        ),
    },
    {
      id: "date",
      label: "Collection Date & Time",
      flex: 2,
      type: "name",
      render: (item: Specimen) => (
        <Typography
          className={style.cell}
          sx={{
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {item?.created_at
            ? dayjs(item?.created_at).format("DD/MM/YYYY hh:mm A")
            : ""}
        </Typography>
      ),
    },
    {
      id: "status",
      label: "Status",
      type: "badge",
      flex: 2,
      sortable: true,
      render: (item: Specimen) =>
        item?.specimen_status !== "None" ? (
          <Box
            className={style.detailsStatusContainer}
            sx={{
              backgroundColor:
                item?.specimen_status === "READY FOR PICKUP"
                  ? colors.lightGreen
                  : item?.specimen_status === "NO SPECIMEN"
                  ? colors.lightRed
                  : colors.modalBackground,
            }}
          >
            <Typography
              className={style.cell}
              sx={{
                fontSize: "12px",
                color: colors.black,
              }}
            >
              {item?.specimen_status !== "None" ? item?.specimen_status : ""}
            </Typography>
          </Box>
        ) : (
          <></>
        ),
    },
  ];

  useEffect(() => {
    if (details) {
      let updatedSpecimens: any = [];
      details?.patient_bag_specimens?.forEach((specimenBag) => {
        specimenBag?.specimens?.forEach((specimen) => {
          updatedSpecimens = [
            ...updatedSpecimens,
            { ...specimen, test_name: specimenBag?.test_type_name },
          ];
        });
      });
      setSpecimens(updatedSpecimens);
    }
  }, [details]);

  const addedNotes = details?.related_notes ?? [];
  const addedDiscrepancies = details?.related_discrepancies ?? [];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "10px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          border: `1px solid ${colors.grey}`,
          borderRadius: "4px",
          height: "100%",
          paddingTop: "10px",
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "calc(100vh - 97px)",
            }}
          >
            <CircularProgress size={40} sx={{ marginLeft: "10px" }} />
          </Box>
        ) : (
          <Box sx={{ height: "100%", padding: "0px 20px" }}>
            <Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>Hospital</Typography>
                <Typography className={style.value} sx={{ fontWeight: 600 }}>
                  {`${userProfileDetails?.hospital?.name}`}
                </Typography>
              </Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>Patient Name</Typography>
                <Typography className={style.value} sx={{ fontWeight: 600 }}>
                  {`${details?.patient?.last_name.toUpperCase() ?? ""}, ${
                    details?.patient?.first_name ?? ""
                  }`}
                </Typography>
              </Box>
              {details?.patient?.dob && (
                <Box className={style.detailsRow}>
                  <Typography className={style.title}>Patient DOB</Typography>
                  <Typography className={style.value} sx={{ fontWeight: 600 }}>
                    {dayjs(details?.patient?.dob).format("DD/MM/YYYY")}
                  </Typography>
                </Box>
              )}
              {details?.patient?.ur_mrn_number && (
                <Box className={style.detailsRow}>
                  <Typography className={style.title}>Patient MRN</Typography>
                  <Typography className={style.value} sx={{ fontWeight: 600 }}>
                    {`${details?.patient?.ur_mrn_number?.toUpperCase() ?? ""}`}
                  </Typography>
                </Box>
              )}
              {details?.patient?.surgeon && (
                <Box className={style.detailsRow}>
                  <Typography className={style.title}>Surgeon</Typography>
                  <Typography className={style.value} sx={{ fontWeight: 600 }}>
                    {`${
                      details?.patient?.surgeon?.last_name?.toUpperCase() ?? ""
                    }, ${details?.patient?.surgeon?.first_name ?? ""} ${
                      details?.patient?.surgeon?.prefix ?? ""
                    }`}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                maxHeight: "calc(100vh - 195px)",
                overflow: "auto",
                marginTop: "20px",
              }}
            >
              <CustomTable<Specimen>
                fields={fields}
                paginationRequired={false}
                count={specimens?.length}
                data={specimens || []}
                handlePageChange={() => {}}
              />
            </Box>

            {!isEmpty(addedDiscrepancies) && (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Attachment width={24} height={24} />
                  <Typography className={style.detailHeading}>
                    Discrepancies
                  </Typography>
                </Box>
                <Box sx={{ marginBottom: "10px" }}>
                  {addedDiscrepancies?.map(
                    (discrepancy: DiscrepancyDetails, index) => {
                      const selectedSpecimenDetails = specimens?.filter(
                        (specimen) => {
                          return (
                            specimen?.id === discrepancy?.patient_specimen_id
                          );
                        }
                      );
                      const sutureTimeDetails =
                        (selectedSpecimenDetails[0]?.suture_time_detail
                          ? Object.keys(
                              selectedSpecimenDetails[0]?.suture_time_detail
                            ).map((key) => {
                              // @ts-ignore
                              return `, ${selectedSpecimenDetails[0]?.suture_time_detail[key]}`;
                            })
                          : "") +
                        (selectedSpecimenDetails[0]?.additional_suture_detail
                          ? `, ${selectedSpecimenDetails[0]?.additional_suture_detail}`
                          : "");

                      const procedureDetails =
                        (selectedSpecimenDetails[0]?.procedure_name ?? "") +
                        (selectedSpecimenDetails[0]?.additional_procedure_detail
                          ? `, ${selectedSpecimenDetails[0]?.additional_procedure_detail}`
                          : "");
                      return (
                        <Box
                          key={index}
                          sx={{
                            border: "1px solid #a3a3a3",
                            borderRadius: "5px",
                            padding: "10px",
                            marginBottom: "10px",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            display: "flex",
                          }}
                        >
                          <Box sx={{ flex: 2.35 }}>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                color: colors?.black,
                                textDecorationLine: "underline",
                              }}
                            >{`${procedureDetails}${sutureTimeDetails}, ${selectedSpecimenDetails[0]?.organ_detail}`}</Typography>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                color: colors?.black,
                              }}
                            >
                              {discrepancy?.discrepancy_detail ?? ""}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                color: colors?.black,
                              }}
                            >
                              {discrepancy?.no_specimen_cause ?? ""}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                color: colors?.black,
                              }}
                            >
                              {discrepancy?.no_specimen_comments !==
                                "undefined" &&
                              discrepancy?.no_specimen_comments !== ""
                                ? discrepancy?.no_specimen_comments ?? ""
                                : ""}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              flexDirection: "row",
                              flex: 0.75,
                              marginTop: "5px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: colors?.black,
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                            >
                              Nurse
                            </Typography>
                            <Typography
                              sx={{
                                color: colors?.black,
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                            >
                              {`${dayjs(discrepancy?.created_at).format(
                                "DD/MM/YYYY"
                              )} | ${dayjs(discrepancy?.created_at).format(
                                "h:mm A"
                              )}`}
                            </Typography>
                            <Typography
                              sx={{
                                color: colors?.black,
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                            >
                              {`${
                                discrepancy?.created_by?.first_name
                                  ? discrepancy?.created_by?.first_name + " "
                                  : ""
                              }${discrepancy?.created_by?.last_name ?? ""}`}
                            </Typography>
                          </Box>

                          <Box
                            key={
                              discrepancy?.specimen_discrepancy_log_pre_signed_url
                            }
                            sx={{
                              justifyContent: "flex-end",
                              flex: 0.75,
                              display: "flex",
                              alignItems: "center",
                              marginTop: "5px",
                              marginRight: "5px",
                            }}
                          >
                            {discrepancy?.specimen_discrepancy_log_pre_signed_url && (
                              <Box
                                component="img"
                                sx={{
                                  height: 90,
                                  width: 90,
                                  backgroundColor: colors.white,
                                  borderRadius: "5px",
                                  border: "1px solid #a3a3a3",
                                  padding: "1px",
                                  cursor: "pointer",
                                }}
                                src={
                                  discrepancy?.specimen_discrepancy_log_pre_signed_url
                                }
                                onClick={() => {
                                  setPreviewVisible(true);
                                  setSelectedImage(
                                    discrepancy?.specimen_discrepancy_log_pre_signed_url
                                  );
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                      );
                    }
                  )}
                </Box>
              </Box>
            )}

            {!isEmpty(addedNotes) && (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Attachment width={24} height={24} />
                  <Typography className={style.detailHeading}>Notes</Typography>
                </Box>
                <Box sx={{ marginBottom: "10px" }}>
                  {addedNotes?.map((notes: NoteDetails, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          border: "1px solid #a3a3a3",
                          borderRadius: "5px",
                          padding: "10px",
                          marginBottom: "10px",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          display: "flex",
                        }}
                      >
                        <Box sx={{ flex: 2.35 }}>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              color: colors?.black,
                            }}
                          >{`${notes?.note_by_author}`}</Typography>
                        </Box>
                        <Box
                          sx={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            flex: 0.75,
                            marginTop: "5px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: colors?.black,
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            Nurse
                          </Typography>
                          <Typography
                            sx={{
                              color: colors?.black,
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            {`${dayjs(notes?.created_at).format(
                              "DD/MM/YYYY"
                            )} | ${dayjs(notes?.created_at).format("h:mm A")}`}
                          </Typography>
                          <Typography
                            sx={{
                              color: colors?.black,
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            {`${
                              notes?.created_by?.first_name
                                ? notes?.created_by?.first_name + " "
                                : ""
                            }${notes?.created_by?.last_name ?? ""}`}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            alignItems: "flex-start",
                            paddingVertical: 7,
                            flex: 0.75,
                          }}
                        />
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            )}

            <Box className={style.buttons} sx={{ marginBottom: "20px" }}>
              <Button
                sx={{
                  width: "200px",
                  height: "55px",
                  ":hover": { backgroundColor: "transparent" },
                }}
                variant="outlined"
                onClick={() => navigate(-1)}
                label={"Close"}
              />
            </Box>
          </Box>
        )}
      </Box>
      {previewVisible && selectedImage && (
        <PreviewDialog
          visible={previewVisible}
          imageUrl={selectedImage}
          onClose={() => {
            setPreviewVisible(false);
            setSelectedImage(undefined);
          }}
        />
      )}
    </Box>
  );
};

export default Details;
