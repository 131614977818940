import { Box } from "@mui/material";

// Functional component representing the Contact page
function Contact() {
  return (
    <Box>
      <h1>Contact</h1>
    </Box>
  );
}
export default Contact;
