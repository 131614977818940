import {
  Box,
  TextField as MuiInput,
  InputProps as MuiInputProps,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { FieldErrors } from "react-hook-form";

import style from "./input.module.css";

interface InputProps extends Omit<MuiInputProps, "error"> {
  register: any;
  label?: string;
  error?: string;
  errors?: FieldErrors;
}

const Input = (props: InputProps) => {
  const {
    register,
    label,
    errors = {},
    placeholder,
    name,
    required,
    ...restProps
  } = props;

  const error = props.error || (errors[name ?? ""]?.message as string);

  return (
    <Box sx={{ flex: 1, flexDirection: "column" }}>
      {label && (
        <Typography
          display="block"
          className={clsx(style.inputLabel, required ? style.required : null)}
        >
          {label}
        </Typography>
      )}
      <MuiInput
        sx={{ display: "flex" }}
        placeholder={placeholder}
        disableUnderline
        type={name}
        {...register(name)}
        error={Boolean(error)}
        helperText={error}
        FormHelperTextProps={{ sx: { ml: 0 } }}
        {...restProps}
        InputProps={{ sx: { backgroundColor: "primary.light" } }}
      />
    </Box>
  );
};
export default Input;
