import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { colors } from "../../theme/colors";

interface CommonCustomMenuProps {
  label: string;
  menuItems: {
    icon: JSX.Element;
    text: string;
    onClick: () => void;
    disableRipple?: boolean;
  }[];
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 180,
    boxShadow: `${colors.white} 0px 0px 0px 0px, ${colors.lighterGrey} 0px 0px 0px 1px, ${colors.lighterGrey} 0px 10px 15px -3px, ${colors.lightPrimary} 0px 4px 6px -2px`,
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      ":hover": {
        backgroundColor: colors.lightPrimary,
      },
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const CustomMenu: React.FC<CommonCustomMenuProps> = ({ label, menuItems }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        sx={{
          backgroundColor: colors.lightRed,
          color: colors.red,
          ":hover": { backgroundColor: colors.lightRed },
        }}
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {label}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuItems.map((menuItem, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              menuItem.onClick();
              handleClose();
            }}
            disableRipple={menuItem.disableRipple}
          >
            {menuItem.icon}
            {menuItem.text}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};

export default CustomMenu;
