import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { Outlet, useNavigate } from "react-router-dom";

import { Button } from "../components";
import Logo from "../assets/images/Logo.svg";
import { colors } from "../theme/colors";

// Props for the BasicLayout component
interface Props {
  window?: () => Window;
}

// Navigation items with links, IDs, and hrefs
const navItems = [
  {
    link: "Services",
    id: "services",
    href: "/",
  },
  {
    link: "Technology",
    id: "technology",
    href: "/technology",
  },
  {
    link: "Contact Us",
    id: "contact",
    href: "/contact",
  },
  {
    link: "About Us",
    id: "about",
    href: "/aboutus",
  },
];

// Main BasicLayout component
export default function BasicLayout(props: Props) {
  const navigate = useNavigate();

  // State to track the active route index
  const [activeRoute, setActiveRoute] = useState(0);

  // Function to handle navigation item click
  const handleClick = (link: string) => {
    navigate(link);
  };

  return (
    // Main layout structure using Material-UI components
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            py: 2,
            columnGap: "35px",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "39px" }}
          >
            <img src={Logo} alt="Logo" />
            <Box>
              {navItems.map((item, i) => {
                const active = activeRoute === i;
                return (
                  <Button
                    variant="text"
                    key={i}
                    id={item.id}
                    onClick={() => {
                      handleClick(item.href);
                      setActiveRoute(i);
                    }}
                    disableRipple
                    sx={{
                      color: "primary.light",
                      fontSize: 16,
                      fontWeight: active ? "900" : "600",
                      textDecoration: active ? "underline" : "none",
                      width: "120px",
                      ":hover": { backgroundColor: colors.primary },
                    }}
                    label={item.link}
                  />
                );
              })}
            </Box>
          </Box>
          <Box>
            <Button
              onClick={() => {
                navigate("/login");
                setActiveRoute(-1);
              }}
              sx={{
                backgroundColor: colors.darkRed,
                borderRadius: "10px",
                width: "141px",
                height: "64px",
                color: "primary.light",
                fontSize: "16px",
                fontWeight: "700",
                ":hover": {
                  backgroundColor: colors.darkerRed,
                  fontWeight: "900",
                },
              }}
              label={"Login"}
            />
          </Box>
        </Toolbar>
      </AppBar>
      <Box pt="97px" width={"100%"}>
        <Outlet />
      </Box>
    </Box>
  );
}
