import { Box, Dialog, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

import style from "./dialog.module.css";
import { Button } from "../.";
import { colors } from "../../theme/colors";

type DialogProps = {
  visible: boolean;
  onClose?: () => void;
  confirmButtonTitle?: string;
  onConfirm?: () => void;
  loading?: boolean;
  details: Hospital | Laboratory | UserRole | Surgeon | Users | null;
};

export default function DetailsDialog({
  visible,
  details,
  onClose,
  confirmButtonTitle = "Ok",
  onConfirm,
  loading = false,
}: DialogProps) {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: colors.white,
          border: `1px solid ${colors.black}`,
          borderRadius: "4px",
          flexDirection: "column",
          width: "600px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: "600px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "15px",
              justifyContent: "space-between",
              alignItems: "center",
              width: "600px",
            }}
          >
            <Typography
              variant="h2"
              component="div"
              fontSize={16}
              fontWeight={700}
              color={colors.modalHeading}
              paddingX={"30px"}
              textAlign={"center"}
              width={"100%"}
            >
              {"Log Details"}
            </Typography>
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => handleClose()}
              onMouseDown={() => handleClose()}
              sx={{ position: "absolute", right: 10 }}
            >
              <CloseIcon sx={{ cursor: "pointer", height: 20, width: 20 }} />
            </IconButton>
          </Box>

          <Box
            sx={{
              height: "100%",
              paddingX: "15px",
              alignItems: "center",
              width: "550px",
            }}
          >
            <Typography className={style.detailHeading}>
              Creation Details
            </Typography>
            <Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>Created by:</Typography>
                <Typography className={style.value}>
                  {details?.created_by?.username ?? "-"}
                </Typography>
              </Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>Created on:</Typography>
                <Typography className={style.value}>
                  {details?.created_at
                    ? moment(details?.created_at).format(
                        "MMMM DD YYYY, h:mm:ss a"
                      )
                    : "-"}
                </Typography>
              </Box>
            </Box>
            <Typography className={style.detailHeading}>
              Updation Details
            </Typography>
            <Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>Updated by:</Typography>
                <Typography className={style.value}>
                  {details?.updated_by?.username ?? "-"}
                </Typography>
              </Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>
                  Last updated on:
                </Typography>
                <Typography className={style.value}>
                  {details?.updated_at
                    ? moment(details?.updated_at).format(
                        "MMMM DD YYYY, h:mm:ss a"
                      )
                    : "-"}
                </Typography>
              </Box>
            </Box>
            {details?.reason_to_deactivate && (
              <Box>
                <Typography className={style.detailHeading}>
                  Deactivation Details
                </Typography>
                <Box>
                  <Box className={style.detailsRow}>
                    <Typography className={style.title}>
                      Reason for deactivation:
                    </Typography>
                    <Typography className={style.value}>
                      {details?.reason_to_deactivate ?? "-"}
                    </Typography>
                  </Box>
                  <Box className={style.detailsRow}>
                    <Typography className={style.title}>
                      Date of Deactivation:
                    </Typography>
                    <Typography className={style.value}>
                      {details?.last_deactivated_at
                        ? moment(details?.last_deactivated_at).format(
                            "MMMM DD YYYY, h:mm:ss a"
                          )
                        : "-"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box className={style.buttons}>
          <Button
            sx={{
              width: "100px",
              height: "35px",
              ":hover": { backgroundColor: colors.primary },
            }}
            variant="contained"
            type="submit"
            onClick={() => {
              handleConfirm();
            }}
            label={confirmButtonTitle}
            loading={loading}
          />
        </Box>
      </Box>
    </Dialog>
  );
}
