import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { usePlacesWidget } from "react-google-autocomplete";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import style from "../surgeon.module.css";
import { addressSchema } from "../../../yup";
import { appConfig } from "../../../hooks/useConfig";
import { Input } from "../../../components/UserInput";
import { Button } from "../../../components";
import { colors } from "../../../theme/colors";

type FormState = typeof addressSchema.__outputType;

interface AddressFormProps {
  address?: AddressDetails;
  onCancel?: () => void;
  onSave?: (args: DeliveryAddressPayload) => void;
  isLoading?: boolean;
  formRegister?: any;
  formSchema?: any;
  formErrors?: any;
  setFormValue?: any;
  clearFormErros?: any;
}

const AddressForm = ({
  address,
  onCancel,
  onSave,
  isLoading,
  formRegister,
  formSchema,
  formErrors,
  setFormValue,
  clearFormErros,
}: AddressFormProps) => {
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<FormState>({
    resolver: yupResolver(formSchema ?? addressSchema),
    mode: "onChange",
  });

  const { ref: materialRef } = usePlacesWidget({
    apiKey: appConfig?.GOOGLE_API_KEY,
    inputAutocompleteValue: "off",
    options: {
      types: [
        "street_address",
        "street_number",
        "intersection",
        "route",
        "health",
      ],
      componentRestrictions: { country: "au" },
    },
    onPlaceSelected: (place) => {
      let suburb = "";
      let state = "";
      let postal = "";
      if (place) {
        if (clearFormErros) {
          clearFormErros("postcode");
          clearFormErros("suburb");
          clearFormErros("state");
        } else {
          clearErrors("postcode");
          clearErrors("suburb");
          clearErrors("state");
        }
        const address_comp = place?.address_components;
        if (address_comp) {
          for (const c of address_comp) {
            const types: Array<string> = c.types;
            if (
              !suburb &&
              (types.includes("locality") ||
                types.includes("sublocality") ||
                types.includes("sublocality_level_1") ||
                types.includes("postal_town") ||
                types.includes("administrative_area_level_3"))
            ) {
              suburb = c.short_name;
            }

            if (types.includes("administrative_area_level_1")) {
              state = c.short_name;
            } else if (types.includes("postal_code")) {
              postal = c.short_name;
            }
          }
          const address_arr = place?.formatted_address?.split(", ");
          if (setFormValue) {
            if (address_arr.length > 3) {
              setFormValue(
                "street_address",
                address_arr.slice(0, 2).join(", ")
              );
            } else {
              setFormValue("street_address", address_arr[0]);
            }
            setFormValue("state", state);
            setFormValue("suburb", suburb);
            setFormValue("postcode", postal);
            const lat: any = get(place, "geometry.location.lat", () => {});
            const lng: any = get(place, "geometry.location.lng", () => {});
            setFormValue("latitude", lat() ?? 0);
            setFormValue("longitude", lng() ?? 0);
          } else {
            if (address_arr.length > 3) {
              setValue("street_address", address_arr.slice(0, 2).join(", "));
            } else {
              setValue("street_address", address_arr[0]);
            }
            setValue("state", state);
            setValue("suburb", suburb);
            setValue("postcode", postal);
            const lat: any = get(place, "geometry.location.lat", () => {});
            const lng: any = get(place, "geometry.location.lng", () => {});
            setLatitude(lat() ?? 0);
            setLongitude(lng() ?? 0);
          }
        }
      }
    },
  });

  useEffect(() => {
    if (!isEmpty(address)) {
      reset({
        postcode: address?.postcode ?? "",
        state: address?.state ?? "",
        suburb: address?.suburb ?? "",
        street_address: address?.street_address ?? "",
        additional_address_detail: address?.additional_address_detail ?? "",
        email: address?.email ?? "",
        telephone: address?.telephone ?? "",
        fax_number: address?.fax_number ?? "",
        health_link: address?.health_link ?? "",
        provider_number: address?.provider_number ?? "",
      });
      setLatitude(address?.latitude ?? 0);
      setLongitude(address?.longitude ?? 0);
    }
  }, [address, reset]);

  const onSubmit = async (values: FormState) => {
    let payload: DeliveryAddressPayload = {
      street_address: values.street_address,
      suburb: values.suburb,
      state: values.state,
      postcode: values.postcode,
      latitude: latitude,
      longitude: longitude,
      email: values.email,
      telephone: values.telephone,
      health_link: values.health_link,
      provider_number: values.provider_number,
      fax_number: values.fax_number,
      additional_address_detail: values?.additional_address_detail,
    };
    if (onSave) {
      onSave(payload);
    }
  };

  return (
    <Box>
      <Box className={style.flexTop}>
        <Box className={style.row}>
          <Input
            placeholder="Enter street address"
            label="Street Address"
            required
            register={formRegister ?? register}
            name="street_address"
            errors={formErrors ?? errors}
            inputRef={materialRef}
          />
          <Input
            placeholder="Suite No./Apt./Building"
            label="Suite No./Apt./Building"
            register={formRegister ?? register}
            name="additional_address_detail"
            errors={formErrors ?? errors}
          />
        </Box>
        <Box className={style.row}>
          <Input
            placeholder="Enter suburb"
            label="Suburb"
            required
            register={formRegister ?? register}
            name="suburb"
            errors={formErrors ?? errors}
          />
          <Input
            placeholder="Enter state"
            label="State"
            required
            register={formRegister ?? register}
            name="state"
            errors={formErrors ?? errors}
          />
        </Box>
        <Box className={style.row}>
          <Input
            placeholder="Enter postcode"
            label="Postcode"
            required
            register={formRegister ?? register}
            name="postcode"
            errors={formErrors ?? errors}
          />
          <Input
            placeholder="Enter email"
            label="Email"
            register={formRegister ?? register}
            name="email"
            errors={formErrors ?? errors}
          />
        </Box>
        <Box className={style.row}>
          <Input
            placeholder="Enter telephone number"
            label="Telephone Number"
            register={formRegister ?? register}
            name="telephone"
            errors={formErrors ?? errors}
          />
          <Input
            placeholder="Enter fax number"
            label="Fax Number"
            register={formRegister ?? register}
            name="fax_number"
            errors={formErrors ?? errors}
          />
        </Box>
        <Box className={style.row}>
          <Input
            placeholder="Enter health link"
            label="Health Link"
            register={formRegister ?? register}
            name="health_link"
            errors={formErrors ?? errors}
          />
          <Input
            placeholder="Enter provider number"
            label="Provider Number"
            register={formRegister ?? register}
            name="provider_number"
            errors={formErrors ?? errors}
          />
        </Box>
        {!formRegister && (
          <Box className={style.buttons} sx={{ marginBottom: "20px" }}>
            <Button
              sx={{
                width: "200px",
                height: "55px",
                ":hover": { backgroundColor: "transparent" },
              }}
              variant="outlined"
              onClick={() => {
                if (onCancel) {
                  onCancel();
                }
              }}
              label={"Cancel"}
            />
            <Button
              sx={{
                width: "200px",
                height: "55px",
                ":hover": { backgroundColor: colors.primary },
              }}
              variant="contained"
              label={"Save"}
              type="submit"
              loading={isLoading}
              onClick={handleSubmit(onSubmit)}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AddressForm;
