import {
  Box,
  Typography,
  CircularProgress,
  Grid,
  Checkbox,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

import style from "./user.module.css";
import { useGetUserRoleDetailsQuery } from "../../services/api";
import { Button } from "../../components";
import { colors } from "../../theme/colors";

function UserRoleDetails() {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: userRoleDetails = {},
    isLoading,
    isFetching,
  } = useGetUserRoleDetailsQuery(id ?? "");

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "30px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          border: `1px solid ${colors.grey}`,
          borderRadius: "4px",
          height: "100%",
        }}
      >
        {isLoading || isFetching ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "calc(100vh - 97px)",
            }}
          >
            <CircularProgress size={40} sx={{ marginLeft: "10px" }} />
          </Box>
        ) : (
          <Box sx={{ height: "100%", padding: "15px 40px" }}>
            <Typography className={style.userDetailHeading}>
              User Role Details
            </Typography>
            <Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>Name</Typography>
                <Typography className={style.value}>
                  {userRoleDetails?.name}
                </Typography>
              </Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>User Group</Typography>
                <Typography className={style.value}>
                  {userRoleDetails?.user_group}
                </Typography>
              </Box>
              <Box
                className={style.detailsRow}
                sx={{ alignItems: "flex-start !important" }}
              >
                <Typography className={style.title}>Services</Typography>
                <Box className={style.value}>
                  <Grid container columnSpacing={3}>
                    {userRoleDetails?.services?.map((service: Service) => {
                      return (
                        <Grid
                          item
                          xs={userRoleDetails?.services?.length > 10 ? 6 : 12}
                          key={service.id}
                        >
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            paddingY={1}
                          >
                            <Checkbox
                              checked={true}
                              disableTouchRipple
                              sx={{
                                transform: "scale(0.92)",
                                padding: "0px",
                              }}
                              disabled
                            />
                            <Typography sx={{ marginLeft: 1 }}>
                              {service.code_name}
                            </Typography>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Box>
            </Box>
            {userRoleDetails.reason_to_deactivate && (
              <Box>
                <Typography className={style.userDetailHeading}>
                  Deactivation Details
                </Typography>
                <Box>
                  <Box className={style.detailsRow}>
                    <Typography className={style.title}>
                      Reason for deactivation
                    </Typography>
                    <Typography className={style.value}>
                      {userRoleDetails?.reason_to_deactivate}
                    </Typography>
                  </Box>
                  <Box className={style.detailsRow}>
                    <Typography className={style.title}>
                      Date of Deactivation
                    </Typography>
                    <Typography className={style.value}>
                      {userRoleDetails?.last_deactivated_at
                        ? moment(userRoleDetails?.last_deactivated_at).format(
                            "MMMM DD YYYY, h:mm:ss a"
                          )
                        : "-"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
            <Box className={style.buttons} sx={{ marginBottom: "20px" }}>
              <Button
                sx={{
                  width: "200px",
                  height: "55px",
                  ":hover": { backgroundColor: "transparent" },
                }}
                variant="outlined"
                onClick={() => navigate(-1)}
                label={"Close"}
              />
              {userRoleDetails?.is_active && (
                <Button
                  sx={{
                    width: "200px",
                    height: "55px",
                    ":hover": { backgroundColor: colors.primary },
                  }}
                  variant="contained"
                  label={"Edit"}
                  onClick={() =>
                    navigate(`/user/roles/edit/${userRoleDetails?.id}`)
                  }
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default UserRoleDetails;
