import clsx from "clsx";
import style from "./loader.module.css";

function Loader({ logoStyle }: any) {
  // use the common loader to show the loading indicator for the screen
  return (
    <div className={clsx(style.spinner, logoStyle)}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
export default Loader;
