import { useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import toast from "react-hot-toast";

import style from "./admin.module.css";
import {
  useGetUserDetailsQuery,
  useResendInvitationMutation,
} from "../../services/api";
import { Button, LabelBox } from "../../components";
import { colors } from "../../theme/colors";

const AdminDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [resendInvitation] = useResendInvitationMutation();

  const [isResendingInvitation, setIsResendingInvitation] = useState(false);
  const [message, setMessage] = useState<LabelMessageType | null>(null);

  const { data: adminDetails, isLoading } = useGetUserDetailsQuery(id ?? "");

  const resendUserInvitation = async () => {
    try {
      setIsResendingInvitation(true);
      const payload: ResendInvitationPayload = {
        id: adminDetails?.id ?? 0,
      };
      const result = await resendInvitation(payload).unwrap();
      setTimeout(() => {
        setIsResendingInvitation(false);
      });
      toast(result?.message ?? "", {
        duration: 2000,
      });
    } catch (error: any) {
      setIsResendingInvitation(false);
      setMessage({
        type: "error",
        text:
          error?.message?.toString() ||
          "Something went wrong. Please try again later.",
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "30px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          border: `1px solid ${colors.grey}`,
          borderRadius: "4px",
          height: "100%",
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "calc(100vh - 97px)",
            }}
          >
            <CircularProgress size={40} sx={{ marginLeft: "10px" }} />
          </Box>
        ) : (
          <Box sx={{ height: "100%", padding: "0px 20px" }}>
            <Typography className={style.adminDetailHeading}>
              Admin Details
            </Typography>
            <Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>Full Name</Typography>
                <Typography className={style.value}>
                  {`${adminDetails?.last_name?.toUpperCase() ?? ""}, ${
                    adminDetails?.first_name ?? ""
                  } ${adminDetails?.prefix ?? ""}`}
                </Typography>
              </Box>
              {adminDetails?.title && (
                <Box className={style.detailsRow}>
                  <Typography className={style.title}>Title</Typography>
                  <Typography className={style.value}>
                    {adminDetails?.title}
                  </Typography>
                </Box>
              )}
              <Typography className={style.adminDetailHeading}>
                Contact Details
              </Typography>
              {adminDetails?.contact_number && (
                <Box className={style.detailsRow}>
                  <Typography className={style.title}>Mobile Number</Typography>
                  <Typography className={style.value}>
                    {adminDetails?.contact_number}
                  </Typography>
                </Box>
              )}
              {adminDetails?.email && (
                <Box className={style.detailsRow}>
                  <Typography className={style.title}>Email Address</Typography>
                  <Typography className={style.value}>
                    {adminDetails?.email}
                  </Typography>
                </Box>
              )}

              {adminDetails?.reason_to_deactivate && (
                <Box>
                  <Typography className={style.operatingTheatreDetailHeading}>
                    Deactivation Details
                  </Typography>
                  <Box>
                    <Box className={style.detailsRow}>
                      <Typography className={style.title}>
                        Reason for deactivation
                      </Typography>
                      <Typography className={style.value}>
                        {adminDetails?.reason_to_deactivate}
                      </Typography>
                    </Box>
                    <Box className={style.detailsRow}>
                      <Typography className={style.title}>
                        Date of Deactivation
                      </Typography>
                      <Typography className={style.value}>
                        {adminDetails?.last_deactivated_at
                          ? dayjs(adminDetails?.last_deactivated_at).format(
                              "MMMM DD YYYY, h:mm:ss a"
                            )
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}

              {message && (
                <Box mt={2}>
                  <LabelBox
                    boxProps={{
                      height: 50,
                      display: "flex",
                      alignItems: "center",
                    }}
                    data={message}
                    onClose={() => setMessage(null)}
                  />
                </Box>
              )}
              <Box className={style.buttons} sx={{ marginBottom: "20px" }}>
                {!adminDetails?.has_changed_initial_password ? (
                  <>
                    <Button
                      sx={{
                        width: "200px",
                        height: "55px",
                        ":hover": { backgroundColor: "transparent" },
                      }}
                      variant="outlined"
                      onClick={() => {
                        navigate(`/admins/edit/${adminDetails?.id}`);
                      }}
                      label={"Edit"}
                    />
                    <Button
                      sx={{
                        width: "200px",
                        height: "55px",
                        ":hover": { backgroundColor: colors.primary },
                      }}
                      variant="contained"
                      label={"Resend Invitation"}
                      onClick={() => {
                        resendUserInvitation();
                      }}
                      loading={isResendingInvitation}
                    />
                  </>
                ) : (
                  <>
                    <Button
                      sx={{
                        width: "200px",
                        height: "55px",
                        ":hover": { backgroundColor: "transparent" },
                      }}
                      variant="outlined"
                      onClick={() => navigate(-1)}
                      label={"Close"}
                    />
                    <Button
                      sx={{
                        width: "200px",
                        height: "55px",
                        ":hover": { backgroundColor: colors.primary },
                      }}
                      variant="contained"
                      label={"Edit"}
                      onClick={() => {
                        navigate(`/admins/edit/${adminDetails?.id}`);
                      }}
                      loading={isResendingInvitation}
                    />
                  </>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AdminDetails;
