import { Box } from "@mui/material";

// Functional component representing the Dashboard page
function Dashboard() {
  return (
    <Box>
      <h1>Dashboard</h1>
    </Box>
  );
}
export default Dashboard;
