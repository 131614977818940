import {
  Box,
  MenuItem,
  Select,
  SelectProps,
  Typography,
  OutlinedInput,
} from "@mui/material";
import { FieldErrors } from "react-hook-form";
import isEmpty from "lodash/isEmpty";

import styles from "./selectinput.module.css";
import { colors } from "../../theme/colors";

interface SelectItem {
  id: number;
  label: string;
  value: string;
}

interface SelectInputProps extends SelectProps {
  register: any;
  label?: string;
  errors?: FieldErrors;
  data?: SelectItem[];
  value?: SelectItem | string | null;
  disabled?: boolean;
}

function SingleSelectInput(props: SelectInputProps) {
  const {
    label,
    data,
    placeholder,
    required,
    register,
    name = "",
    error,
    errors = {},
    value,
    disabled = false,
  } = props;

  return (
    <Box sx={{ flex: 1, flexDirection: "column" }}>
      <Typography className={styles.inputLabel} sx={{ display: "flex" }}>
        {label}
        {required && (
          <Typography sx={{ color: "red", marginLeft: 0.7 }}>{" *"}</Typography>
        )}
      </Typography>
      <Select
        sx={{
          display: "flex",
          border: `1px solid ${errors[name] ? colors.red : colors.white}`,
        }}
        id="select-helper"
        displayEmpty
        {...register(name)}
        input={<OutlinedInput />}
        value={value}
        renderValue={(selected: SelectItem) => {
          if (isEmpty(selected)) {
            return (
              <Typography sx={{ color: colors.grey }}>{placeholder}</Typography>
            );
          }
          return selected;
        }}
        error={error}
        disabled={disabled}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 350, // Limit the dropdown height
              overflow: "auto", // Enable scrolling
            },
          },
        }}
      >
        {data?.map((item) => (
          <MenuItem key={item.id} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {errors[name] && (
        <Typography sx={{ color: colors.red, fontSize: 12, marginTop: 0.5 }}>
          {errors[name].message?.toString() ?? ""}
        </Typography>
      )}
    </Box>
  );
}

export default SingleSelectInput;
