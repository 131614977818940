import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import isEmpty from "lodash/isEmpty";
import { toast } from "react-hot-toast";

//importing custom modules
import { Button } from "../../components";
import Input from "../../components/UserInput/Input";
import { operatingTheatreSchema } from "../../yup";

// importing styleas
import style from "./operating-theatre.module.css";
import { colors } from "../../theme/colors";

// importing api
import {
  useUpdateOperatingTheatreMutation,
  useGetOperatingTheatreDetailsQuery,
} from "../../services/api";
import { LabelBox } from "../../components";
import { RootState, useAppSelector } from "../../store";

type FormState = typeof operatingTheatreSchema.__outputType;

const EditOperatingTheatre = () => {
  const { id } = useParams();
  const navigation = useNavigate();
  const [updateOperatingTheatre] = useUpdateOperatingTheatreMutation();

  const { userProfileDetails } = useAppSelector(
    (state: RootState) => state.auth
  );

  // Get Operating Theatre Details mutation hook
  const {
    data: operatingTheatreDetails,
    isLoading,
    isFetching,
  } = useGetOperatingTheatreDetailsQuery(id ?? "");

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<LabelMessageType | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormState>({
    resolver: yupResolver(operatingTheatreSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (!isEmpty(operatingTheatreDetails)) {
      reset({
        name: operatingTheatreDetails?.name,
        room_number: operatingTheatreDetails?.room_number ?? "",
        floor: operatingTheatreDetails?.floor ?? "",
        building: operatingTheatreDetails?.building ?? "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operatingTheatreDetails]);

  const onSubmit = async (values: FormState) => {
    try {
      setLoading(true);

      const payload: EditOperatingTheatrePayload = {
        id: parseInt(id ?? "0"),
        name: values?.name,
        hospital_id: userProfileDetails?.hospital?.id ?? 0,
        room_number: values?.room_number ?? "",
        floor: values?.floor ?? "",
        building: values?.building ?? "",
      };

      const result = await updateOperatingTheatre(payload).unwrap();
      setLoading(false);
      toast(result?.message ?? "Operating Theatre updated successfully.", {
        duration: 2000,
      });
      navigation(-1);
    } catch (error: any) {
      setMessage({
        type: "error",
        text: error?.data?.detail?.toString() || "Please enter valid details",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className={style.container}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        {(isFetching || isLoading) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "60vh",
              width: "calc(100vw - 400px)",
              zIndex: 999,
              backgroundColor: "transparent",
              position: "absolute",
            }}
          >
            <CircularProgress size={40} />
          </Box>
        )}
        <Box>
          <Typography className={style.detailHeading}>
            Operating Theatre Details
          </Typography>
          <Box className={style.flexTop} sx={{ marginTop: "20px" }}>
            <Box className={style.row}>
              <Input
                placeholder="Theatre Name"
                label="Theatre Name"
                required
                register={register}
                name="name"
                errors={errors}
              />
              <Input
                placeholder="Room Number"
                label="Room Number"
                register={register}
                name="room_number"
                errors={errors}
              />
            </Box>
            <Box className={style.row}>
              <Input
                placeholder="Floor"
                label="Floor"
                register={register}
                name="floor"
                errors={errors}
              />
              <Input
                placeholder="Building"
                label="Building"
                register={register}
                name="building"
                errors={errors}
              />
            </Box>

            <Box className={style.row}></Box>
            {message && (
              <Box mt={2}>
                <LabelBox
                  boxProps={{
                    height: 50,
                    display: "flex",
                    alignItems: "center",
                  }}
                  data={message}
                  onClose={() => setMessage(null)}
                />
              </Box>
            )}
            <Box className={style.buttons}>
              <Button
                sx={{
                  width: "200px",
                  height: "55px",
                }}
                variant="outlined"
                onClick={() => navigation(-1)}
                label="Cancel"
              />
              <Button
                loading={loading}
                sx={{
                  width: "200px",
                  height: "55px",
                  ":hover": { backgroundColor: colors.primary },
                }}
                variant="contained"
                type="submit"
                label="Save"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EditOperatingTheatre;
