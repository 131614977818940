import {
  useState,
  useEffect,
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactNode,
  ReactPortal,
} from "react";
import {
  Box,
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Typography,
  ListItemButton,
  Collapse,
  SxProps,
  Theme,
  ListItemIcon,
  ListItemText,
  Avatar,
  IconButton,
  Grid,
} from "@mui/material";
import {
  NavLink,
  Outlet,
  useNavigate,
  useLocation,
  To,
} from "react-router-dom";
import toast from "react-hot-toast";
import isEmpty from "lodash/isEmpty";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  useLogoutMutation,
  useRefreshTokenMutation,
  useLazyGetProfileDetailsQuery,
} from "../services/api";
import { RootState, useAppDispatch, useAppSelector } from "../store";
import {
  logout as logoutAction,
  login as loginAction,
  setProfileDetails,
} from "../store/authReducer";
import { ReactComponent as Home } from "../assets/svg/home.svg";
import { ReactComponent as Hospitals } from "../assets/svg/hospitals.svg";
import { ReactComponent as Laboratories } from "../assets/svg/Laboratories.svg";
import Management from "../assets/svg/Management.png";
import { ReactComponent as ModuleManagement } from "../assets/svg/user.svg";
import Audit from "../assets/svg/AuditLogs.png";
import { ReactComponent as Logo } from "../assets/images/Logo.svg";
import { ReactComponent as Nurses } from "../assets/svg/nurses.svg";
import { colors } from "../theme/colors";
import { ReactComponent as Surgeons } from "../assets/svg/surgeons.svg";
import { ReactComponent as Users } from "../assets/svg/user.svg";
import { ReactComponent as OperationTheatre } from "../assets/svg/operation_theatre.svg";
import { ReactComponent as Logout } from "../assets/svg/logout.svg";

const drawerWidth = 300;

const styles: Record<string, SxProps<Theme>> = {
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    ml: `${drawerWidth}px`,
    backgroundColor: colors.white,
    boxShadow: "none",
    borderBottom: `1px solid ${colors.lighterGrey}`,
  },
  header: {
    display: "flex",
    alignItems: "center",
    columnGap: "12px",
  },
  headerIcons: {
    display: "flex",
    alignItems: "center",
    columnGap: "32px",
  },
  formControl: {
    border: "0",
    ".MuiSelect-outlined": {
      paddingLeft: "0",
      paddingRight: "8px !important",
      color: colors.black,
      fontSize: "16px",
      fontWeight: "400",
    },
    ".MuiOutlinedInput-notchedOutline": {
      border: "0",
    },
  },
  sideMenu: {
    color: colors.lightestGrey,
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "150%",
    margin: "0px",
  },
};

const isAbsolutePath: Record<string, string> = {
  "/users": "User List",
  "/user/add": "Add User",
  "/user/roles": "User Roles",
  "/user/roles/add": "Add User Role",
  "/user/roles/details/": "User Role Details",
  "/user/roles/edit/": "Edit User Role",
};

const superAdminSideNavigationOptions = [
  {
    navItem: "Home",
    id: "home",
    pathLocation: "/home",
    icon: <Home />,
  },
  {
    navItem: "Hospitals",
    id: "hospitals",
    pathLocation: "/hospitals",
    icon: <Hospitals />,
  },
  {
    navItem: "Laboratories",
    id: "laboratories",
    pathLocation: "/laboratories",
    icon: <Laboratories />,
  },
  {
    navItem: "User Management",
    id: "users",
    pathLocation: "/users",
    icon: <ModuleManagement />,
    subItemMenu: [
      {
        navItem: "User List",
        id: "userlist",
        pathLocation: "/users",
        icon: <></>,
      },
      {
        navItem: "Add User",
        id: "laboratories",
        pathLocation: "/user/add",
        icon: <></>,
      },
      {
        navItem: "User Roles",
        id: "user-roles",
        pathLocation: "/user/roles",
        icon: <></>,
      },
    ],
  },
  {
    navItem: "Nurses",
    id: "nurses",
    pathLocation: "/nurses",
    icon: <Nurses />,
  },
  {
    navItem: "Admin",
    id: "admin",
    pathLocation: "/admins",
    icon: <ModuleManagement />,
  },
  {
    navItem: "Specimens",
    id: "specimens",
    pathLocation: "/specimens",
    icon: <Laboratories />,
  },
  {
    navItem: "Permissions",
    id: "permissions",
    pathLocation: "/module",
    icon: <img src={Management} alt="Management" />,
  },
  {
    navItem: "Audit Logs",
    id: "audit",
    pathLocation: "/audit",
    icon: <img src={Audit} alt="Audit" />,
  },
];

const hospitalAdminSideNavigationOptions = [
  {
    navItem: "Specimens",
    id: "specimens",
    pathLocation: "/specimens",
    icon: <Laboratories />,
  },
  {
    navItem: "Surgeons",
    id: "surgeons",
    pathLocation: "/surgeons",
    icon: <Surgeons />,
  },
  {
    navItem: "Nurses",
    id: "nurses",
    pathLocation: "/nurses",
    icon: <Nurses />,
  },
  {
    navItem: "Admins",
    id: "admin",
    pathLocation: "/admins",
    icon: <Users />,
  },
  {
    navItem: "Operation Theatres",
    id: "operating-theatres",
    pathLocation: "/operating-theatres",
    icon: <OperationTheatre />,
  },
];

export default function AuthLayout() {
  // Handles the logout mutation logic.
  const [logout, logoutResult] = useLogoutMutation();

  // Handles the token refreshing mutation logic.
  const [getNewToken, tokenResult] = useRefreshTokenMutation();

  // Get Profile Details mutation hook
  const [trigger, { data: profileDetails }] = useLazyGetProfileDetailsQuery();

  // Provides a function to navigate to different pages within the application.
  const navigate = useNavigate();

  const location = useLocation();
  const { pathname, state } = location;

  const [userName, setUserName] = useState("");
  const [refreshToken, setRefreshToken] = useState("");
  const [currentRouteName, setCurrentRouteName] = useState("");
  const [isHistoryAvailable, setIsHistoryAvailable] = useState(false);
  const [open, setOpen] = useState(false);
  const [sideNavigationMenu, setSideNavigationMenu] = useState<any[]>([]);

  // Retrieve token data from redux store auth state
  const {
    authToken,
    refreshToken: refresh_token,
    userRole,
  } = useAppSelector((state: RootState) => state.auth);

  const dispatch = useAppDispatch();

  const handleLogout = async () => {
    logout({ refresh_token: refreshToken });
    dispatch(logoutAction());
    navigate("/");
  };

  // Function to validate the access token stored in local storage
  const validateToken = async () => {
    // Check if tokens exists
    if (authToken && refresh_token) {
      // Set the refresh token state
      setRefreshToken(refresh_token);

      // Decode the access token payload
      const splittedToken = JSON.parse(window.atob(authToken.split(".")[1]));

      // Check if the access token has expired
      const isTokenExpired = Date.now() >= splittedToken.exp * 1000;

      // If the access token has expired, request a new token using the refresh token
      if (isTokenExpired) {
        getNewToken({ refresh_token });
      }
    } else {
      handleLogout();
    }
  };

  useEffect(() => {
    validateToken();
    trigger("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userRole === "OT_ADMIN") {
      setSideNavigationMenu(hospitalAdminSideNavigationOptions);
    } else {
      setSideNavigationMenu(superAdminSideNavigationOptions);
    }
  }, [userRole]);

  // useEffect to handle the result of the token refreshing mutation
  useEffect(() => {
    const { status, isSuccess, isError, data, error } = tokenResult;

    // Check if the token refreshing is successful
    if (status === "fulfilled" && isSuccess) {
      const { access } = data.token;

      // Calling loginAction dispatch to save the newly generated tokens
      if (access) {
        dispatch(
          loginAction({
            authToken: access,
            refreshToken: refreshToken,
          })
        );
      }
    } else if (status === "rejected" && isError) {
      // Show toast notification for error message
      toast((error as ErrorMessage).data?.detail);

      // Perform a logout if there is an error refreshing the token
      handleLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenResult]);

  // useEffect to handle the result of the logout mutation
  useEffect(() => {
    const { status, isSuccess, isError, error } = logoutResult;
    // Check if the logging out is successful
    if (status === "fulfilled" && isSuccess) {
      // navigate to the login page if logged out successfully
      navigate("/login");
    } else if (status === "rejected" && isError) {
      // Show toast notification for error message
      toast((error as ErrorMessage).data?.detail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutResult]);

  useEffect(() => {
    const arrangeHeader = () => {
      const arr: string[] = pathname.split("/");
      arr.shift();

      // set the profile details
      setUserName(profileDetails?.first_name + " " + profileDetails?.last_name);
      setIsHistoryAvailable(false);

      if (pathname?.includes("home")) {
        // if the route is home page
        setCurrentRouteName(`Welcome back, ${profileDetails?.first_name}`);
      } else if (isAbsolutePath.hasOwnProperty(pathname)) {
        // if its an absolute path
        if (pathname?.includes("operating")) {
          // if the route is home page
          setCurrentRouteName("Operating Theatres");
        } else {
          setCurrentRouteName(isAbsolutePath[pathname]);
        }
        setOpen(true);
        if (arr.length > 2) {
          setIsHistoryAvailable((prev) => !prev);
        }
      } else {
        // if it contain any more prop in the url show the back button
        if (arr.length > 1) {
          setIsHistoryAvailable((prev) => !prev);
        }
        if (pathname?.includes("operating")) {
          // if the route is home page
          setCurrentRouteName("Operating Theatres");
        } else {
          if (state) {
            setCurrentRouteName(state.label);
          } else {
            // set the current route heading
            setCurrentRouteName(
              arr[0].charAt(0).toUpperCase() + arr[0].slice(1)
            );
          }
        }
        setOpen(false);
      }
    };

    if (!isEmpty(profileDetails)) {
      arrangeHeader();
      dispatch(setProfileDetails({ profileDetails: profileDetails }));
    }
  }, [profileDetails, state, pathname, dispatch]);

  function stringAvatar(name: string) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  // Render the layout component
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" sx={styles.appBar}>
        <Toolbar
          sx={{
            height: "70px",
            justifyContent: "space-between",
          }}
        >
          <Grid display="flex" alignItems={"center"}>
            {isHistoryAvailable && (
              <IconButton onClick={() => navigate(-1)} aria-label="delete">
                <ArrowBackIcon fontSize="medium" sx={{ color: "#000000" }} />
              </IconButton>
            )}
            {currentRouteName && (
              <Typography
                variant="h5"
                noWrap
                sx={{
                  color: "#262626",
                  paddingRight: 2,
                }}
                component="div"
                fontWeight={"500"}
              >
                {currentRouteName}
              </Typography>
            )}
          </Grid>

          <Typography noWrap component="div" sx={styles.header}>
            <Box component="div" sx={{ marginRight: "10px" }}>
              {userName && (
                <Avatar
                  {...stringAvatar(userName)}
                  sx={{
                    backgroundColor: colors.primary,
                    fontSize: "16px",
                    width: 36,
                    height: 36,
                  }}
                />
              )}
            </Box>
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,

          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: colors.primary,
            justifyContent: "space-between",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box>
          <Box
            component="a"
            padding={"18px 24px"}
            sx={{ alignItems: "center", display: "flex" }}
          >
            <Logo width={102} />
          </Box>
          <Box maxHeight={"calc(100vh - 160px)"} overflow={"auto"}>
            <List disablePadding>
              {sideNavigationMenu.map((item, index) => (
                <Box key={item.id}>
                  <NavLink
                    key={item.id}
                    to={item.pathLocation}
                    onClick={(ev) => {
                      if (item.id === "logout") {
                        ev.preventDefault();
                        handleLogout();
                      } else if (!isEmpty(item?.subItemMenu)) {
                        setOpen(!open);
                      }
                    }}
                    style={({ isActive }) => {
                      return {
                        backgroundColor: isActive
                          ? isEmpty(item?.subItemMenu)
                            ? colors.hightlight
                            : ""
                          : "",
                        textDecoration: "none",
                        display: "flex",
                        margin: "0 8px",
                        borderRadius: "8px",
                        alignItems: "center",
                        padding: "5px 10px",
                      };
                    }}
                  >
                    <ListItemButton sx={{ paddingY: "16px" }}>
                      <ListItemIcon sx={{ minWidth: "40px" }}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        sx={styles.sideMenu}
                        primary={item.navItem}
                      />
                    </ListItemButton>
                    {!isEmpty(item?.subItemMenu) &&
                      (open ? (
                        <ExpandLess
                          sx={{ height: 30, width: 30, color: "primary.light" }}
                        />
                      ) : (
                        <ExpandMore
                          sx={{ height: 30, width: 30, color: "primary.light" }}
                        />
                      ))}
                  </NavLink>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    {item?.subItemMenu?.map(
                      (
                        subItem: {
                          pathLocation: To;
                          icon:
                            | string
                            | number
                            | boolean
                            | ReactElement<
                                any,
                                string | JSXElementConstructor<any>
                              >
                            | Iterable<ReactNode>
                            | ReactPortal
                            | null
                            | undefined;
                          navItem:
                            | string
                            | number
                            | boolean
                            | ReactElement<
                                any,
                                string | JSXElementConstructor<any>
                              >
                            | Iterable<ReactNode>
                            | ReactPortal
                            | null
                            | undefined;
                        },
                        subItemIndex: Key | null | undefined
                      ) => (
                        <NavLink
                          key={index}
                          to={subItem.pathLocation}
                          style={({ isActive }) => {
                            return {
                              backgroundColor: isActive
                                ? colors.hightlight
                                : "",
                              textDecoration: "none",
                              display: "block",
                              marginLeft: "40px",
                              marginRight: "20px",
                              borderRadius: "8px",
                            };
                          }}
                        >
                          <ListItemButton
                            key={subItemIndex}
                            sx={{ paddingY: "16px" }}
                          >
                            <ListItemIcon sx={{ minWidth: "40px" }}>
                              {subItem.icon}
                            </ListItemIcon>
                            <ListItemText
                              sx={styles.sideMenu}
                              primary={subItem.navItem}
                            />
                          </ListItemButton>
                        </NavLink>
                      )
                    )}
                  </Collapse>
                </Box>
              ))}
            </List>
          </Box>
        </Box>
        <NavLink
          to={"/logout"}
          onClick={(ev) => {
            ev.preventDefault();
            handleLogout();
          }}
          style={({ isActive }) => {
            return {
              backgroundColor: isActive ? colors.hightlight : "",
              textDecoration: "none",
              display: "flex",
              margin: "0 8px",
              borderRadius: "8px",
              alignItems: "center",
              padding: "5px 10px",
            };
          }}
        >
          <ListItemButton sx={{ paddingY: "16px" }}>
            <ListItemIcon sx={{ minWidth: "40px" }}>
              <Logout />
            </ListItemIcon>
            <ListItemText sx={styles.sideMenu} primary={"Log out"} />
          </ListItemButton>
        </NavLink>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          paddingY: 3,
          paddingX: 2,
          marginTop: "45px",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}
