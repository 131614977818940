import { Box } from "@mui/material";

import { colors } from "../theme/colors";

type MessageProp = {
  message: {
    type: string;
    text: string;
  };
};

const Message: React.FC<MessageProp> = ({ message }) => {
  return (
    <Box
      mt="10px"
      p="10px"
      fontSize="11px"
      bgcolor={colors.darkModalBackground}
      fontWeight={600}
      borderRadius="8px"
    >
      {message.text}
    </Box>
  );
};
export default Message;
