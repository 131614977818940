import { useCallback, useState } from "react";
import { Box, Input as MuiInput, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

// import custom components
import {
  ConfirmationDialog,
  InputDialog,
  DetailsDialog,
} from "../../components/Dialog";
import { CustomActionMenu } from "../../components/Menu";
import { CustomTable, StatusBadge } from "../../components/DataTable";
import { Button, LabelBox } from "../../components";
import Input from "../../components/UserInput/Input";
import { updateUserRoleSchema } from "../../yup";
import { ReactComponent as DeactivateIcon } from "../../assets/svg/deactivate.svg";
import { ReactComponent as EditIcon } from "../../assets/svg/edit.svg";
import { ReactComponent as EyeIcon } from "../../assets/svg/eye.svg";
import Logs from "../../assets/images/logs.png";

// importing css and svg  or images
import { ReactComponent as SearchIcon } from "../../assets/svg/Search.svg";
import style from "./user.module.css";
import { colors } from "../../theme/colors";

// importing services
import {
  useGetUserRolesQuery,
  useUpdateUserRoleStatusMutation,
} from "../../services/api";

type FormState = typeof updateUserRoleSchema.__outputType;

// Functional component representing the User role page
function UserRoles() {
  const [updateUserRoleStatus] = useUpdateUserRoleStatusMutation();
  const navigate = useNavigate();

  // all query parameters and pagination params
  const [queryParam, setQueryParam] = useState({
    name: "",
    limit: 10,
    offset: 0,
  });

  const { data: roleData } = useGetUserRolesQuery(queryParam);

  const [message, setMessage] = useState<LabelMessageType | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUserRole, setSelectedUserRole] = useState<UserRole | null>(
    null
  );
  const [reasonModalVisible, setReasonModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [logModalVisible, setLogModalVisible] = useState(false);

  const fields = [
    { id: "name", label: "User Role", flex: 2, sortable: true },
    { id: "user_group", label: "Group", flex: 3 },
    {
      id: "is_active",
      label: "Status",
      type: "badge",
      flex: 0.8,
      sortable: true,
      render: (item: UserRole) => <StatusBadge isActive={item.is_active} />,
    },
    {
      id: "logo_s3_key_name",
      label: "Actions",
      type: "action",
      render: (item: UserRole) => (
        <CustomActionMenu
          item={item}
          isActive={item.is_active}
          handleChange={handleMenu}
          actions={[
            {
              label: "View",
              value: "view",
              icon: <EyeIcon height={24} width={24} />,
            },
            {
              label: "Edit",
              value: "edit",
              icon: <EditIcon height={24} width={24} />,
            },
            {
              label: "Logs",
              value: "logs",
              icon: <Box component="img" src={Logs} width={24} />,
            },
            {
              label: item.is_active ? "Deactivate" : "Activate",
              value: item.is_active ? "deactivate" : "activate",
              icon: item.is_active ? (
                <DeactivateIcon height={24} width={24} />
              ) : (
                <PowerSettingsNewIcon height={24} width={24} />
              ),
            },
          ]}
        />
      ),
    },
  ];

  const handleMenu = (value: string, item: UserRole) => {
    setSelectedUserRole(item);
    if (value === "deactivate") {
      setModalVisible(true);
    } else if (value === "activate") {
      setModalVisible(true);
    } else if (value === "view") {
      navigate(`/user/roles/details/${item?.id}`);
    } else if (value === "edit") {
      navigate(`/user/roles/edit/${item?.id}`);
    } else if (value === "logs") {
      setLogModalVisible(true);
    }
  };

  // for searching in the query
  const handleSearch = useCallback(
    (e: any) => {
      setQueryParam((prevParams) => ({
        ...prevParams,
        name: e.target.value,
        offset: 0, // Reset offset when performing a new search
      }));
    },
    [setQueryParam]
  );

  // handle the page change
  const handlePageChange = useCallback(
    (e: any, newOffset: number) => {
      setQueryParam((prevParams) => ({
        ...prevParams,
        offset: prevParams.limit * newOffset,
      }));
    },
    [setQueryParam]
  );

  // handle the page size change
  const handleSizeChange = useCallback(
    (e: any) => {
      setQueryParam((prevParams) => ({
        ...prevParams,
        limit: e.target.value,
      }));
    },
    [setQueryParam]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<FormState>({
    resolver: yupResolver(updateUserRoleSchema),
  });

  const onSubmit = async (values: FormState) => {
    try {
      setIsLoading(true);
      const { reason_to_deactivate } = values;
      await updateUserRoleStatus({
        is_active: !selectedUserRole?.is_active,
        ids: [selectedUserRole?.id ?? 0],
        reason_to_deactivate,
      }).unwrap();
      setIsLoading(false);
      setReasonModalVisible(false);
      setValue("reason_to_deactivate", "");
      clearErrors();
    } catch (error: any) {
      setIsLoading(false);
      setMessage({
        type: "error",
        text: error?.message || error?.data?.detail || "Something went wrong!",
      });
    }
  };

  // Render the User role component
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "15px",
        }}
      >
        <MuiInput
          disableUnderline
          placeholder="Search by name...."
          type="search"
          startAdornment={<SearchIcon width={20} height={20} />}
          value={queryParam.name}
          onChange={handleSearch}
          sx={{ width: "550px", gap: "8px", borderRadius: "50px" }}
        />
      </Box>
      {message && (
        <Box mt={2}>
          <LabelBox
            boxProps={{ height: 50, display: "flex", alignItems: "center" }}
            data={message}
            onClose={() => setMessage(null)}
          />
        </Box>
      )}
      <Box style={{ maxHeight: "calc(100vh - 195px)", overflow: "auto" }}>
        {roleData && (
          <CustomTable<UserRole>
            fields={fields}
            paginationRequired={true}
            count={roleData?.count}
            data={roleData.items || []}
            onDoubleClick={(userRole: UserRole) => {
              navigate(`/user/roles/details/${userRole?.id}`);
            }}
            defaultOrderBy="name"
            handlePageChange={handlePageChange}
            onSizeChange={handleSizeChange}
            pagination={queryParam}
          />
        )}
      </Box>
      {logModalVisible && (
        <DetailsDialog
          visible={logModalVisible}
          details={selectedUserRole}
          onClose={() => {
            setLogModalVisible(false);
          }}
          onConfirm={() => {
            setLogModalVisible(false);
          }}
        />
      )}
      {modalVisible && (
        <ConfirmationDialog
          visible={modalVisible}
          message={`Are you sure you want to ${
            selectedUserRole?.is_active ? "deactivate " : "activate "
          }${selectedUserRole?.name}?`}
          onClose={() => {
            setModalVisible(false);
          }}
          onConfirm={() => {
            setModalVisible(false);
            if (selectedUserRole?.is_active) {
              setReasonModalVisible(true);
            } else {
              onSubmit({ reason_to_deactivate: "" });
            }
          }}
        />
      )}

      {reasonModalVisible && (
        <InputDialog
          visible={reasonModalVisible}
          onClose={() => {
            setReasonModalVisible(false);
            setValue("reason_to_deactivate", "");
            clearErrors();
          }}
        >
          <Box
            component="form"
            sx={{ marginX: "20px" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Typography
              variant="h2"
              component="div"
              fontSize={16}
              fontWeight={700}
              color={colors.modalHeading}
              marginBottom={"10px"}
              sx={{ display: "flex", flexDirection: "row" }}
            >
              {"Enter the reason for deactivation"}
              <Typography
                color={"red"}
                fontSize={16}
                fontWeight={700}
                sx={{ marginLeft: "5px", marginTop: "-5px" }}
              >
                {"*"}
              </Typography>
            </Typography>
            <Input
              placeholder=""
              name="reason_to_deactivate"
              required
              register={register}
              multiline
              rows={3}
              errors={errors}
              sx={{
                width: "100%",
                margin: "1px",
                borderWidth: "1px",
              }}
            />
            <Box className={style.horizontalButtons} sx={{ paddingX: "50px" }}>
              <Button
                sx={{
                  width: "150px",
                  height: "35px",
                  backgroundColor: colors.white,
                  ":hover": { backgroundColor: colors.white },
                }}
                variant="outlined"
                onClick={() => {
                  setReasonModalVisible(false);
                  setValue("reason_to_deactivate", "");
                  clearErrors();
                }}
                label={"Cancel"}
              />
              <Button
                sx={{
                  width: "150px",
                  height: "35px",
                  ":hover": { backgroundColor: colors.primary },
                }}
                variant="contained"
                type="submit"
                label={"Submit"}
                loading={isLoading}
              />
            </Box>
          </Box>
        </InputDialog>
      )}
    </Box>
  );
}
export default UserRoles;
