import { useEffect, useState } from "react";
import {
  Box,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TableHead,
  TableBody,
  Table as DataTable,
  Grid,
  TableSortLabel,
  TablePagination,
} from "@mui/material";
import clsx from "clsx";
import { UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import orderBy from "lodash/orderBy";
import isEmpty from "lodash/isEmpty";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

import styles from "./table.module.css";
import ActionMenu from "./ActionMenu";
import { ReactComponent as EyeIcon } from "../../assets/svg/eye.svg";
import { ReactComponent as DeactivateIcon } from "../../assets/svg/deactivate.svg";
import { ReactComponent as EditIcon } from "../../assets/svg/edit.svg";
import Logs from "../../assets/images/logs.png";

interface Field {
  value: string;
  id: string;
  type?: string | undefined;
  flex?: number;
  sortable?: boolean;
  field?: string;
}

type FilterType = Record<string, string>;

interface Props<T> {
  fields: Field[];
  isLargeRow?: boolean;
  data?: ResultDataType<T> | any;
  dataHook?: UseQuery<any>;
  filters?: FilterType;
  onChange?: (value: string, selectedItem: any) => void;
  onDoubleClick?: (selectedItem: any) => void;
  paginationRequired?: boolean;
  defaultOrder?: "asc" | "desc";
  defaultOrderBy?: string;
  query?: string;
  isActivationAllowed?: boolean;
}

const defaultFilters: FilterType = {};

function Table<T>(props: Props<T>) {
  // Destructuring props to extract specific values
  const {
    fields,
    isLargeRow,
    dataHook,
    filters = defaultFilters,
    onChange,
    onDoubleClick,
    paginationRequired = true,
    defaultOrder = "asc",
    defaultOrderBy = "",
    isActivationAllowed = false,
  } = props;

  // Initializing state to track the current page
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState("");
  const [order, setOrder] = useState<"asc" | "desc">(defaultOrder);
  const [orderby, setOrderby] = useState(defaultOrderBy);

  // Configuring pagination parameters: limit and offset
  const limit = rowsPerPage ?? 10;
  const offset = currentPage === 0 ? 0 : currentPage * limit;

  const paginationQuery = `?limit=${limit}&offset=${offset}`;

  // Using the dataHook to fetch data based on pagination parameters
  const hookResult = dataHook?.<ResultType<T>>(query);

  // Extracting data from the hook result or using data from props if unavailable
  const data: ResultDataType<T> | undefined = hookResult?.data ?? props.data;

  const dataCount = data?.count ?? 0;

  // Handling page change: updating current page and triggering a data refetch
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage !== currentPage) {
      setCurrentPage(newPage);
      hookResult?.refetch();
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  function params(data: { [x: string]: string | number | boolean }) {
    return Object.keys(data)
      .map((key) => `${key}=${encodeURIComponent(data[key])}`)
      .join("&");
  }

  useEffect(() => {
    if (!isEmpty(filters)) {
      setQuery(`?${params(filters)}`);
    } else {
      if (paginationRequired) {
        setQuery(paginationQuery);
      }
    }
    hookResult?.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (paginationRequired) {
      setQuery(paginationQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationQuery]);

  const createSortHandler = (property: any) => {
    const isDesc = orderby === property && order === "desc";
    setOrderby(property);
    setOrder(isDesc ? "asc" : "desc");
  };

  const getOrderedList = () => {
    return orderBy(data?.items, [(item: any) => item[orderby]], order);
  };

  const orderedList: any[] = getOrderedList();

  // for color handling
  const getBadgeColor = (status: string) => {
    if (
      status === "Awaiting Pickup" ||
      status === "Received by Lab" ||
      status === "No Action Required" ||
      status === "Resolved" ||
      status === "Ready For Pickup"
    ) {
      return "active";
    } else if (status === "Action Required") {
      return "inactive";
    } else {
      return "inactive";
    }
  };

  // Render the table component
  return (
    <Box>
      <TableContainer
        sx={{
          background: "transparent",
        }}
      >
        <DataTable>
          <TableHead>
            <TableRow
              className={clsx(styles.rowBox, styles.tableHead, styles.tableRow)}
            >
              {fields.map(({ sortable, value, id, flex = 1 }, index) => {
                return (
                  <TableCell
                    key={index}
                    className={styles.headerCell}
                    align="center"
                    width="100%"
                    sx={{ flex, borderBottomWidth: "0px !important" }}
                    sortDirection={orderby === id ? order : false}
                  >
                    {sortable ? (
                      <TableSortLabel
                        active={orderby === id}
                        direction={orderby === id ? order : "asc"}
                        onClick={() => {
                          createSortHandler(id);
                        }}
                      >
                        {value}
                      </TableSortLabel>
                    ) : (
                      <>{value}</>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          {(hookResult as any)?.isError ? (
            <Typography className={styles.error}>
              {(hookResult as any).error.data?.detail}
            </Typography>
          ) : (
            <TableBody>
              {orderedList.map((item: any, index: number) => (
                <TableRow
                  className={clsx(
                    styles.rowBox,
                    isLargeRow && styles.largeRow,
                    styles.tableRow
                  )}
                  key={index}
                  hover
                  sx={{ cursor: "pointer" }}
                  onDoubleClick={() => {
                    if (onDoubleClick) {
                      onDoubleClick(item);
                    }
                  }}
                >
                  {fields.map(
                    ({ id, type = "", flex = 1, field = "" }, index) => {
                      let value = item;
                      if (type === "auto") {
                        const keys = field.split(".");
                        for (const key of keys) {
                          value = value[key];
                        }
                      }
                      return (
                        <TableCell
                          key={index}
                          className={styles.cell}
                          align="center"
                          width="100%"
                          sx={{
                            flex,
                            display: "flex",
                            borderBottomWidth: "0px !important",
                          }}
                        >
                          {type === "badge" ? (
                            <Box
                              className={clsx(
                                styles.badge,
                                styles[item.is_active ? "active" : "inactive"]
                              )}
                            >
                              <Typography className={styles.cell}>
                                {item.is_active ? "Active" : "Inactive"}
                              </Typography>
                            </Box>
                          ) : type === "action" ? (
                            <ActionMenu
                              onChange={(value) => {
                                if (onChange) {
                                  onChange?.(value, item);
                                }
                              }}
                              actionItems={
                                isActivationAllowed
                                  ? [
                                      {
                                        label: "View",
                                        value: "view",
                                        icon: (
                                          <EyeIcon height={24} width={24} />
                                        ),
                                      },
                                      {
                                        label: "Edit",
                                        value: "edit",
                                        icon: (
                                          <EditIcon height={24} width={24} />
                                        ),
                                      },
                                      {
                                        label: "Logs",
                                        value: "logs",
                                        icon: (
                                          <Box
                                            component="img"
                                            src={Logs}
                                            width={24}
                                          />
                                        ),
                                      },
                                      {
                                        label: item.is_active
                                          ? "Deactivate"
                                          : "Activate",
                                        value: item.is_active
                                          ? "deactivate"
                                          : "activate",
                                        icon: item.is_active ? (
                                          <DeactivateIcon
                                            height={24}
                                            width={24}
                                          />
                                        ) : (
                                          <PowerSettingsNewIcon
                                            height={24}
                                            width={24}
                                          />
                                        ),
                                      },
                                    ]
                                  : item.is_active
                                  ? [
                                      {
                                        label: "View",
                                        value: "view",
                                        icon: (
                                          <EyeIcon height={24} width={24} />
                                        ),
                                      },
                                      {
                                        label: "Edit",
                                        value: "edit",
                                        icon: (
                                          <EditIcon height={24} width={24} />
                                        ),
                                      },
                                      {
                                        label: "Logs",
                                        value: "logs",
                                        icon: (
                                          <Box
                                            component="img"
                                            src={Logs}
                                            width={24}
                                          />
                                        ),
                                      },
                                      {
                                        label: "Deactivate",
                                        value: "deactivate",
                                        icon: (
                                          <DeactivateIcon
                                            height={24}
                                            width={24}
                                          />
                                        ),
                                      },
                                    ]
                                  : [
                                      {
                                        label: "View",
                                        value: "view",
                                        icon: (
                                          <EyeIcon height={24} width={24} />
                                        ),
                                      },
                                      {
                                        label: "Logs",
                                        value: "logs",
                                        icon: (
                                          <Box
                                            component="img"
                                            src={Logs}
                                            width={24}
                                          />
                                        ),
                                      },
                                    ]
                              }
                            />
                          ) : (
                            <Typography
                              className={styles.cell}
                              sx={{
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}
                            >
                              {type === "address"
                                ? `${item[id] ?? "-"} ${item["suburb"] ?? ""} ${
                                    item["state"] ?? ""
                                  } ${item["postcode"] ?? ""}`
                                : type === "user_name"
                                ? `${item["first_name"] ?? "-"} ${
                                    item["last_name"] ?? ""
                                  }`
                                : item[id] ?? ""}
                            </Typography>
                          )}

                          {/* Specimens ui cells */}
                          {type === "multiple_badges" && (
                            <Grid minWidth="240px" container>
                              {Object.keys(item?.test_status).map((key) => {
                                let color = getBadgeColor(
                                  item.test_status[key]
                                );
                                return (
                                  <Grid
                                    item
                                    xs={12}
                                    p="2px"
                                    display="flex"
                                    justifyContent="space-between"
                                    key={key}
                                  >
                                    <Typography className={styles.cell}>
                                      {key}
                                    </Typography>
                                    <Typography
                                      className={clsx(
                                        styles.badges,
                                        styles.badge,
                                        styles[color]
                                      )}
                                      sx={{ fontSize: "8px" }}
                                    >
                                      {item.test_status[key]}
                                    </Typography>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          )}
                          {type === "statusBadge" && (
                            <Box
                              className={clsx(
                                styles.badge,
                                styles.rectangleBadge,
                                styles[getBadgeColor(item.overall_status)]
                              )}
                            >
                              <Typography className={styles.cell}>
                                {item.overall_status}
                              </Typography>
                            </Box>
                          )}
                          {type === "auto" && (
                            <Box>
                              <Typography className={styles.cell}>
                                {value}
                              </Typography>
                            </Box>
                          )}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              ))}
            </TableBody>
          )}
        </DataTable>
        {paginationRequired && dataCount > 10 && (
          <TablePagination
            component="div"
            count={data?.count ?? 0}
            page={currentPage}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton
            showLastButton
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginLeft: "-20px",
            }}
          />
        )}
      </TableContainer>
    </Box>
  );
}

export default Table;
