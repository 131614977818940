import { useCallback, useState } from "react";
import { Box, Input as MuiInput, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { ReactComponent as SearchIcon } from "../../assets/svg/Search.svg";
import style from "./operating-theatre.module.css";
import { Button, LabelBox } from "../../components";
import { colors } from "../../theme/colors";
import { RootState, useAppSelector } from "../../store";
import { CustomTable, StatusBadge } from "../../components/DataTable";
import { ReactComponent as DeactivateIcon } from "../../assets/svg/deactivate.svg";
import { ReactComponent as EditIcon } from "../../assets/svg/edit.svg";
import { ReactComponent as EyeIcon } from "../../assets/svg/eye.svg";
import { CustomActionMenu } from "../../components/Menu";
import Logs from "../../assets/images/logs.png";
import {
  useGetOperatingTheatreListQuery,
  useUpdateOperatingTheatreStatusMutation,
} from "../../services/api";
import {
  ConfirmationDialog,
  DetailsDialog,
  InputDialog,
} from "../../components/Dialog";
import { Input } from "../../components/UserInput";
import { updateOperatingTheatreSchema } from "../../yup";

type FormState = typeof updateOperatingTheatreSchema.__outputType;

// Functional component representing the operating theatres page
const OperatingTheatres = () => {
  const { userProfileDetails } = useAppSelector(
    (state: RootState) => state.auth
  );
  const navigate = useNavigate();
  const [updateOperatingTheatreStatus] =
    useUpdateOperatingTheatreStatusMutation();

  const [message, setMessage] = useState<LabelMessageType | null>(null);
  const [selectedOperatingTheatre, setSelecteOperatingTheatre] =
    useState<OperatingTheatre | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [reasonModalVisible, setReasonModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [logModalVisible, setLogModalVisible] = useState(false);

  // all query parameters and pagination params
  const [queryParam, setQueryParam] = useState({
    search_key: "",
    limit: 10,
    offset: 0,
    hospital_id: userProfileDetails?.hospital?.id,
    order_by_columns: "created_at",
    order: "dsc",
    is_pagination_required: true,
  });

  const handleMenu = (value: string, operatingTheatre: OperatingTheatre) => {
    setSelecteOperatingTheatre(operatingTheatre);
    if (value === "deactivate") {
      setModalVisible(true);
    } else if (value === "activate") {
      setModalVisible(true);
    } else if (value === "view") {
      navigate(`/operating-theatres/details/${operatingTheatre?.id}`);
    } else if (value === "edit") {
      navigate(`/operating-theatres/edit/${operatingTheatre?.id}`);
    } else if (value === "logs") {
      setLogModalVisible(true);
    }
  };

  const fields = [
    {
      id: "name",
      label: "Theatre Location",
      flex: 2.5,
      sortable: true,
      type: "name",
      render: (item: OperatingTheatre) => (
        <Typography
          className={style.cell}
          sx={{
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {`${
            item?.name
              ? item?.name +
                (item?.room_number || item?.floor || item?.building ? ", " : "")
              : ""
          }${
            item?.room_number
              ? item?.room_number + (item?.floor ? ", " : "")
              : ""
          }${item?.floor ? item?.floor + (item?.building ? ", " : "") : ""} ${
            item?.building ?? ""
          }`}
        </Typography>
      ),
    },
    {
      id: "created_at",
      label: "Added",
      flex: 1,
      type: "date",
      sortable: true,
      render: (item: OperatingTheatre) => (
        <Typography
          className={style.cell}
          sx={{
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {`${dayjs(item["created_at"]).format("DD/MM/YYYY") ?? ""}`}
        </Typography>
      ),
    },
    {
      id: "is_active",
      label: "Status",
      type: "badge",
      flex: 0.8,
      sortable: true,
      render: (item: OperatingTheatre) => (
        <StatusBadge isActive={item?.is_active ?? false} />
      ),
    },
    {
      id: "logo_s3_key_name",
      label: "Actions",
      type: "action",
      render: (item: Surgeon) => (
        <CustomActionMenu
          item={item}
          isActive={item?.is_active ?? false}
          handleChange={handleMenu}
          actions={[
            {
              label: "View",
              value: "view",
              icon: <EyeIcon height={24} width={24} />,
            },
            {
              label: "Edit",
              value: "edit",
              icon: <EditIcon height={24} width={24} />,
            },
            {
              label: "Logs",
              value: "logs",
              icon: <img src={Logs} alt="Logs" width={24} />,
            },
            {
              label: item.is_active ? "Deactivate" : "Activate",
              value: item.is_active ? "deactivate" : "activate",
              icon: item.is_active ? (
                <DeactivateIcon height={24} width={24} />
              ) : (
                <PowerSettingsNewIcon height={24} width={24} />
              ),
            },
          ]}
        />
      ),
    },
  ];

  const { data: operatingTheatreData } =
    useGetOperatingTheatreListQuery(queryParam);

  // for searching in the query
  const handleSearch = useCallback(
    (e: any) => {
      setQueryParam((prevParams) => ({
        ...prevParams,
        search_key: e.target.value === "" ? undefined : e.target.value,
        offset: 0, // Reset offset when performing a new search
      }));
    },
    [setQueryParam]
  );

  // handle the page change
  const handlePageChange = useCallback(
    (e: any, newOffset: number) => {
      setQueryParam((prevParams) => ({
        ...prevParams,
        offset: prevParams.limit * newOffset,
      }));
    },
    [setQueryParam]
  );

  // handle the page size change
  const handleSizeChange = useCallback(
    (e: any) => {
      setQueryParam((prevParams) => ({
        ...prevParams,
        limit: e.target.value,
      }));
    },
    [setQueryParam]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<FormState>({
    resolver: yupResolver(updateOperatingTheatreSchema),
  });

  const onSubmit = async (values: FormState) => {
    try {
      setIsLoading(true);
      const { reason_to_deactivate } = values;
      await updateOperatingTheatreStatus({
        is_active: !selectedOperatingTheatre?.is_active,
        ids: [selectedOperatingTheatre?.id ?? 0],
        reason_to_deactivate,
      }).unwrap();
      setIsLoading(false);
      setReasonModalVisible(false);
      setValue("reason_to_deactivate", "");
      clearErrors();
    } catch (error: any) {
      setIsLoading(false);
      setMessage({
        type: "error",
        text: error?.message || error?.data?.detail || "Something went wrong!",
      });
    }
  };

  // Render the Operating Theatre component
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "15px",
        }}
      >
        <MuiInput
          disableUnderline
          placeholder="Search by name...."
          type="search"
          startAdornment={<SearchIcon width={20} height={20} />}
          value={queryParam.search_key}
          onChange={handleSearch}
          sx={{ width: "550px", gap: "8px", borderRadius: "50px" }}
        />
        <Link to="add">
          <Button
            variant="contained"
            className={style.addButton}
            label={"Add New Operating Theatre"}
            sx={{
              ":hover": { backgroundColor: colors.primary },
            }}
          />
        </Link>
      </Box>
      {message && (
        <Box mt={2}>
          <LabelBox
            boxProps={{ height: 50, display: "flex", alignItems: "center" }}
            data={message}
            onClose={() => setMessage(null)}
          />
        </Box>
      )}
      <Box style={{ maxHeight: "calc(100vh - 195px)", overflow: "auto" }}>
        <CustomTable<OperatingTheatre>
          fields={fields}
          paginationRequired={true}
          count={operatingTheatreData?.count}
          data={operatingTheatreData?.items || []}
          handlePageChange={handlePageChange}
          onSizeChange={handleSizeChange}
          pagination={queryParam}
          onDoubleClick={(operatingTheatre: OperatingTheatre) => {
            navigate(`/operating-theatres/details/${operatingTheatre?.id}`);
          }}
          defaultOrderBy={"name"}
          onOrderChange={() => {
            setQueryParam((prevParams) => ({
              ...prevParams,
              order: prevParams?.order === "asc" ? "dsc" : "asc",
            }));
          }}
          onOrderByChange={(column: string) => {
            setQueryParam((prevParams) => ({
              ...prevParams,
              order_by_columns: column,
            }));
          }}
        />
      </Box>
      {modalVisible && (
        <ConfirmationDialog
          visible={modalVisible}
          message={`Are you sure you want to ${
            selectedOperatingTheatre?.is_active ? "deactivate " : "activate "
          }"${selectedOperatingTheatre?.name?.toUpperCase() ?? ""}"?`}
          onClose={() => {
            setModalVisible(false);
          }}
          onConfirm={() => {
            setModalVisible(false);
            if (selectedOperatingTheatre?.is_active) {
              setReasonModalVisible(true);
            } else {
              onSubmit({ reason_to_deactivate: "" });
            }
          }}
        />
      )}
      {reasonModalVisible && (
        <InputDialog
          visible={reasonModalVisible}
          onClose={() => {
            setReasonModalVisible(false);
            setValue("reason_to_deactivate", "");
            clearErrors();
          }}
        >
          <Box
            component="form"
            sx={{ marginX: "20px" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Typography
              variant="h2"
              component="div"
              fontSize={16}
              fontWeight={700}
              color={colors.modalHeading}
              marginBottom={"10px"}
              sx={{ display: "flex", flexDirection: "row" }}
            >
              {"Enter the reason for deactivation"}
              <Typography
                color={"red"}
                fontSize={16}
                fontWeight={700}
                sx={{ marginLeft: "5px", marginTop: "-5px" }}
              >
                {"*"}
              </Typography>
            </Typography>
            <Input
              placeholder=""
              disableUnderline
              name="reason_to_deactivate"
              required
              register={register}
              multiline
              rows={3}
              errors={errors}
              sx={{
                width: "100%",
                margin: "1px",
                borderWidth: "1px",
              }}
            />
            <Box className={style.horizontalButtons} sx={{ paddingX: "50px" }}>
              <Button
                sx={{
                  width: "150px",
                  height: "35px",
                  backgroundColor: colors.white,
                  ":hover": { backgroundColor: colors.white },
                }}
                variant="outlined"
                onClick={() => {
                  setReasonModalVisible(false);
                  setValue("reason_to_deactivate", "");
                  clearErrors();
                }}
                label={"Cancel"}
              />
              <Button
                sx={{
                  width: "150px",
                  height: "35px",
                  ":hover": { backgroundColor: colors.primary },
                }}
                variant="contained"
                type="submit"
                label={"Submit"}
                loading={isLoading}
              />
            </Box>
          </Box>
        </InputDialog>
      )}
      {logModalVisible && (
        <DetailsDialog
          visible={logModalVisible}
          details={selectedOperatingTheatre}
          onClose={() => {
            setLogModalVisible(false);
          }}
          onConfirm={() => {
            setLogModalVisible(false);
          }}
        />
      )}
    </Box>
  );
};
export default OperatingTheatres;
