import { useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Grid, Input as MuiInput, Box, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { AutoCompleteSelectInput } from "../../components/SelectInput";
import Table from "../../components/DataTable/Table";
import { CustomDatePicker } from "../../components";

type SelectProp = {
  label: string;
  value: string;
};

const ViewSpecimens = () => {
  const navigate = useNavigate();

  // create for filtering purpose
  const [query] = useState("");
  const [status, setStatus] = useState<any>();
  const [dropOffTime, setDropOffTime] = useState<moment.Moment | null>(null);
  /*
   * for now we are using the static data for
   * the demonstration purpose only and
   */
  // make a sample field that can currently show the Speciments list
  const fields = [
    { id: "name", value: "Patient Name", flex: 1 },
    { id: "mrn", value: "MRN", type: "address", flex: 1 },
    {
      id: "drop_off_time",
      value: "Dropped off Time & Date",
      flex: 1.2,
    },
    {
      id: "test_status",
      value: "Test Status",
      type: "multiple_badges",
      flex: 2.5,
    },
    {
      id: "overall_status",
      value: "Overall Status",
      type: "statusBadge",
      flex: 1.6,
    },
    { id: "action", value: "Action", type: "action", flex: 1 },
  ];

  // current the data is on demonstrate purpose
  const data = {
    items: [
      {
        name: "Patient Name",
        mrn: "89003899",
        drop_off_time: "12-10-2015",
        test_status: {
          Histopathology: "Awaiting Pickup",
          MSC: "Awaiting Pickup (Discrepancy)",
          Disaccharidase: "Received by Lab",
        },
        overall_status: "Resolved",
        action: "Action",
      },
      {
        name: "Patient Name",
        mrn: "89003899",
        drop_off_time: "12-10-2015",
        test_status: {
          Histopathology: "Awaiting Pickup",
          MSC: "Awaiting Pickup (Discrepancy)",
          Disaccharidase: "Received by Lab(Discrepancy)",
        },
        overall_status: "No Action Required",
        action: "Action",
      },
      {
        name: "Patient Name",
        mrn: "89003899",
        drop_off_time: "12-10-2015",
        test_status: {
          Histopathology: "Awaiting Pickup",
          MSC: "Awaiting Pickup (Discrepancy)",
          Disaccharidase: "Received by Lab",
        },
        overall_status: "Action Required",
        action: "Action",
      },
      {
        name: "Patient Name",
        mrn: "89003899",
        drop_off_time: "12-10-2015",
        test_status: {
          Histopathology: "Awaiting Pickup",
          MSC: "Awaiting Pickup (Discrepancy)",
          Disaccharidase: "Received by Lab",
        },
        overall_status: "Action Required",
        action: "Action",
      },
    ],
    count: 21,
  };

  const handleStatus = (e: React.SyntheticEvent, data: SelectProp) => {
    setStatus(data);
  };

  const handleDropOff = (date: any) => {
    setDropOffTime(date);
  };

  const statusOptions = [
    { label: "Awaiting Pickup", value: "Awaiting Pickup" },
    {
      label: "Awaiting Pickup (Discrepancy)",
      value: "Awaiting Pickup (Discrepancy)",
    },
    { label: "Received by Lab", value: "Received by Lab" },
  ];

  return (
    <Box>
      {/* filter section in the specimens  */}
      <Grid spacing={2} container>
        <Grid item md={4}>
          <AutoCompleteSelectInput
            label="Status"
            value={status}
            onChange={handleStatus}
            options={statusOptions}
            renderInput={(params) => (
              <TextField {...params} label="Status" variant="outlined" />
            )}
          />
        </Grid>
        <Grid item xs={2.8}>
          <CustomDatePicker
            handleDate={handleDropOff}
            label="Dropoff Date"
            value={dropOffTime}
          />
        </Grid>
        <Grid item xs={5.2}>
          <MuiInput
            disableUnderline
            placeholder="Search for anything"
            type="search"
            startAdornment={<SearchIcon width={20} height={20} />}
            fullWidth
            sx={{ height: "55px" }}
          />
        </Grid>
      </Grid>
      <Table<Laboratory>
        fields={fields}
        isLargeRow={true}
        data={data}
        onDoubleClick={(ele: any) => {
          navigate(`/specimens/form/${ele?.mrn}`, {
            state: {
              label: "Specimens",
            },
          });
        }}
        filters={{ title: query }}
        onChange={() => ""}
        defaultOrderBy="name"
        paginationRequired={false}
      />
    </Box>
  );
};

export default ViewSpecimens;
