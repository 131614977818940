import React from "react";

import ActionMenu from "../DataTable/ActionMenu";
import { ReactComponent as DeactivateIcon } from "../../assets/svg/deactivate.svg";
import { ReactComponent as EditIcon } from "../../assets/svg/edit.svg";
import { ReactComponent as EyeIcon } from "../../assets/svg/eye.svg";
import Logs from "../../assets/images/logs.png";

interface DynamicActionMenuProps {
  item: any;
  isActive: boolean;
  handleChange: (value: string, item: any) => void;
  actions?: any[];
}

const CustomActionMenu: React.FC<DynamicActionMenuProps> = ({
  item,
  isActive,
  handleChange,
  actions,
}) => {
  const actionItems = actions
    ? actions
    : isActive
    ? [
        {
          label: "View",
          value: "view",
          icon: <EyeIcon height={24} width={24} />,
        },
        {
          label: "Edit",
          value: "edit",
          icon: <EditIcon height={24} width={24} />,
        },
        {
          label: "Logs",
          value: "logs",
          icon: <img src={Logs} alt="Logs" width={24} />,
        },
        {
          label: "Deactivate",
          value: "deactivate",
          icon: <DeactivateIcon height={24} width={24} />,
        },
      ]
    : [
        {
          label: "View",
          value: "view",
          icon: <EyeIcon height={24} width={24} />,
        },
        {
          label: "Logs",
          value: "logs",
          icon: <img src={Logs} alt="Logs" width={24} />,
        },
      ];

  return (
    <ActionMenu
      onChange={(value) => handleChange(value, item)}
      actionItems={actionItems}
    />
  );
};

export default CustomActionMenu;
