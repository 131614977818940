import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { RootState } from "../store";
import { appConfig } from "../hooks/useConfig";
import { API_URL } from "./constants";

// createApi function to configure an API slice using RTK Query
export const api = createApi({
  // Configuration for the API slice
  reducerPath: "api",

  // Configuration for the base query function using fetchBaseQuery
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig?.BASE_URL,
    prepareHeaders: async (headers, { getState }) => {
      // Get the access token from local storage
      const { authToken } = (getState() as RootState).auth;
      if (authToken) {
        // Set the 'Authorization' header with the access token for authenticated requests
        headers.set("authorization", `Bearer ${authToken}`);
      }
    },
  }),
  tagTypes: [
    "Hospitals",
    "HospitalDetails",
    "Laboratory",
    "LaboratoryDetails",
    "UserRoles",
    "UserGroups",
    "Services",
    "UserType",
    "UserRoleDetails",
    "Surgeons",
    "SurgeonDetails",
    "Specialities",
    "PatientSpecimenRecords",
    "PatientRequestForm",
    "PatientSpecimenRecordDetails",
    "Users",
    "UserDetails",
    "OperatingTheatres",
    "OperatingTheatreDetails",
  ],
  // Define API endpoints using the 'endpoints' property within the createApi configuration
  endpoints: (builder) => ({
    // 'login' endpoint configuration for handling user login
    login: builder.mutation<LoginResponse, LoginPayload>({
      query: (body) => ({
        url: API_URL.login,
        method: "POST",
        body,
      }),
    }),

    // 'two-factor-verification' endpoint configuration for handling two factor authentication
    twoFactorAuth: builder.mutation<TokenResponse, TwoFactorPayload>({
      query: (body) => ({
        url: API_URL.authentication,
        method: "POST",
        body,
      }),
    }),

    // 'forgot-password' endpoint configuration for handling forgot password
    forgotPassword: builder.mutation<{ message: string }, EmailType>({
      query: (body) => ({
        url: API_URL.forgotPassword,
        method: "POST",
        body,
      }),
    }),

    // 'reset-password' endpoint configuration for handling reset password
    resetPassword: builder.mutation<{ message: string }, ResetPasswordPayload>({
      query: (body) => ({
        url: API_URL.resetPassword,
        method: "POST",
        body,
      }),
    }),

    // 'change-password' endpoint configuration for handling change password
    changePassword: builder.mutation<
      { message: string },
      ChangePasswordPayload
    >({
      query: (body) => ({
        url: API_URL.changePassword,
        method: "POST",
        body,
      }),
    }),

    // 'refresh-token' endpoint configuration to get a new token from backend
    refreshToken: builder.mutation<TokenResponse, any>({
      query: (body) => ({
        url: API_URL.refreshToken,
        method: "POST",
        body,
      }),
    }),

    // 'logout' endpoint configuration for handling user logout
    logout: builder.mutation<any, any>({
      query: (body) => ({
        url: API_URL.logout,
        method: "POST",
        body,
      }),
    }),

    // 'get hospitals'
    getHospitals: builder.query<any, any>({
      query: (param: any) => ({
        url: `${API_URL.getHospitals}`,
        method: "GET",
        params: param,
      }),
      providesTags: (_) => ["Hospitals"],
    }),

    // create hospital
    addHospital: builder.mutation<any, AddHospitalPayload>({
      query: (body) => ({
        url: API_URL.hospitals,
        method: "POST",
        body,
      }),
      invalidatesTags: (_) => ["Hospitals"],
    }),

    //update hospital status
    updateHospitalStatus: builder.mutation<any, UpdateHospitalStatusPayload>({
      query: (body) => ({
        url: API_URL.updateHospitalStatus,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_) => ["Hospitals"],
    }),

    // get selected hospital details
    getHospitalDetails: builder.query<any, string>({
      query: (id = "") => ({
        url: `${API_URL.hospitals}/${id}`,
        method: "GET",
      }),
      providesTags: (_) => ["Hospitals", "HospitalDetails"],
    }),

    //get logged in user profile details
    getProfileDetails: builder.query<UserProfile, any>({
      query: () => ({
        url: API_URL.getProfileDetails,
        method: "GET",
      }),
    }),

    // get user list
    getUserList: builder.query<any, any>({
      query: (query = "") => ({
        url: `${API_URL.getUsers}${query}`,
        method: "GET",
      }),
      providesTags: (_) => ["Users"],
    }),

    //update hospital details
    updateHospitalDetails: builder.mutation<any, EditHospitalPayload>({
      query: (body) => ({
        url: `${API_URL.hospitals}/${body?.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_) => ["Hospitals", "HospitalDetails"],
    }),

    // 'get laboratories'
    getLaboratories: builder.query<any, any>({
      query: (query = "") => ({
        url: API_URL.laboratories,
        method: "GET",
        params: query,
      }),
      providesTags: (_) => ["Laboratory"],
    }),

    // create laboratory
    addLaboratory: builder.mutation<any, any>({
      query: (body) => ({
        url: API_URL.laboratories,
        method: "POST",
        body,
        formData: true,
      }),
      invalidatesTags: (_) => ["Laboratory"],
    }),

    //update laboratory details
    updateLaboratoryDetails: builder.mutation<any, any>({
      query: (body) => ({
        url: `${API_URL.laboratories}/${body.get("id")}`,
        method: "POST",
        body,
        formData: true,
      }),
      invalidatesTags: (_) => ["Laboratory", "LaboratoryDetails"],
    }),

    // get selected laboratory details
    getLaboratoryDetails: builder.query<any, string>({
      query: (id = "") => ({
        url: `${API_URL.laboratories}/${id}`,
        method: "GET",
      }),
      providesTags: (_) => ["LaboratoryDetails"],
    }),

    //update laboratory status
    updateLaboratoryStatus: builder.mutation<
      any,
      UpdateLaboratoryStatusPayload
    >({
      query: (body) => ({
        url: API_URL.updateLaboratoryStatus,
        method: "PUT",
        body,
      }),
    }),

    //get user roles
    getUserRoles: builder.query<any, any>({
      query: (query = "") => ({
        url: API_URL.userRoles,
        method: "GET",
        params: query,
      }),
      providesTags: (_) => ["UserRoles"],
    }),

    //get user group
    getUserGroup: builder.query<any, string>({
      query: (query = "") => ({
        url: `${API_URL.userGroups}${query}`,
        method: "GET",
      }),
      providesTags: (_) => ["UserGroups"],
    }),

    //get services
    getServices: builder.query<any, string>({
      query: (query = "") => ({
        url: `${API_URL.services}${query}`,
        method: "GET",
      }),
      providesTags: (_) => ["Services"],
    }),

    // create user role
    addUserRole: builder.mutation<any, AddUserRolePayload>({
      query: (body) => ({
        url: API_URL.userRole,
        method: "POST",
        body,
      }),
      invalidatesTags: (_) => ["UserRoles"],
    }),

    //get user type list
    getUserType: builder.query<any, any>({
      query: (query = "") => ({
        url: `${API_URL.userType}`,
        method: "GET",
        params: query,
      }),
      providesTags: (_) => ["UserType"],
    }),

    // get selected user role details
    getUserRoleDetails: builder.query<any, string>({
      query: (id = "") => ({
        url: `${API_URL.userRole}/${id}`,
        method: "GET",
      }),
      providesTags: (_) => ["UserRoleDetails"],
    }),

    //update user role status
    updateUserRoleStatus: builder.mutation<any, UpdateUserRoleStatusPayload>({
      query: (body) => ({
        url: API_URL.updateUserRoleStatus,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (_) => ["UserRoles", "UserRoleDetails"],
    }),

    //update user role details
    updateUserRoleDetails: builder.mutation<any, UpdateUserRolePayload>({
      query: (body) => ({
        url: `${API_URL.userRole}/${body?.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_) => ["UserRoles", "UserRoleDetails"],
    }),

    //create user admin role
    createUserAdmin: builder.mutation<any, CreateUserAdminPayload>({
      query: (body) => ({
        url: `${API_URL.createUserAdmin}`,
        method: "POST",
        body,
      }),
      invalidatesTags: (_) => ["UserGroups"],
    }),

    registerNurse: builder.mutation<any, any>({
      query: (body) => ({
        url: `${API_URL.registerNurse}`,
        method: "POST",
        body,
      }),
      invalidatesTags: (_) => ["Users"],
    }),

    // 'get surgeons'
    getSurgeons: builder.query<any, any>({
      query: (query = "") => ({
        url: `${API_URL.getSurgeons}`,
        method: "GET",
        params: query,
      }),
      providesTags: (_) => ["Surgeons"],
    }),

    // get selected surgeon details
    getSurgeonDetails: builder.query<Surgeon, string>({
      query: (id = "") => ({
        url: `${API_URL.surgeon}/${id}`,
        method: "GET",
      }),
      providesTags: (_) => ["SurgeonDetails"],
    }),

    //update surgeon status
    updateSurgeonStatus: builder.mutation<any, UpdateSurgeonStatusPayload>({
      query: (body) => ({
        url: API_URL.updateSurgeonStatus,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_) => ["Surgeons"],
    }),

    // get specialities
    getSpecialities: builder.query<any, string>({
      query: () => ({
        url: `${API_URL.getSpecialities}`,
        method: "GET",
      }),
      providesTags: (_) => ["Specialities"],
    }),

    // add surgeon
    addSurgeon: builder.mutation<any, any>({
      query: (body) => ({
        url: API_URL.addSurgeon,
        method: "POST",
        body,
      }),
      invalidatesTags: (_) => ["Surgeons"],
    }),

    // update surgeon
    updateSurgeon: builder.mutation<{ message: string }, EditSurgeonPayload>({
      query: (body) => ({
        url: `${API_URL.surgeon}/${body?.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_) => ["Surgeons", "SurgeonDetails"],
    }),

    // manage surgeon default address
    updateSurgeonDefaultAddress: builder.mutation<
      any,
      UpdateDeliveryAddressPayload
    >({
      query: (body) => ({
        url: API_URL.updateSurgeonDefaultAddress,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (_) => ["Surgeons", "SurgeonDetails"],
    }),

    // update associated surgeon address
    updateAssociatedSurgeonAddress: builder.mutation<
      any,
      DeliveryAddressPayload
    >({
      query: (body) => ({
        url: `${API_URL.updateAssociateSurgeonAddress}/${body?.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_) => ["SurgeonDetails"],
    }),

    // associate surgeon address
    associateSurgeonAddress: builder.mutation<Surgeon, DeliveryAddressPayload>({
      query: (body) => ({
        url: `${API_URL.associateSurgeonAddress}/${body?.doctor_id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_) => ["SurgeonDetails"],
    }),

    // get patient specimen records
    getPatientSpecimenRecords: builder.query<any, any>({
      query: (query = "") => ({
        url: `${API_URL.getPatientSpecimenRecords}`,
        method: "GET",
        params: query,
      }),
      providesTags: (_) => ["PatientSpecimenRecords"],
    }),

    // get patient request form
    getPatientRequestForm: builder.query<any, string>({
      query: (id = "") => ({
        url: `${API_URL.getPatientRequestForm}/${id}`,
        method: "GET",
      }),
      providesTags: (_) => ["PatientRequestForm"],
    }),

    // get patient specimen record details
    getPatientSpecimenRecordDetails: builder.query<PatientSpecimenRel, any>({
      query: (id = "") => ({
        url: `${API_URL.getPatientRequestFormDetails}/${id}`,
        method: "GET",
      }),
      providesTags: (_) => ["PatientSpecimenRecordDetails"],
    }),

    // get users list
    getUsersList: builder.query<any, any>({
      query: (query = "") => ({
        url: `${API_URL.getUsers}`,
        method: "GET",
        params: query,
      }),
      providesTags: (_) => ["Users"],
    }),

    // get user details
    getUserDetails: builder.query<Users, any>({
      query: (id = "") => ({
        url: `${API_URL.user}/${id}`,
        method: "GET",
      }),
      providesTags: (_) => ["UserDetails"],
    }),

    // update users status
    updateUsersStatus: builder.mutation<any, UpdateUserStatusPayload>({
      query: (body) => ({
        url: API_URL.updateUsersStatus,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_) => ["Users", "UserDetails"],
    }),

    // create new user
    addUser: builder.mutation<
      { message: string },
      AddUserPayload | AddHospitalAdminPayload
    >({
      query: (body) => ({
        url: API_URL.user,
        method: "POST",
        body,
      }),
      invalidatesTags: (_) => ["Users"],
    }),

    // update user
    updateUser: builder.mutation<
      { message: string },
      EditUserPayload | EditHospitalAdminPayload
    >({
      query: (body) => ({
        url: `${API_URL.user}/${body?.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_) => ["Users", "UserDetails"],
    }),

    // resend user invitation
    resendInvitation: builder.mutation<
      { message: string },
      ResendInvitationPayload
    >({
      query: (body) => ({
        url: `${API_URL.resendInvitation}/${body?.id}`,
        method: "POST",
      }),
      invalidatesTags: (_) => ["Users", "UserDetails"],
    }),

    // get operating theatres
    getOperatingTheatreList: builder.query<any, any>({
      query: (query = "") => ({
        url: `${API_URL.getOperatingTheatres}`,
        method: "GET",
        params: query,
      }),
      providesTags: (_) => ["OperatingTheatres"],
    }),

    // update operating theatre status
    updateOperatingTheatreStatus: builder.mutation<
      any,
      UpdateOperatingTheatrePayload
    >({
      query: (body) => ({
        url: API_URL.updateOperatingTheatreStatus,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_) => ["OperatingTheatres", "OperatingTheatreDetails"],
    }),

    // get selected operating theatre details
    getOperatingTheatreDetails: builder.query<OperatingTheatre, string>({
      query: (id = "") => ({
        url: `${API_URL.operatingTheatre}/${id}`,
        method: "GET",
      }),
      providesTags: (_) => ["OperatingTheatreDetails"],
    }),

    // create operating theatre
    addOperatingTheatre: builder.mutation<
      { message: string },
      AddOperatingTheatrePayload
    >({
      query: (body) => ({
        url: API_URL.addOperatingTheatre,
        method: "POST",
        body,
      }),
      invalidatesTags: (_) => ["OperatingTheatres"],
    }),

    // update operating theatre
    updateOperatingTheatre: builder.mutation<
      { message: string },
      EditOperatingTheatrePayload
    >({
      query: (body) => ({
        url: `${API_URL.operatingTheatre}/${body?.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_) => ["OperatingTheatres", "OperatingTheatreDetails"],
    }),
  }),
});

// Destructure individual mutation hooks from the 'api' object
export const {
  useLoginMutation,
  useTwoFactorAuthMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useRefreshTokenMutation,
  useLogoutMutation,
  useGetHospitalsQuery,
  useAddHospitalMutation,
  useUpdateHospitalStatusMutation,
  useGetHospitalDetailsQuery,
  useGetProfileDetailsQuery,
  useGetUserListQuery,
  useUpdateHospitalDetailsMutation,
  useGetLaboratoriesQuery,
  useAddLaboratoryMutation,
  useUpdateLaboratoryDetailsMutation,
  useGetLaboratoryDetailsQuery,
  useUpdateLaboratoryStatusMutation,
  useGetUserRolesQuery,
  useGetServicesQuery,
  useGetUserGroupQuery,
  useAddUserRoleMutation,
  useGetUserTypeQuery,
  useGetUserRoleDetailsQuery,
  useUpdateUserRoleStatusMutation,
  useUpdateUserRoleDetailsMutation,
  useCreateUserAdminMutation,
  useRegisterNurseMutation,
  useChangePasswordMutation,
  useLazyGetProfileDetailsQuery,
  useGetSurgeonsQuery,
  useGetSurgeonDetailsQuery,
  useUpdateSurgeonStatusMutation,
  useGetSpecialitiesQuery,
  useAddSurgeonMutation,
  useUpdateSurgeonDefaultAddressMutation,
  useGetPatientSpecimenRecordsQuery,
  useGetPatientRequestFormQuery,
  useGetPatientSpecimenRecordDetailsQuery,
  useGetUsersListQuery,
  useUpdateUsersStatusMutation,
  useGetOperatingTheatreListQuery,
  useUpdateOperatingTheatreStatusMutation,
  useGetOperatingTheatreDetailsQuery,
  useAddOperatingTheatreMutation,
  useUpdateOperatingTheatreMutation,
  useAddUserMutation,
  useGetUserDetailsQuery,
  useUpdateUserMutation,
  useUpdateAssociatedSurgeonAddressMutation,
  useAssociateSurgeonAddressMutation,
  useResendInvitationMutation,
  useUpdateSurgeonMutation,
} = api;
