import { Box, Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { colors } from "../../theme/colors";

type DialogProps = {
  visible: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
};

export default function InputDialog({
  visible,
  onClose,
  children,
}: DialogProps) {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: colors.modalBackground,
          maxWidth: "486px",
          width: "100%",
          border: `1px solid ${colors.black}`,
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              padding: "15px",
            }}
          >
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => handleClose()}
              onMouseDown={() => handleClose()}
              edge="end"
            >
              <CloseIcon sx={{ cursor: "pointer", height: 20, width: 20 }} />
            </IconButton>
          </Box>
          {children}
        </Box>
      </Box>
    </Dialog>
  );
}
