import { useState } from "react";
import { Box, FormControlLabel, Checkbox, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

// importing custom components and styles
import { login as loginAction } from "../../store/authReducer";
import { CustomInput } from "../../components/UserInput";
import { Message } from "../../components";
import { useAppDispatch } from "../../store";
import { loginSchema } from "../../yup";

// importing icons and css
import { ReactComponent as Pegasus } from "../../assets/svg/PegasusLogo.svg";
import { colors } from "../../theme/colors";
import loginStyle from "./login.module.css";

// importing services
import { useLoginMutation } from "../../services/api";

// Main Login component
function Login() {
  // Navigation hook to handle page navigation
  const navigate = useNavigate();

  // Login mutation hook
  const [login] = useLoginMutation();
  const dispatch = useAppDispatch();

  // for showing the message dialog
  const [message, setMessage] = useState<LabelMessageType | null>(null);

  // showing loading indicator
  const [isLoading, setIsLoading] = useState(false);

  /*
   * useForm hook from react-hook-form
   * Manages form state, validation, and submission logic.
   * - register: Registers input fields and gathers their values.
   * - handleSubmit: Triggers the provided callback on form submission.
   * - formState: Provides access to form state, such as errors
   * - yupResolver(loginSchema): Restrict the type of payload data for required, min length etc
   */
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginPayload>({
    resolver: yupResolver(loginSchema),
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  // Handle form submission
  const onSubmit = async (payload: LoginPayload) => {
    try {
      setIsLoading(true);
      const { token, user_role } = await login(payload).unwrap();
      setIsLoading(false);
      if (token && token.access && token.refresh) {
        const { access, refresh } = token;
        await dispatch(
          loginAction({
            authToken: access,
            refreshToken: refresh,
            userRole: user_role,
          })
        );

        navigate(user_role === "OT_ADMIN" ? "/specimens" : "/dashboard", {
          replace: true,
        });
      } else {
        navigate("/authentication", {
          state: { loginPayload: payload },
          replace: true,
        });
      }
    } catch (error: any) {
      const message =
        error?.message || error?.data?.detail || "Something went wrong!";
      // loading to false
      setIsLoading(false);

      // set the message to the error message
      setMessage({
        type: "error",
        text: message,
      });
    }
  };

  return (
    <Box
      sx={{
        display: "grid",
        placeItems: "center",
        height: "calc(100vh - 97px)",
      }}
    >
      <Box className={loginStyle.boxShadow}>
        <Box
          sx={{
            display: "grid",
            placeItems: "center",
            height: "100%",
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Pegasus />
          <Box className={loginStyle.inputs}>
            <Box>
              <CustomInput
                name="email"
                label="Email"
                type="text"
                focus={true}
                value={watch("email")}
                isLoading={false}
                isError={errors.email}
                register={register}
                showIcon={false}
              />
            </Box>
            <Box>
              <CustomInput
                name="password"
                label="Password"
                type="password"
                value={watch("password")}
                isError={errors.password}
                isLoading={isLoading}
                register={register}
                // icons props
                showIcon={true}
                icon="rightArrow"
                handleIconClick={handleSubmit(onSubmit)}
              />
            </Box>
            {message && <Message message={message} />}
          </Box>

          <Box className={loginStyle.checkBox}>
            <FormControlLabel
              sx={{ m: 0 }}
              control={<Checkbox />}
              label="Keep me signed in"
            />
            <Box
              onClick={() => navigate("/forgotpassword")}
              sx={{
                textDecoration: "none",
                color: colors.primary,
                cursor: "pointer",
                ":hover": {
                  fontWeight: "900",
                  textDecoration: "underline",
                },
              }}
            >
              <Typography variant="body1">Forgot password?</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default Login;
