import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";

//importing custom modules
import { SingleSelectInput } from "../../components/SelectInput";
import { Button } from "../../components";
import Input from "../../components/UserInput/Input";
import { Prefix } from "../../utils/constants";
import { hospitalAdminSchema } from "../../yup";

// importing styles
import style from "./admin.module.css";
import { colors } from "../../theme/colors";

// importing api
import { useAddUserMutation } from "../../services/api";
import { LabelBox } from "../../components";
import { RootState, useAppSelector } from "../../store";

type FormState = typeof hospitalAdminSchema.__outputType;

const AddAdmin = () => {
  const navigation = useNavigate();
  const [addUser] = useAddUserMutation();
  const { userProfileDetails } = useAppSelector(
    (state: RootState) => state.auth
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<LabelMessageType | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormState>({
    resolver: yupResolver(hospitalAdminSchema),
    mode: "onChange",
  });

  const onSubmit = async (values: FormState) => {
    try {
      setLoading(true);

      const payload: AddHospitalAdminPayload = {
        prefix: values.prefix,
        title: values.title ?? "",
        first_name: values.first_name,
        last_name: values.last_name,
        contact_number: values.contact_number ?? "",
        email: values.email,
        hospital_id: userProfileDetails?.hospital?.id ?? 0,
        user_role: "OT_ADMIN",
        email_user_creds: true,
        create_automatic_password: true,
        password_change_required_for_first_sign_in: true,
      };

      const result = await addUser(payload).unwrap();
      setLoading(false);
      toast(result?.message ?? "", {
        duration: 2000,
      });
      navigation(-1);
    } catch (error: any) {
      setMessage({
        type: "error",
        text: error?.data?.detail?.toString() || "Please enter valid details",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className={style.container}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Typography className={style.detailHeading}>Add New Admin</Typography>
        <Typography className={style.description}>
          Enter the below details to start adding a new admin to Pegasus
          Cybersoft.
        </Typography>
        <Box className={style.flexTop}>
          <Box className={style.row}>
            <SingleSelectInput
              placeholder="Select Prefix"
              label="Prefix"
              register={register}
              name="prefix"
              errors={errors}
              data={Prefix}
            />
            <Input
              placeholder="Title"
              label="Title"
              required
              register={register}
              name="title"
              errors={errors}
            />
          </Box>

          <Box className={style.row}>
            <Input
              placeholder="First Name"
              label="First Name"
              required
              register={register}
              name="first_name"
              errors={errors}
            />
            <Input
              placeholder="Last Name"
              label="Last Name"
              required
              register={register}
              name="last_name"
              errors={errors}
            />
          </Box>

          <Box className={style.row}>
            <Input
              placeholder="Mobile Number"
              label="Mobile Number"
              required
              register={register}
              name="contact_number"
              errors={errors}
            />
            <Input
              placeholder="Email Address"
              label="Email Address"
              required
              register={register}
              name="email"
              errors={errors}
            />
          </Box>
          {message && (
            <Box mt={2}>
              <LabelBox
                boxProps={{ height: 50, display: "flex", alignItems: "center" }}
                data={message}
                onClose={() => setMessage(null)}
              />
            </Box>
          )}
          <Box className={style.buttons}>
            <Button
              sx={{
                width: "200px",
                height: "55px",
              }}
              variant="outlined"
              onClick={() => navigation(-1)}
              label="Back"
            />
            <Button
              loading={loading}
              sx={{
                width: "200px",
                height: "55px",
                ":hover": { backgroundColor: colors.primary },
              }}
              variant="contained"
              type="submit"
              label="Save"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddAdmin;
