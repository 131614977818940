import { object, string, ref, boolean, array, number } from "yup";

// const phoneRegExp = /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/;

const nameRegExp = /[^0-9]+$/;
const providerNumberRegExp = /^[\w]+$/;
const suburbRegExp = /^[a-zA-Z ]+$/;
const stateRegExp = /^[a-zA-Z ]+$/;
const postCodeRegExp = /^[0-9]+$/;
// const alphaNumberRegExp = /^[a-zA-Z0-9]*$/;

// Validation schema for the login form
const loginSchema = object().shape({
  email: string().email("Email is invalid").required("Please enter email"),
  password: string().required("Please enter password"),
});

// Validation schema for the otp form
const otpSchema = object().shape({
  email: string().email("Email is invalid").required("Please enter email"),
  otp: string()
    .required("Please enter otp")
    .min(6, "Otp should be min 6 digit")
    .matches(/^[0-9-]+$/, "Please enter valid otp"),
});

// Validation schema for the forgot password form
const forgotPasswordSchema = object().shape({
  email: string().email("Email is invalid").required("Please enter email"),
});

// Validation schema for the reset password form
const resetPasswordSchema = object().shape({
  password: string().required("Please enter password"),
  confirmPassword: string()
    .required("Please enter confirm password")
    .oneOf([ref("password")], "Password must match"),
});

// Validation schema for the change password form
const changePasswordSchema = object().shape({
  oldPassword: string().required("Please enter old password"),
  password: string().required("Please enter password"),
  confirmPassword: string()
    .required("Please enter confirm password")
    .oneOf([ref("password")], "Password must match"),
});

// Validation schema for the add/edit hospital form
const hospitalSchema = object().shape(
  {
    name: string()
      .trim()
      .required("Hospital name is required")
      .max(100, "Hospital name cannot be longer than 100 characters")
      .matches(nameRegExp, "Invalid hospital name"),
    provider_number: string()
      .required("Provider number is required")
      .matches(providerNumberRegExp, "Invalid provider number")
      .test(
        "len",
        "Provider number should be of 8 characters",
        (val) => val.toString().length === 8
      ),
    street_address: string()
      .trim()
      .required("Street address is required")
      .max(100, "Street address cannot be longer than 100 characters"),
    suburb: string()
      .trim()
      .required("Suburb is required")
      .max(50, "Suburb cannot be longer than 50 characters")
      .matches(suburbRegExp, "Invalid suburb"),
    state: string()
      .trim()
      .required("State is required")
      .max(50, "State cannot be longer than 50 characters")
      .matches(stateRegExp, "Invalid suburb"),
    postcode: string()
      .required("Postcode is required")
      .max(4, "Postcode cannot be longer than 4 characters")
      .matches(postCodeRegExp, "Invalid postcode"),
    account_manager_email_address: string()
      .email("Email is invalid")
      .required("Email is required"),
    general_enquiries_contact_number: string()
      .required("Enquiry number is required")
      .min(10, "Enquiry number must be at least 10 characters")
      .max(10, "Enquiry number cannot be longer than 10 characters"),
    mob_operating_theatre_manager: string()
      .required("Manager contact is required")
      .min(10, "Manager number must be at least 10 characters")
      .max(10, "Manager number cannot be longer than 10 characters"),
    fax_number: string().when("fax_number", {
      is: (val: string | string[]) => val === "",
      then: () => string().notRequired().nullable(),
      otherwise: () =>
        string()
          .min(10, "Fax number must be at least 10 characters")
          .max(10, "Fax number cannot be longer than 10 characters"),
    }),
    delivery_email: string().notRequired().email("Email is invalid"),
    is_delivery_by_fax: boolean().notRequired().nullable(),
    is_delivery_by_mail: boolean().notRequired().nullable(),
  },
  [
    // Add Cyclic deps here when require itself
    ["fax_number", "fax_number"],
  ]
);

// Validation schema for the update hospital status form
const updateHospitalSchema = object().shape({
  reason_to_deactivate: string().required("Please enter reason"),
});

// Validation schema for the add/edit laboratory form
const laboratorySchema = object().shape(
  {
    name: string()
      .trim()
      .required("Laboratory name is required")
      .max(100, "Laboratory name cannot be longer than 100 characters")
      .matches(nameRegExp, "Invalid laboratory name"),
    street_address: string()
      .trim()
      .required("Street address is required")
      .max(100, "Street address cannot be longer than 100 characters"),
    suburb: string()
      .trim()
      .required("Suburb is required")
      .max(50, "Suburb cannot be longer than 50 characters")
      .matches(suburbRegExp, "Invalid suburb"),
    state: string()
      .trim()
      .required("State is required")
      .max(50, "State cannot be longer than 50 characters")
      .matches(stateRegExp, "Invalid suburb"),
    account_manager_email_address: string()
      .email("Email is invalid")
      .required("Email is required"),
    general_enquiries_contact_number: string()
      .required("Enquiry number is required")
      .min(10, "Enquiry number must be at least 10 characters")
      .max(10, "Enquiry number cannot be longer than 10 characters"),
    postcode: string()
      .required("Postcode is required")
      .max(4, "Postcode cannot be longer than 4 characters")
      .matches(postCodeRegExp, "Invalid postcode"),
    fax_number: string().when("fax_number", {
      is: (val: string | string[]) => val === "",
      then: () => string().notRequired().nullable(),
      otherwise: () =>
        string()
          .min(10, "Fax number must be at least 10 characters")
          .max(10, "Fax number cannot be longer than 10 characters"),
    }),
    health_link: string().when("health_link", {
      is: (val: string | string[]) => val === "",
      then: () => string().notRequired().nullable(),
      otherwise: () =>
        string()
          .min(5, "Health link must be at least 5 characters")
          .max(20, "Health link cannot be longer than 20 characters"),
    }),
    delivery_email: string().when("delivery_email", {
      is: (val: string | string[]) => val === "",
      then: () => string().notRequired().nullable(),
      otherwise: () => string().email("Email is invalid"),
    }),
    web_link: string().when("web_link", {
      is: (val: string | string[]) => val === "",
      then: () => string().notRequired().nullable(),
      otherwise: () =>
        string().min(5, "Web link must be at least 5 characters"),
    }),
    is_delivery_by_fax: boolean().notRequired().nullable(),
    is_delivery_by_health_link: boolean().notRequired().nullable(),
    is_delivery_mail: boolean().notRequired().nullable(),
    is_delivery_by_web_link: boolean().notRequired().nullable(),
  },
  [
    // Add Cyclic deps here when require itself
    ["fax_number", "fax_number"],
    ["health_link", "health_link"],
    ["web_link", "web_link"],
    ["delivery_email", "delivery_email"],
  ]
);

// Validation schema for the update laboratory status form
const updateLaboratorySchema = object().shape({
  reason_to_deactivate: string().required("Please enter reason"),
});

// Validation schema for the add/edit user form
const userSchema = object().shape(
  {
    first_name: string().trim().required("First Name is required"),
    last_name: string().trim().required("Last Name is required"),
    title: string().trim().required("Title is required"),
    email: string().email("Email is invalid").required("Email is required"),
    user_role: string().trim().required("User role is required"),
    contact_number: string()
      .required("Phone number is required")
      .min(10, "Phone number must be at least 10 characters")
      .max(10, "Phone number cannot be longer than 10 characters"),
    hospital_id: string().trim().required("Institution name is required"),
    health_link: string()
      .notRequired()
      .nullable()
      .min(5, "Health link must be at least 5 characters")
      .max(20, "Health link cannot be longer than 20 characters"),
    create_automatic_password: boolean().notRequired().nullable(),
    password_change_required_for_first_sign_in: boolean()
      .notRequired()
      .nullable(),
    email_user_creds: boolean().notRequired().nullable(),
    device_mob_number: string().when("user_role", {
      is: (val: string) =>
        val?.toLowerCase().includes("lab") ||
        val?.toLowerCase().includes("courier"),
      then: () =>
        string()
          .required("Mobile number is required")
          .min(10, "Mobile number must be at least 10 characters")
          .max(10, "Mobile number cannot be longer than 10 characters"),
      otherwise: () => string().notRequired(),
    }),
  },
  [
    // Add Cyclic deps here when require itself
    ["user_role", "user_role"],
  ]
);

// Validation schema for the add/edit user role form
const userRoleSchema = object().shape({
  role_name: string().trim().required("Role Name is required"),
  group: string().trim().required("Group is required"),
  services_id_list: array().notRequired().nullable(),
});

// Validation schema for the update user role status form
const updateUserRoleSchema = object().shape({
  reason_to_deactivate: string().required("Please enter reason"),
});

const nurseSchema = object().shape(
  {
    prefix: string(),
    emp_id: string(),
    first_name: string().trim().required("First name is required"),
    last_name: string().trim().required("Last name is required"),
    contact_number: string().when("contact_number", {
      is: (val: string | string[]) => val === "",
      then: () => string().notRequired().nullable(),
      otherwise: () =>
        string()
          .min(10, "Mobile number must be at least 10 characters")
          .max(10, "Mobile number cannot be longer than 10 characters"),
    }),
    email: string().email("Email is invalid").required("Please enter email"),
  },
  [
    // Add Cyclic deps here when require itself
    ["contact_number", "contact_number"],
  ]
);

const adminSchema = object().shape({
  prefix: string().trim().required("Prefix is required"),
  first_name: string().trim().required("First Name is required"),
  last_name: string().trim().required("Last Name is required"),
  title: string().trim().required("Title is required"),
  email: string().email("Email is invalid").required("Email is required"),
  user_role: string().trim().required("User role is required"),
  contact_number: string()
    .required("Phone number is required")
    .min(10, "Phone number must be at least 10 characters")
    .max(10, "Phone number cannot be longer than 10 characters"),
  password: string()
    .required("Please enter password")
    .min(12, "Password must be at least 12 characters"),
  create_password: boolean().notRequired().nullable(),
  change_password: boolean().notRequired().nullable(),
  email_info: boolean().notRequired().nullable(),
  password_change_required_for_first_sign_in: boolean()
    .notRequired()
    .nullable(),
  email_user_creds: boolean().notRequired().nullable(),
  create_automatic_password: boolean().notRequired().nullable(),
  hospital_id: string().notRequired().nullable(),
  laboratory_id: string().notRequired().nullable(),
});

// Validation schema for the add/edit surgeon form
const surgeonSchema = object().shape(
  {
    prefix: string().trim().required("Prefix is required"),
    speciality: string().trim().required("Speciality is required"),
    first_name: string()
      .trim()
      .required("First name is required")
      .matches(nameRegExp, "Invalid first name"),
    last_name: string()
      .trim()
      .required("Last name is required")
      .matches(nameRegExp, "Invalid last name"),
    street_address: string()
      .trim()
      .required("Street address is required")
      .max(100, "Street address cannot be longer than 100 characters"),
    additional_address_detail: string().trim(),
    suburb: string()
      .trim()
      .required("Suburb is required")
      .max(50, "Suburb cannot be longer than 50 characters")
      .matches(suburbRegExp, "Invalid suburb"),
    state: string()
      .trim()
      .required("State is required")
      .max(50, "State cannot be longer than 50 characters")
      .matches(stateRegExp, "Invalid suburb"),
    postcode: string()
      .required("Postcode is required")
      .max(4, "Postcode cannot be longer than 4 characters")
      .matches(postCodeRegExp, "Invalid postcode"),
    email: string().email("Email is invalid"),
    telephone: string().when("telephone", {
      is: (val: string | string[]) => val === "",
      then: () => string().notRequired().nullable(),
      otherwise: () =>
        string()
          .min(10, "Telephone number must be at least 10 characters")
          .max(10, "Telephone number cannot be longer than 10 characters"),
    }),
    fax_number: string().when("fax_number", {
      is: (val: string | string[]) => val === "",
      then: () => string().notRequired().nullable(),
      otherwise: () =>
        string()
          .min(10, "Fax number must be at least 10 characters")
          .max(10, "Fax number cannot be longer than 10 characters"),
    }),
    health_link: string().when("health_link", {
      is: (val: string | string[]) => val === "",
      then: () => string().notRequired().nullable(),
      otherwise: () =>
        string()
          .min(5, "Health link must be at least 5 characters")
          .max(20, "Health link cannot be longer than 20 characters"),
    }),
    provider_number: string().when("provider_number", {
      is: (val: string | string[]) => val === "",
      then: () => string().notRequired().nullable(),
      otherwise: () =>
        string()
          .matches(providerNumberRegExp, "Invalid provider number")
          .test(
            "len",
            "Provider number should be of 8 characters",
            (val) => val?.toString().length === 8
          ),
    }),
  },
  [
    // Add Cyclic deps here when require itself
    ["telephone", "telephone"],
    ["fax_number", "fax_number"],
    ["health_link", "health_link"],
    ["provider_number", "provider_number"],
  ]
);

// Validation schema for the select surgeon schema
const selectSurgeonSchema = object().shape({
  surgeon: number(),
});

// Validation schema for the update users status form
const updateUsersSchema = object().shape({
  reason_to_deactivate: string().required("Please enter reason"),
});

// Validation schema for the update operating theatre status form
const updateOperatingTheatreSchema = object().shape({
  reason_to_deactivate: string().required("Please enter reason"),
});

// Validation schema for add new operating theatre form
const operatingTheatreSchema = object().shape({
  name: string().required("Theatre name is required"),
  room_number: string().trim(),
  floor: string().trim(),
  building: string().trim(),
});

const hospitalAdminSchema = object().shape({
  prefix: string(),
  title: string(),
  first_name: string().trim().required("First name is required"),
  last_name: string().trim().required("Last name is required"),
  contact_number: string()
    .required("Phone number is required")
    .min(10, "Phone number must be at least 10 characters")
    .max(10, "Phone number cannot be longer than 10 characters"),
  email: string().email("Email is invalid").required("Please enter email"),
});

const addressSchema = object().shape(
  {
    street_address: string()
      .trim()
      .required("Street address is required")
      .max(100, "Street address cannot be longer than 100 characters"),
    additional_address_detail: string().trim(),
    suburb: string()
      .trim()
      .required("Suburb is required")
      .max(50, "Suburb cannot be longer than 50 characters")
      .matches(suburbRegExp, "Invalid suburb"),
    state: string()
      .trim()
      .required("State is required")
      .max(50, "State cannot be longer than 50 characters")
      .matches(stateRegExp, "Invalid suburb"),
    postcode: string()
      .required("Postcode is required")
      .max(4, "Postcode cannot be longer than 4 characters")
      .matches(postCodeRegExp, "Invalid postcode"),
    email: string().email("Email is invalid"),
    telephone: string().when("telephone", {
      is: (val: string | string[]) => val === "",
      then: () => string().notRequired().nullable(),
      otherwise: () =>
        string()
          .min(10, "Telephone number must be at least 10 characters")
          .max(10, "Telephone number cannot be longer than 10 characters"),
    }),
    fax_number: string().when("fax_number", {
      is: (val: string | string[]) => val === "",
      then: () => string().notRequired().nullable(),
      otherwise: () =>
        string()
          .min(10, "Fax number must be at least 10 characters")
          .max(10, "Fax number cannot be longer than 10 characters"),
    }),
    health_link: string().when("health_link", {
      is: (val: string | string[]) => val === "",
      then: () => string().notRequired().nullable(),
      otherwise: () =>
        string()
          .min(5, "Health link must be at least 5 characters")
          .max(20, "Health link cannot be longer than 20 characters"),
    }),
    provider_number: string().when("provider_number", {
      is: (val: string | string[]) => val === "",
      then: () => string().notRequired().nullable(),
      otherwise: () =>
        string()
          .matches(providerNumberRegExp, "Invalid provider number")
          .test(
            "len",
            "Provider number should be of 8 characters",
            (val) => val?.toString().length === 8
          ),
    }),
  },
  [
    // Add Cyclic deps here when require itself
    ["telephone", "telephone"],
    ["fax_number", "fax_number"],
    ["health_link", "health_link"],
    ["provider_number", "provider_number"],
  ]
);

// Export validation schemas for use in other parts of the application
export {
  loginSchema,
  otpSchema,
  forgotPasswordSchema,
  resetPasswordSchema,
  hospitalSchema,
  updateHospitalSchema,
  laboratorySchema,
  updateLaboratorySchema,
  userSchema,
  userRoleSchema,
  updateUserRoleSchema,
  nurseSchema,
  adminSchema,
  changePasswordSchema,
  surgeonSchema,
  updateUsersSchema,
  updateOperatingTheatreSchema,
  operatingTheatreSchema,
  hospitalAdminSchema,
  addressSchema,
  selectSurgeonSchema,
  providerNumberRegExp,
};
