import { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Chip,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

import { userRoleSchema } from "../../yup";
import style from "./user.module.css";
import { Button, LabelBox } from "../../components";
import { colors } from "../../theme/colors";
import { SingleSelectInput } from "../../components/SelectInput";
import {
  useGetUserGroupQuery,
  useGetServicesQuery,
  useGetUserTypeQuery,
  useGetUserRoleDetailsQuery,
  useUpdateUserRoleDetailsMutation,
} from "../../services/api";

type FormState = typeof userRoleSchema.__outputType;

function EditUserRole() {
  const [updateUserRole] = useUpdateUserRoleDetailsMutation();
  const navigation = useNavigate();
  const { id } = useParams();

  const [message, setMessage] = useState<LabelMessageType | null>(null);
  const [isUpdatingUserRole, setIsUpdatingUserRole] = useState(false);

  const { data: types = [] } = useGetUserTypeQuery("");

  const { data: groups = [] } = useGetUserGroupQuery("");

  const { data: services = [] } = useGetServicesQuery("");

  const {
    data: userRoleDetails = {},
    isLoading,
    isFetching,
  } = useGetUserRoleDetailsQuery(id ?? "");

  const userTypes = types.map((type: string, index: number) => {
    return {
      id: index,
      label: type,
      value: type,
    };
  });

  const userGroups = groups.map((group: string, index: number) => {
    return {
      id: index,
      label: group,
      value: group,
    };
  });

  const apiServices = services.map((service: Service) => {
    return {
      id: service?.id,
      label: service?.code_name,
      value: service?.code_name,
    };
  });

  /*
   * useForm hook from react-hook-form
   * Manages form state, validation, and submission logic.
   * - register: Registers input fields and gathers their values.
   * - handleSubmit: Triggers the provided callback on form submission.
   * - formState: Provides access to form state, such as errors
   * - yupResolver(userRoleSchema): Restrict the type of payload data for required, min length etc
   */
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    control,
    reset,
  } = useForm<FormState>({
    resolver: yupResolver(userRoleSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (
      !isEmpty(userRoleDetails) &&
      !isEmpty(userGroups) &&
      !isEmpty(userTypes) &&
      !isEmpty(apiServices)
    ) {
      reset({
        role_name: userRoleDetails?.name ?? "",
        group: userRoleDetails?.user_group ?? "",
        services_id_list:
          userRoleDetails?.services?.map((service: Service) => {
            return {
              id: service?.id,
              label: service?.code_name,
              value: service?.code_name,
            };
          }) ?? [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRoleDetails]);

  const onSubmit = async (values: FormState) => {
    try {
      setIsUpdatingUserRole(true);
      setMessage(null);
      const { role_name, group, services_id_list } = values;

      const payload: UpdateUserRolePayload = {
        id: userRoleDetails?.id,
        name: role_name,
        user_group: group,
        services_id_list: services_id_list?.map((service) => service.id) ?? [],
      };
      await updateUserRole(payload).unwrap();
      setIsUpdatingUserRole(false);
      navigation(-1);
    } catch (error: any) {
      setMessage({
        type: "error",
        text: error?.data?.detail?.toString() || "Please enter valid details",
      });
      setIsUpdatingUserRole(false);
    }
  };

  return (
    <Box className={style.container}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        {(isFetching || isLoading) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              width: "calc(100vw - 400px)",
              zIndex: 999,
              backgroundColor: "transparent",
              position: "absolute",
            }}
          >
            <CircularProgress size={40} />
          </Box>
        )}
        <Box>
          <Typography
            className={style.detailHeading2}
            sx={{ marginTop: "10px" }}
          >
            User Role Details
          </Typography>
          <Box className={style.flexTop}>
            <Box className={style.row}>
              <SingleSelectInput
                placeholder="Eg. Super Admin"
                label="Role Name"
                required
                register={register}
                name="role_name"
                data={userTypes ?? []}
                error={!!errors.group}
                errors={errors}
                value={getValues("role_name") ?? ""}
              />
            </Box>
            <Box className={style.row} sx={{ flexDirection: "column" }}>
              <SingleSelectInput
                placeholder="Eg. Hospital"
                label="Group"
                required
                register={register}
                name="group"
                data={userGroups ?? []}
                error={!!errors.group}
                errors={errors}
                value={getValues("group") ?? ""}
              />
            </Box>
            <Box className={style.row} sx={{ flexDirection: "column" }}>
              <Typography
                className={style.inputLabel}
                sx={{ marginBottom: -2, display: "flex" }}
              >
                Services
                <Typography sx={{ color: "red", marginLeft: 0.7 }}>
                  {" *"}
                </Typography>
              </Typography>

              <Controller
                control={control}
                name={"services_id_list"}
                rules={{ required: true }}
                render={({ field: { onChange }, ...props }) => (
                  <Autocomplete
                    {...props}
                    sx={{
                      width: "100%",
                    }}
                    multiple
                    disablePortal
                    disableCloseOnSelect
                    id="tags-outlined"
                    options={apiServices ?? []}
                    disableClearable={true}
                    defaultValue={
                      userRoleDetails?.services?.map((service: Service) => {
                        return {
                          id: service?.id,
                          label: service?.code_name,
                          value: service?.code_name,
                        };
                      }) ?? []
                    }
                    onChange={(data) => {
                      onChange(data);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    getOptionLabel={(option: any) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder={
                          isEmpty(getValues("services_id_list"))
                            ? "Select api services"
                            : ""
                        }
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                      />
                    )}
                    renderTags={(value: string[], getTagProps) =>
                      value.map((option: any, index: number) => (
                        <Chip
                          variant="outlined"
                          label={option.label}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderOption={(props, option: any, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    )}
                  />
                )}
              />
            </Box>
          </Box>
          {message && (
            <Box mt={2}>
              <LabelBox
                boxProps={{ height: 50, display: "flex", alignItems: "center" }}
                data={message}
                onClose={() => setMessage(null)}
              />
            </Box>
          )}
          <Box className={style.buttons} sx={{ marginBottom: 3 }}>
            <Button
              sx={{
                width: "200px",
                height: "55px",
                ":hover": { backgroundColor: "transparent" },
              }}
              variant="outlined"
              onClick={() => navigation(-1)}
              label={"Cancel"}
            />
            <Button
              sx={{
                width: "200px",
                height: "55px",
                ":hover": { backgroundColor: colors.primary },
              }}
              variant="contained"
              type="submit"
              label={"Save"}
              loading={isUpdatingUserRole}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default EditUserRole;
