import { Box, BoxProps, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import style from "./labelbox.module.css";
import { colors } from "../../theme/colors";

interface Props {
  data: LabelMessageType | null;
  onClose?: () => void;
  boxProps?: BoxProps;
}

function LabelBox(props: Props) {
  const { onClose, boxProps } = props;
  const { text, type } = props?.data ?? { type: "", text: "" };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Box className={style.labelBox} component="div" {...boxProps}>
      <Typography
        className={style.text}
        style={{ color: type === "success" ? colors.success : colors.error }}
        sx={{ fontWeight: 500 }}
      >
        {text}
      </Typography>
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => handleClose()}
        onMouseDown={() => handleClose()}
        edge="end"
      >
        <CloseIcon sx={{ cursor: "pointer", height: 20, width: 20 }} />
      </IconButton>
    </Box>
  );
}

export default LabelBox;
