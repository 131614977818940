import { useCallback, useState } from "react";
import { Box, Input as MuiInput, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

// importing custom components
import {
  ConfirmationDialog,
  DetailsDialog,
  InputDialog,
} from "../../components/Dialog";
import { CustomTable, StatusBadge } from "../../components/DataTable";
import { Button, LabelBox } from "../../components";
import { updateHospitalSchema } from "../../yup";
import Input from "../../components/UserInput/Input";

// css imports and images
import { ReactComponent as SearchIcon } from "../../assets/svg/Search.svg";
import style from "./hospital.module.css";
import { colors } from "../../theme/colors";

// importing the api services
import {
  useGetHospitalsQuery,
  useUpdateHospitalStatusMutation,
} from "../../services/api";
import { CustomActionMenu } from "../../components/Menu";

type FormState = typeof updateHospitalSchema.__outputType;

// Functional component representing the Hospital page
function Hospitals() {
  const [updateHospitalStatus] = useUpdateHospitalStatusMutation();
  const navigate = useNavigate();

  // all query parameters and pagination params
  const [queryParam, setQueryParam] = useState({
    name: "",
    limit: 10,
    offset: 0,
  });
  const [message, setMessage] = useState<LabelMessageType | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedHospital, setSelectedHospital] = useState<Hospital | null>(
    null
  );
  const [reasonModalVisible, setReasonModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [logModalVisible, setLogModalVisible] = useState(false);

  const { data: hospitalData } = useGetHospitalsQuery(queryParam);

  // for handling the hospital state
  const handleMenu = (value: string, item: Hospital) => {
    setSelectedHospital(item);
    if (value === "deactivate") {
      setModalVisible(true);
    } else if (value === "view") {
      navigate(`/hospitals/details/${item?.id}`);
    } else if (value === "edit") {
      navigate(`/hospitals/edit/${item?.id}`);
    } else if (value === "logs") {
      setLogModalVisible(true);
    }
  };

  // update the fields
  const fields = [
    { id: "name", label: "Hospital Name", flex: 2, sortable: true },
    {
      id: "street_address",
      label: "Address",
      type: "address",
      flex: 2.5,
      render: (item: Hospital) => (
        <Typography
          className={style.cell}
          sx={{
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {`${item["street_address"] ?? ""} ${item["suburb"] ?? ""} ${
            item["state"] ?? ""
          } ${item["postcode"] ?? ""}`}
        </Typography>
      ),
    },
    {
      id: "general_enquiries_contact_number",
      label: "Phone Number",
      type: "phone_number",
    },
    {
      id: "is_active",
      label: "Status",
      type: "badge",
      flex: 0.8,
      sortable: true,
      render: (item: Hospital) => <StatusBadge isActive={item.is_active} />,
    },
    {
      id: "logo_s3_key_name",
      label: "Actions",
      type: "action",
      render: (item: Hospital) => (
        <CustomActionMenu
          item={item}
          isActive={item.is_active}
          handleChange={handleMenu}
        />
      ),
    },
  ];

  // for searching in the query
  const handleSearch = useCallback(
    (e: any) => {
      setQueryParam((prevParams) => ({
        ...prevParams,
        name: e.target.value,
        offset: 0, // Reset offset when performing a new search
      }));
    },
    [setQueryParam]
  );

  // handle the page change
  const handlePageChange = useCallback(
    (e: any, newOffset: number) => {
      setQueryParam((prevParams) => ({
        ...prevParams,
        offset: prevParams.limit * newOffset,
      }));
    },
    [setQueryParam]
  );

  // handle the page size change
  const handleSizeChange = useCallback(
    (e: any) => {
      setQueryParam((prevParams) => ({
        ...prevParams,
        limit: e.target.value,
      }));
    },
    [setQueryParam]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<FormState>({
    resolver: yupResolver(updateHospitalSchema),
  });

  const onSubmit = async (values: FormState) => {
    try {
      setIsLoading(true);
      const { reason_to_deactivate } = values;
      await updateHospitalStatus({
        is_active: false,
        ids: [selectedHospital?.id ?? 0],
        reason_to_deactivate,
      }).unwrap();
      setIsLoading(false);
      setReasonModalVisible(false);
      setValue("reason_to_deactivate", "");
      clearErrors();
    } catch (error: any) {
      setIsLoading(false);
      setMessage({
        type: "error",
        text: error?.message || error?.data?.detail || "Something went wrong!",
      });
    }
  };

  // Render the Hospital component
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "15px",
        }}
      >
        <MuiInput
          disableUnderline
          placeholder="Search by name...."
          type="search"
          startAdornment={<SearchIcon width={20} height={20} />}
          value={queryParam.name}
          onChange={handleSearch}
          sx={{ width: "550px", gap: "8px", borderRadius: "50px" }}
        />
        <Link to="add">
          <Button
            variant="contained"
            className={style.addButton}
            label="Add New Hospital"
            sx={{
              ":hover": { backgroundColor: colors.primary },
            }}
          />
        </Link>
      </Box>
      {message && (
        <Box mt={2}>
          <LabelBox
            boxProps={{ height: 50, display: "flex", alignItems: "center" }}
            data={message}
            onClose={() => setMessage(null)}
          />
        </Box>
      )}
      <Box style={{ maxHeight: "calc(100vh - 195px)", overflow: "auto" }}>
        {/* custom table  */}
        {hospitalData && (
          <CustomTable<Hospital>
            fields={fields}
            paginationRequired={true}
            count={hospitalData?.count}
            data={hospitalData.items || []}
            onDoubleClick={(hospital: Hospital) => {
              navigate(`/hospitals/details/${hospital?.id}`);
            }}
            defaultOrderBy="name"
            handlePageChange={handlePageChange}
            onSizeChange={handleSizeChange}
            pagination={queryParam}
          />
        )}
      </Box>
      {modalVisible && (
        <ConfirmationDialog
          visible={modalVisible}
          message={`Are you sure you want to deactivate "${selectedHospital?.name}"?`}
          onClose={() => {
            setModalVisible(false);
          }}
          onConfirm={() => {
            setModalVisible(false);
            setReasonModalVisible(true);
          }}
        />
      )}
      {reasonModalVisible && (
        <InputDialog
          visible={reasonModalVisible}
          onClose={() => {
            setReasonModalVisible(false);
            setValue("reason_to_deactivate", "");
            clearErrors();
          }}
        >
          <Box
            component="form"
            sx={{ marginX: "20px" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Typography
              variant="h2"
              component="div"
              fontSize={16}
              fontWeight={700}
              color={colors.modalHeading}
              marginBottom={"10px"}
              sx={{ display: "flex", flexDirection: "row" }}
            >
              {"Enter the reason for deactivation"}
              <Typography
                color={"red"}
                fontSize={16}
                fontWeight={700}
                sx={{ marginLeft: "5px", marginTop: "-5px" }}
              >
                {"*"}
              </Typography>
            </Typography>
            <Input
              placeholder=""
              name="reason_to_deactivate"
              required
              register={register}
              multiline
              rows={3}
              errors={errors}
              sx={{
                width: "100%",
                margin: "1px",
                borderWidth: "1px",
              }}
            />
            <Box className={style.horizontalButtons} sx={{ paddingX: "50px" }}>
              <Button
                sx={{
                  width: "150px",
                  height: "35px",
                  backgroundColor: colors.white,
                  ":hover": { backgroundColor: colors.white },
                }}
                variant="outlined"
                onClick={() => {
                  setReasonModalVisible(false);
                  setValue("reason_to_deactivate", "");
                  clearErrors();
                }}
                label={"Cancel"}
              />
              <Button
                sx={{
                  width: "150px",
                  height: "35px",
                  ":hover": { backgroundColor: colors.primary },
                }}
                variant="contained"
                type="submit"
                label={"Submit"}
                loading={isLoading}
              />
            </Box>
          </Box>
        </InputDialog>
      )}
      {logModalVisible && (
        <DetailsDialog
          visible={logModalVisible}
          details={selectedHospital}
          onClose={() => {
            setLogModalVisible(false);
          }}
          onConfirm={() => {
            setLogModalVisible(false);
          }}
        />
      )}
    </Box>
  );
}
export default Hospitals;
