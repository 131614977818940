import { Box } from "@mui/material";

// Functional component representing the Home page
function Home() {
  return (
    <Box>
      <h1>this is Home component</h1>
    </Box>
  );
}
export default Home;
