import { Box } from "@mui/material";

// Functional component representing the About Us page
function AboutUs() {
  return (
    <Box>
      <h1>About us</h1>
    </Box>
  );
}
export default AboutUs;
