import { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { object, string } from "yup";

import {
  useUpdateSurgeonMutation,
  useGetSpecialitiesQuery,
  useUpdateSurgeonDefaultAddressMutation,
  useGetSurgeonDetailsQuery,
  useUpdateAssociatedSurgeonAddressMutation,
  useAssociateSurgeonAddressMutation,
} from "../../services/api";
import style from "./surgeon.module.css";
import { Button, LabelBox } from "../../components";
import { colors } from "../../theme/colors";
import { SingleSelectInput } from "../../components/SelectInput";
import { RootState, useAppSelector } from "../../store";
import AddressForm from "./components/AddressForm";
import AddressDetails from "./components/AddressDetails";
import { surgeonPrefix } from "../../utils/constants";
import { Input } from "../../components/UserInput";

const EditSurgeon = () => {
  const navigation = useNavigate();
  const { id } = useParams();
  const { userProfileDetails } = useAppSelector(
    (state: RootState) => state.auth
  );

  const [message, setMessage] = useState<LabelMessageType | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSurgeonDetails, setSelectedSurgeonDetails] =
    useState<Surgeon>();
  const [isAddNewAddress, setIsAddNewAddress] = useState(false);
  const [defaultAddressDetails, setDefaultAddressDetails] =
    useState<AddressDetails>();
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [isSavingAddressDetails, setIsSavingAddressDetails] = useState(false);

  const { data: surgeonDetails } = useGetSurgeonDetailsQuery(id ?? "");

  const [updateSurgeon] = useUpdateSurgeonMutation();

  const [updateAssociatedSurgeonAddress] =
    useUpdateAssociatedSurgeonAddressMutation();

  const [associateSurgeonAddress] = useAssociateSurgeonAddressMutation();

  const [updateSurgeonDefaultAddress] =
    useUpdateSurgeonDefaultAddressMutation();

  const { data: specialityData } = useGetSpecialitiesQuery("");

  const surgeonSpecialities = specialityData?.items?.map((speciality: any) => {
    return {
      id: speciality?.id,
      label: speciality?.name,
      value: speciality?.name,
    };
  });

  useEffect(() => {
    if (surgeonDetails) {
      setSelectedSurgeonDetails(surgeonDetails);
      setValue("prefix", surgeonDetails?.prefix ?? "");
      setValue("speciality_id", surgeonDetails?.speciality?.name ?? "");
      setValue("first_name", surgeonDetails?.first_name ?? "");
      setValue("last_name", surgeonDetails?.last_name ?? "");

      if (!isEmpty(surgeonDetails?.default_doctor_address_detail)) {
        const address = surgeonDetails?.doctor_delivery_addresses?.filter(
          (addr: AddressDetails) => {
            return (
              surgeonDetails?.default_doctor_address_detail?.[0]
                ?.doctor_delivery_address === addr?.id
            );
          }
        );
        if (address) {
          setDefaultAddressDetails(address[0]);
        }
      } else {
        if (
          surgeonDetails?.doctor_delivery_addresses &&
          surgeonDetails?.doctor_delivery_addresses?.length > 1
        ) {
          setDefaultAddressDetails(undefined);
        } else {
          setDefaultAddressDetails(
            surgeonDetails?.doctor_delivery_addresses?.[0]
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(surgeonDetails)]);

  const updateSurgeonSchema = object().shape(
    {
      prefix: string().when("$selectedSurgeonDetails", {
        is: (v: any) => v && !isEmpty(selectedSurgeonDetails),
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required("Prefix is required"),
      }),
      first_name: string().when("$selectedSurgeonDetails", {
        is: (v: any) => v && !isEmpty(selectedSurgeonDetails),
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required("First Name is required"),
      }),
      last_name: string().when("$selectedSurgeonDetails", {
        is: (v: any) => v && !isEmpty(selectedSurgeonDetails),
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required("Last Name is required"),
      }),
      speciality_id: string().when("$selectedSurgeonDetails", {
        is: (v: any) =>
          v &&
          !isEmpty(selectedSurgeonDetails) &&
          selectedSurgeonDetails?.speciality &&
          selectedSurgeonDetails?.speciality_id,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required("Speciality is required"),
      }),
    },
    [
      ["prefix", "prefix"],
      ["first_name", "first_name"],
      ["last_name", "last_name"],
      ["speciality_id", "speciality_id"],
    ]
  );

  type UpdateSurgeonFormState = typeof updateSurgeonSchema.__outputType;

  /*
   * useForm hook from react-hook-form
   * Manages form state, validation, and submission logic.
   * - register: Registers input fields and gathers their values.
   * - handleSubmit: Triggers the provided callback on form submission.
   * - formState: Provides access to form state, such as errors
   * - yupResolver(surgeonSchema): Restrict the type of payload data for required, min length etc
   */
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch: detailsWatch,
  } = useForm<UpdateSurgeonFormState>({
    resolver: yupResolver(updateSurgeonSchema),
    mode: "onChange",
    context: {
      selectedSurgeonDetails,
    },
  });

  const onSubmit = async (values: UpdateSurgeonFormState) => {
    try {
      setIsLoading(true);
      const selectedSpeciality = surgeonSpecialities?.find(
        (speciality: any) => speciality.value === values.speciality_id
      );
      let payload: EditSurgeonPayload = {
        id: Number(id ?? "0"),
        prefix: values.prefix ?? "",
        first_name: values.first_name ?? "",
        last_name: values.last_name ?? "",
        speciality_id: selectedSpeciality?.id,
        is_doctor_copy: false,
      };
      const response = await updateSurgeon(payload).unwrap();
      if (response) {
        setIsLoading(false);
        navigation(-1);
      }
    } catch (error: any) {
      setMessage({
        type: "error",
        text: error?.data?.detail?.toString() || "Please enter valid details",
      });
      setIsLoading(false);
    }
  };

  const saveUpdatedAddress = async (addressPayload: DeliveryAddressPayload) => {
    try {
      setIsSavingAddressDetails(true);
      await updateAssociatedSurgeonAddress(addressPayload);
      setTimeout(() => {
        setIsSavingAddressDetails(false);
      }, 1000);
    } catch (error: any) {
      setMessage({
        type: "error",
        text: error?.message?.toString() || "Please enter valid details",
      });
      setIsSavingAddressDetails(false);
    }
  };

  const updateDefaultAddress = async (
    addressPayload: UpdateDeliveryAddressPayload
  ) => {
    try {
      await updateSurgeonDefaultAddress(addressPayload);
      setIsSavingAddressDetails(false);
      setIsAddNewAddress(false);
      setIsEditAddress(false);
    } catch (error: any) {
      setMessage({
        type: "error",
        text:
          error?.message?.toString() ||
          "Something went wrong. Please try again later.",
      });
    }
  };

  const addNewAddress = async (addressPayload: DeliveryAddressPayload) => {
    try {
      setIsSavingAddressDetails(true);
      const response = await associateSurgeonAddress(addressPayload).unwrap();
      if (response) {
        const address =
          response?.doctor_delivery_addresses?.[
            response?.doctor_delivery_addresses?.length - 1
          ];
        const payload: UpdateDeliveryAddressPayload = {
          doctor_delivery_address_id: Number(address?.id ?? "0"),
          doctor_id: Number(selectedSurgeonDetails?.id),
          hospital_id: userProfileDetails?.hospital?.id ?? 0,
        };
        updateDefaultAddress(payload);
      }
    } catch (error: any) {
      setMessage({
        type: "error",
        text: error?.message?.toString() || "Please enter valid details",
      });
      setIsSavingAddressDetails(false);
    }
  };

  return (
    <Box className={style.container}>
      <Typography className={style.heading}>Edit Surgeon</Typography>
      <Box>
        <Box className={style.flexTop}>
          <Box>
            <Box style={{ marginTop: 10 }}>
              <Box className={style.flexTop}>
                <Box className={style.row}>
                  <SingleSelectInput
                    placeholder="Select Prefix"
                    label="Select Prefix"
                    required
                    register={register}
                    name="prefix"
                    errors={errors}
                    data={surgeonPrefix ?? []}
                    value={detailsWatch("prefix") ?? ""}
                    disabled={selectedSurgeonDetails ? true : false}
                  />
                  <SingleSelectInput
                    placeholder="Select Speciality"
                    label="Select Speciality"
                    required
                    register={register}
                    name="speciality_id"
                    errors={errors}
                    data={surgeonSpecialities ?? []}
                    value={detailsWatch("speciality_id") ?? ""}
                    disabled={
                      selectedSurgeonDetails &&
                      (selectedSurgeonDetails?.speciality ||
                        selectedSurgeonDetails?.speciality_id)
                        ? true
                        : false
                    }
                  />
                </Box>
                <Box className={style.row}>
                  <Input
                    placeholder="First Name"
                    label="First Name"
                    required
                    register={register}
                    name="first_name"
                    errors={errors}
                    disabled={selectedSurgeonDetails ? true : false}
                  />
                  <Input
                    placeholder="Last Name"
                    label="Last Name"
                    required
                    register={register}
                    name="last_name"
                    errors={errors}
                    disabled={selectedSurgeonDetails ? true : false}
                  />
                </Box>
              </Box>
              {selectedSurgeonDetails && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Typography
                    className={style.surgeonDetailHeading}
                    sx={{ fontSize: "20px !important" }}
                  >
                    Report Delivery Address Details
                  </Typography>
                  <Button
                    variant="contained"
                    label={"Add New Address"}
                    sx={{
                      ":hover": {
                        backgroundColor: colors.primary,
                      },
                    }}
                    onClick={() => {
                      setIsAddNewAddress(true);
                    }}
                  />
                </Box>
              )}
              {selectedSurgeonDetails?.doctor_delivery_addresses &&
                selectedSurgeonDetails?.doctor_delivery_addresses?.map(
                  (address, index) => {
                    const isDefault = address.id === defaultAddressDetails?.id;
                    return (
                      <Box key={index}>
                        <AddressDetails
                          address={address}
                          isDefault={isDefault}
                          isLoading={isSavingAddressDetails}
                          onSave={(deliveryAddress: DeliveryAddressPayload) => {
                            const payload: DeliveryAddressPayload = {
                              ...deliveryAddress,
                              id: Number(address.id ?? "0"),
                              doctor_id: selectedSurgeonDetails?.id,
                            };
                            saveUpdatedAddress(payload);
                          }}
                          onUpdateDefaultAddress={() => {
                            const payload: UpdateDeliveryAddressPayload = {
                              doctor_delivery_address_id: Number(
                                address.id ?? "0"
                              ),
                              doctor_id: selectedSurgeonDetails?.id,
                              hospital_id:
                                userProfileDetails?.hospital?.id ?? 0,
                            };
                            updateDefaultAddress(payload);
                            setIsEditAddress(false);
                            setIsAddNewAddress(false);
                          }}
                          onCancel={() => {
                            setIsEditAddress(false);
                            setIsAddNewAddress(false);
                          }}
                        />
                      </Box>
                    );
                  }
                )}
            </Box>
            {isAddNewAddress && (
              <Box sx={{ marginTop: "20px" }}>
                <AddressForm
                  onCancel={() => {
                    setIsAddNewAddress(false);
                  }}
                  onSave={(deliveryAddress: DeliveryAddressPayload) => {
                    const payload: DeliveryAddressPayload = {
                      ...deliveryAddress,
                      doctor_id: selectedSurgeonDetails?.id ?? 0,
                    };

                    addNewAddress(payload);
                  }}
                  isLoading={isSavingAddressDetails}
                />
              </Box>
            )}
          </Box>
        </Box>
        {message && (
          <Box mt={2}>
            <LabelBox
              boxProps={{ height: 50, display: "flex", alignItems: "center" }}
              data={message}
              onClose={() => setMessage(null)}
            />
          </Box>
        )}
        {!isEmpty(selectedSurgeonDetails) &&
          !isAddNewAddress &&
          !isEditAddress && (
            <Box className={style.buttons} sx={{ marginBottom: "15px" }}>
              <Button
                sx={{
                  width: "200px",
                  height: "55px",
                  ":hover": { backgroundColor: "transparent" },
                }}
                variant="outlined"
                onClick={() => navigation(-1)}
                label={"Back"}
              />
              <Button
                sx={{
                  width: "200px",
                  height: "55px",
                  ":hover": { backgroundColor: colors.primary },
                }}
                variant="contained"
                label={"Save"}
                loading={isLoading}
                onClick={handleSubmit(onSubmit)}
              />
            </Box>
          )}
      </Box>
    </Box>
  );
};

export default EditSurgeon;
