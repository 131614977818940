import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography, Checkbox, FormControlLabel } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { usePlacesWidget } from "react-google-autocomplete";
import get from "lodash/get";

import { laboratorySchema } from "../../yup";
import { Input, ImageInput } from "../../components/UserInput";
import { useAddLaboratoryMutation } from "../../services/api";
import style from "./laboratory.module.css";
import { Button, LabelBox } from "../../components";
import { appConfig } from "../../hooks/useConfig";
import { colors } from "../../theme/colors";
import { getFormData } from "../../utils/common";
import labLogo from "../../assets/images/lab-logo.jpg";
import bannerLogo from "../../assets/images/lab-banner.png";

type FormState = typeof laboratorySchema.__outputType;

function AddLaboratory() {
  const [addLaboratory] = useAddLaboratoryMutation();
  const navigation = useNavigate();

  const [message, setMessage] = useState<LabelMessageType | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [deliveryModeError, setDeliveryModeError] = useState(false);
  const [error, setError] = useState(false);
  const [bannerError, setBannerError] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [faxNumberSelected, setFaxNumberSelected] = useState(false);
  const [emailSelected, setEmailSelected] = useState(false);
  const [healthLinkSelected, setHealthLinkSelected] = useState(false);
  const [webAddressSelected, setWebAddressSelected] = useState(false);
  const [banner, setBanner] = useState();
  const [logo, setLogo] = useState();

  /*
   * useForm hook from react-hook-form
   * Manages form state, validation, and submission logic.
   * - register: Registers input fields and gathers their values.
   * - handleSubmit: Triggers the provided callback on form submission.
   * - formState: Provides access to form state, such as errors
   * - yupResolver(laboratorySchema): Restrict the type of payload data for required, min length etc
   */
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = useForm<FormState>({
    resolver: yupResolver(laboratorySchema),
    mode: "onChange",
  });

  const { ref: materialRef } = usePlacesWidget({
    apiKey: appConfig?.GOOGLE_API_KEY,
    inputAutocompleteValue: "off",
    options: {
      types: [
        "street_address",
        "street_number",
        "intersection",
        "route",
        "health",
      ],
      componentRestrictions: { country: "au" },
    },
    onPlaceSelected: (place) => {
      let suburb = "";
      let state = "";
      let postal = "";
      if (place) {
        clearErrors("postcode");
        clearErrors("suburb");
        clearErrors("state");
        const address_comp = place?.address_components;
        if (address_comp) {
          for (const c of address_comp) {
            const types: Array<string> = c.types;
            if (
              !suburb &&
              (types.includes("locality") ||
                types.includes("sublocality") ||
                types.includes("sublocality_level_1") ||
                types.includes("postal_town") ||
                types.includes("administrative_area_level_3"))
            ) {
              suburb = c.short_name;
            }

            if (types.includes("administrative_area_level_1")) {
              state = c.short_name;
            } else if (types.includes("postal_code")) {
              postal = c.short_name;
            }
          }
          const address_arr = place?.formatted_address?.split(", ");
          if (address_arr.length > 3) {
            setValue("street_address", address_arr.slice(0, 2).join(", "));
          } else {
            setValue("street_address", address_arr[0]);
          }
          setValue("state", state);
          setValue("suburb", suburb);
          setValue("postcode", postal);
          const lat: any = get(place, "geometry.location.lat", () => {});
          const lng: any = get(place, "geometry.location.lng", () => {});
          setLatitude(lat() ?? 0);
          setLongitude(lng() ?? 0);
        }
      }
    },
  });

  const onSubmit = async (values: FormState) => {
    try {
      const {
        street_address,
        fax_number,
        health_link,
        is_delivery_by_fax,
        is_delivery_by_health_link,
        general_enquiries_contact_number,
        is_delivery_by_web_link,
        is_delivery_mail,
        delivery_email,
        web_link,
        ...rest
      } = values;

      if (banner === undefined) {
        setBannerError(true);
        return;
      }
      if (
        delivery_email === "" &&
        fax_number === "" &&
        health_link === "" &&
        web_link === ""
      ) {
        setDeliveryModeError(true);
        return;
      }
      if (
        !emailSelected &&
        !faxNumberSelected &&
        !webAddressSelected &&
        !healthLinkSelected
      ) {
        setError(true);
        return;
      }
      setIsLoading(true);
      let payload: AddLaboratoryPayload = {
        ...rest,
        general_enquiries_contact_number:
          general_enquiries_contact_number?.replace(/ /g, ""),
        fax_number: fax_number?.replace(/ /g, "") as any,
        delivery_email,
        health_link,
        street_address,
        web_link,
        is_delivery_mail: emailSelected,
        is_delivery_by_web_link: webAddressSelected,
        is_delivery_by_fax: faxNumberSelected,
        is_delivery_by_health_link: healthLinkSelected,
        latitude,
        longitude,
        is_subscribed: true,
      };
      if (banner) {
        payload = { ...payload, banner: banner };
      }
      if (logo) {
        payload = { ...payload, logo: logo };
      }
      const formData = getFormData(payload);
      await addLaboratory(formData).unwrap();
      setIsLoading(false);
      navigation(-1);
    } catch (error: any) {
      setMessage({
        type: "error",
        text: error?.data?.detail?.toString() || "Please enter valid details",
      });
      setIsLoading(false);
    }
  };

  return (
    <Box className={style.container}>
      <Typography className={style.heading}>Add Laboratory</Typography>
      <Typography className={style.description}>
        Enter the below details to start adding a new laboratory to Pegasus
        Cybersoft.
      </Typography>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 1,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 360,
              left: 380,
              zIndex: 10,
            }}
          >
            <ImageInput
              label="Logo"
              register={register}
              name="logo"
              errors={errors}
              onImageSelected={(images) => {
                setLogo(images[0]);
              }}
              height={"100px"}
              width={"120px"}
              defaultValue={labLogo}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <ImageInput
              label="Banner"
              required
              register={register}
              name="banner"
              error={bannerError}
              onImageSelected={(images) => {
                setBannerError(false);
                setBanner(images[0]);
              }}
              height={"250px"}
              width={"100%"}
              defaultValue={bannerLogo}
            />
          </Box>
        </Box>
        <Box className={style.flexTop}>
          <Box className={style.row}>
            <Input
              placeholder="Enter name"
              label="Laboratory Name"
              required
              register={register}
              name="name"
              errors={errors}
            />
          </Box>
          <Box className={style.row}>
            <Input
              placeholder="Enter street address"
              label="Street Address"
              required
              register={register}
              name="street_address"
              errors={errors}
              inputRef={materialRef}
            />
            <Input
              placeholder="Enter suburb"
              label="Suburb"
              required
              register={register}
              name="suburb"
              errors={errors}
            />
          </Box>
          <Box className={style.row}>
            <Input
              placeholder="Enter state"
              label="State"
              required
              register={register}
              name="state"
              errors={errors}
            />
            <Input
              placeholder="Enter postcode"
              label="Postcode"
              required
              register={register}
              name="postcode"
              errors={errors}
            />
          </Box>
          <Typography className={style.detailHeading}>
            Contact Details
          </Typography>
          <Box className={style.flexBottom}>
            <Box className={style.row}>
              <Input
                placeholder="Enter phone number"
                label="General Inquiries"
                required
                register={register}
                name="general_enquiries_contact_number"
                errors={errors}
              />
              <Input
                placeholder="Enter accounts manager email address"
                label="Email Address"
                required
                register={register}
                name="account_manager_email_address"
                errors={errors}
              />
            </Box>
            <Typography className={style.detailHeading}>
              Request Form Delivery Details
            </Typography>

            <Box className={style.row}>
              <Input
                placeholder="Enter email address"
                label="Email"
                register={register}
                name="delivery_email"
                errors={errors}
                onFocus={() => {
                  setDeliveryModeError(false);
                }}
                onChange={(event) => {
                  setValue("delivery_email", event.target.value, {
                    shouldValidate: true,
                  });
                  if (event.target.value === "") {
                    setEmailSelected(false);
                  }
                }}
              />
              <Input
                placeholder="Enter fax number"
                label="Fax Number"
                register={register}
                name="fax_number"
                errors={errors}
                onFocus={() => {
                  setDeliveryModeError(false);
                }}
                onChange={(event) => {
                  setValue("fax_number", event.target.value, {
                    shouldValidate: true,
                  });
                  clearErrors("fax_number");
                  if (event.target.value === "") {
                    setFaxNumberSelected(false);
                  }
                }}
              />
            </Box>
            <Box className={style.row}>
              <Input
                placeholder="Enter health link"
                label="Health Link"
                register={register}
                name="health_link"
                errors={errors}
                onFocus={() => {
                  setDeliveryModeError(false);
                }}
                onChange={(event) => {
                  setValue("health_link", event.target.value, {
                    shouldValidate: true,
                  });
                  if (event.target.value === "") {
                    setHealthLinkSelected(false);
                  }
                }}
              />
              <Input
                placeholder="Enter web link"
                label="Web Address"
                register={register}
                name="web_link"
                errors={errors}
                onFocus={() => {
                  setDeliveryModeError(false);
                }}
                onChange={(event) => {
                  setValue("web_link", event.target.value, {
                    shouldValidate: true,
                  });
                  if (event.target.value === "") {
                    setWebAddressSelected(false);
                  }
                }}
              />
            </Box>
            {deliveryModeError && (
              <Typography sx={{ color: colors.red, fontSize: "12px" }}>
                * Please provide either Email or Fax Number or Health Link or
                Web Address..
              </Typography>
            )}
            <Typography className={style.detailHeading}>
              Mode of Delivery
            </Typography>
            <Box sx={{ marginLeft: 1 }}>
              <FormControlLabel
                sx={{ "& span": { py: 0, px: 0.3 } }}
                control={
                  <Checkbox
                    {...register("is_delivery_mail")}
                    sx={{ color: error ? colors.red : colors.black }}
                    onChange={() => {
                      setError(false);
                      setEmailSelected(!emailSelected);
                    }}
                    checked={emailSelected}
                  />
                }
                label="Email"
                disabled={!watch("delivery_email")}
              />
              <FormControlLabel
                sx={{ "& span": { py: 0, px: 0.3 } }}
                control={
                  <Checkbox
                    {...register("is_delivery_by_fax")}
                    sx={{ color: error ? colors.red : colors.black }}
                    onChange={() => {
                      setError(false);
                      setFaxNumberSelected(!faxNumberSelected);
                    }}
                    checked={faxNumberSelected}
                  />
                }
                label="Fax Number"
                disabled={!watch("fax_number")}
              />
              <FormControlLabel
                sx={{ "& span": { py: 0, px: 0.3 } }}
                control={
                  <Checkbox
                    {...register("is_delivery_by_health_link")}
                    sx={{ color: error ? colors.red : colors.black }}
                    onChange={() => {
                      setError(false);
                      setHealthLinkSelected(!healthLinkSelected);
                    }}
                    checked={healthLinkSelected}
                  />
                }
                label="Health Link"
                disabled={!watch("health_link")}
              />

              <FormControlLabel
                sx={{ "& span": { py: 0, px: 0.3 } }}
                control={
                  <Checkbox
                    {...register("is_delivery_by_web_link")}
                    sx={{ color: error ? colors.red : colors.black }}
                    onChange={() => {
                      setError(false);
                      setWebAddressSelected(!webAddressSelected);
                    }}
                    checked={webAddressSelected}
                  />
                }
                label="Web"
                disabled={!watch("web_link")}
              />
            </Box>
            {error && (
              <Typography sx={{ color: colors.red, fontSize: "12px" }}>
                * Please select atleast one mode of delivery
              </Typography>
            )}
          </Box>
        </Box>
        {message && (
          <Box mt={2}>
            <LabelBox
              boxProps={{ height: 50, display: "flex", alignItems: "center" }}
              data={message}
              onClose={() => setMessage(null)}
            />
          </Box>
        )}
        <Box className={style.buttons}>
          <Button
            sx={{
              width: "200px",
              height: "55px",
              ":hover": { backgroundColor: "transparent" },
            }}
            variant="outlined"
            onClick={() => navigation(-1)}
            label={"Back"}
          />
          <Button
            sx={{
              width: "200px",
              height: "55px",
              ":hover": { backgroundColor: colors.primary },
            }}
            variant="contained"
            type="submit"
            label={"Save"}
            loading={isLoading}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default AddLaboratory;
