import { useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import dayjs from "dayjs";

import style from "./surgeon.module.css";
import {
  useGetSurgeonDetailsQuery,
  useUpdateAssociatedSurgeonAddressMutation,
  useUpdateSurgeonDefaultAddressMutation,
  useAssociateSurgeonAddressMutation,
} from "../../services/api";
import { Button, LabelBox } from "../../components";
import { colors } from "../../theme/colors";
import AddressDetails from "./components/AddressDetails";
import { RootState, useAppSelector } from "../../store";
import AddressForm from "./components/AddressForm";

const SurgeonDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { userProfileDetails } = useAppSelector(
    (state: RootState) => state.auth
  );

  const [defaultAddressDetails, setDefaultAddressDetails] =
    useState<AddressDetails>();
  const [isAddNewAddress, setIsAddNewAddress] = useState(false);
  const [isSavingAddressDetails, setIsSavingAddressDetails] = useState(false);
  const [message, setMessage] = useState<LabelMessageType | null>(null);

  const { data: surgeonDetails, isLoading } = useGetSurgeonDetailsQuery(
    id ?? ""
  );

  const [updateAssociatedSurgeonAddress] =
    useUpdateAssociatedSurgeonAddressMutation();

  const [updateSurgeonDefaultAddress] =
    useUpdateSurgeonDefaultAddressMutation();

  const [associateSurgeonAddress] = useAssociateSurgeonAddressMutation();

  useEffect(() => {
    if (surgeonDetails) {
      if (!isEmpty(surgeonDetails?.default_doctor_address_detail)) {
        const address = surgeonDetails?.doctor_delivery_addresses?.filter(
          (addr: AddressDetails) => {
            return (
              surgeonDetails?.default_doctor_address_detail?.[0]
                ?.doctor_delivery_address === addr?.id
            );
          }
        );
        if (address) {
          setDefaultAddressDetails(address[0]);
        }
      } else {
        if (
          surgeonDetails?.doctor_delivery_addresses &&
          surgeonDetails?.doctor_delivery_addresses?.length > 1
        ) {
          setDefaultAddressDetails(undefined);
        } else {
          setDefaultAddressDetails(
            surgeonDetails?.doctor_delivery_addresses?.[0]
          );
        }
      }
    }
  }, [surgeonDetails]);

  const saveUpdatedAddress = async (addressPayload: DeliveryAddressPayload) => {
    try {
      setIsSavingAddressDetails(true);
      await updateAssociatedSurgeonAddress(addressPayload);
      setTimeout(() => {
        setIsSavingAddressDetails(false);
      }, 1000);
    } catch (error: any) {
      setMessage({
        type: "error",
        text: error?.message?.toString() || "Please enter valid details",
      });
      setIsSavingAddressDetails(false);
    }
  };

  const updateDefaultAddress = async (
    addressPayload: UpdateDeliveryAddressPayload
  ) => {
    try {
      await updateSurgeonDefaultAddress(addressPayload);
      setIsSavingAddressDetails(false);
      setIsAddNewAddress(false);
    } catch (error: any) {
      setMessage({
        type: "error",
        text:
          error?.message?.toString() ||
          "Something went wrong. Please try again later.",
      });
    }
  };

  const addNewAddress = async (addressPayload: DeliveryAddressPayload) => {
    try {
      setIsSavingAddressDetails(true);
      const response = await associateSurgeonAddress(addressPayload).unwrap();
      if (response) {
        const address =
          response?.doctor_delivery_addresses?.[
            response?.doctor_delivery_addresses?.length - 1
          ];
        const payload: UpdateDeliveryAddressPayload = {
          doctor_delivery_address_id: Number(address?.id ?? "0"),
          doctor_id: Number(surgeonDetails?.id),
          hospital_id: userProfileDetails?.hospital?.id ?? 0,
        };
        updateDefaultAddress(payload);
      }
    } catch (error: any) {
      setMessage({
        type: "error",
        text: error?.message?.toString() || "Please enter valid details",
      });
      setIsSavingAddressDetails(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "30px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          border: `1px solid ${colors.grey}`,
          borderRadius: "4px",
          height: "100%",
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "calc(100vh - 97px)",
            }}
          >
            <CircularProgress size={40} sx={{ marginLeft: "10px" }} />
          </Box>
        ) : (
          <Box sx={{ height: "100%", padding: "0px 20px" }}>
            <Typography className={style.surgeonDetailHeading}>
              Surgeon Details
            </Typography>
            <Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>Full Name</Typography>
                <Typography className={style.value}>
                  {`${surgeonDetails?.last_name.toUpperCase() ?? ""}, ${
                    surgeonDetails?.first_name ?? ""
                  } ${surgeonDetails?.prefix ?? ""}`}
                </Typography>
              </Box>
              <Box className={style.detailsRow}>
                <Typography className={style.title}>Speciality</Typography>
                <Typography className={style.value}>
                  {`${surgeonDetails?.speciality?.name ?? ""}`}
                </Typography>
              </Box>
              <Box sx={{ flexDirection: "column" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    className={style.surgeonDetailHeading}
                    sx={{ fontSize: "20px !important" }}
                  >
                    Report Delivery Address Details
                  </Typography>
                  <Button
                    variant="contained"
                    label={"Add New Address"}
                    sx={{
                      ":hover": {
                        backgroundColor: colors.primary,
                      },
                    }}
                    onClick={() => {
                      setIsAddNewAddress(true);
                    }}
                  />
                </Box>
                <Box style={{ marginTop: 10 }}>
                  {surgeonDetails?.doctor_delivery_addresses &&
                    surgeonDetails?.doctor_delivery_addresses?.map(
                      (address, index) => {
                        const isDefault =
                          address.id === defaultAddressDetails?.id;
                        return (
                          <Box key={index}>
                            <AddressDetails
                              address={address}
                              isDefault={isDefault}
                              isLoading={isSavingAddressDetails}
                              onSave={(
                                deliveryAddress: DeliveryAddressPayload
                              ) => {
                                const payload: DeliveryAddressPayload = {
                                  ...deliveryAddress,
                                  id: Number(address.id ?? "0"),
                                  doctor_id: surgeonDetails?.id,
                                };
                                saveUpdatedAddress(payload);
                              }}
                              onUpdateDefaultAddress={() => {
                                const payload: UpdateDeliveryAddressPayload = {
                                  doctor_delivery_address_id: Number(
                                    address.id ?? "0"
                                  ),
                                  doctor_id: surgeonDetails?.id,
                                  hospital_id:
                                    userProfileDetails?.hospital?.id ?? 0,
                                };
                                updateDefaultAddress(payload);
                              }}
                            />
                          </Box>
                        );
                      }
                    )}
                </Box>
                {isAddNewAddress && (
                  <Box sx={{ marginTop: "20px" }}>
                    <AddressForm
                      onCancel={() => {
                        setIsAddNewAddress(false);
                      }}
                      onSave={(deliveryAddress: DeliveryAddressPayload) => {
                        const payload: DeliveryAddressPayload = {
                          ...deliveryAddress,
                          doctor_id: surgeonDetails?.id ?? 0,
                        };
                        addNewAddress(payload);
                      }}
                      isLoading={isSavingAddressDetails}
                    />
                  </Box>
                )}
              </Box>
            </Box>

            {surgeonDetails?.reason_to_deactivate && (
              <Box>
                <Typography className={style.surgeonDetailHeading}>
                  Deactivation Details
                </Typography>
                <Box>
                  <Box className={style.detailsRow}>
                    <Typography className={style.title}>
                      Reason for deactivation
                    </Typography>
                    <Typography className={style.value}>
                      {surgeonDetails?.reason_to_deactivate}
                    </Typography>
                  </Box>
                  <Box className={style.detailsRow}>
                    <Typography className={style.title}>
                      Date of Deactivation
                    </Typography>
                    <Typography className={style.value}>
                      {surgeonDetails?.last_deactivated_at
                        ? dayjs(surgeonDetails?.last_deactivated_at).format(
                            "MMMM DD YYYY, h:mm:ss a"
                          )
                        : "-"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
            {message && (
              <Box mt={2}>
                <LabelBox
                  boxProps={{
                    height: 50,
                    display: "flex",
                    alignItems: "center",
                  }}
                  data={message}
                  onClose={() => setMessage(null)}
                />
              </Box>
            )}
            {!isAddNewAddress && (
              <Box className={style.buttons} sx={{ marginBottom: "20px" }}>
                <Button
                  sx={{
                    width: "200px",
                    height: "55px",
                    ":hover": { backgroundColor: "transparent" },
                  }}
                  variant="outlined"
                  onClick={() => navigate(-1)}
                  label={"Close"}
                />
                <Button
                  sx={{
                    width: "200px",
                    height: "55px",
                    ":hover": { backgroundColor: colors.primary },
                  }}
                  variant="contained"
                  label={"Edit"}
                  onClick={() =>
                    navigate(`/surgeons/edit/${surgeonDetails?.id}`)
                  }
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SurgeonDetails;
