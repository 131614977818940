const API_URL = {
  //auth
  login: "login",
  authentication: "two-factor-verification",
  forgotPassword: "forgot-password",
  resetPassword: "reset-password",
  refreshToken: "refresh-token",
  logout: "logout",
  changePassword: "change-password-for-first-time",

  //hospital
  getHospitals: "hospitals",
  hospitals: "hospital",
  updateHospitalStatus: "hospitals/update-status",

  //user
  getUsers: "users",
  getProfileDetails: "profile",
  updateUsersStatus: "users/update-status",
  user: "user",

  //laboratory
  laboratories: "laboratory",
  updateLaboratoryStatus: "laboratories/update-status",

  //user roles
  userRoles: "user-roles",
  userRole: "user-role",
  updateUserRoleStatus: "user-roles/update-status",

  //user group
  userGroups: "user-group-type",

  //services
  services: "api-services",

  //user type
  userType: "user-type",

  //create user admin / doctor / nurse
  createUserAdmin: "user",

  //register nurse
  registerNurse: "nurse-profile",

  //surgeons
  getSurgeons: "doctors",
  surgeon: "doctor",
  updateSurgeonStatus: "doctors/update-status",
  addSurgeon: "doctor-profile",
  updateSurgeonDefaultAddress: "update-default-doctor-address",
  updateAssociateSurgeonAddress: "update-associate-doctor-address",
  associateSurgeonAddress: "associate-doctor-address",
  resendInvitation: "resend-invitation",

  //specialities
  getSpecialities: "specialities?is_active=true&limit=100&offset=0",

  //patient specimen records
  getPatientSpecimenRecords: "patient-specimen-records",
  getPatientRequestForm: "request-form-file",
  getPatientRequestFormDetails: "patient-request-form",

  //operating theatre
  getOperatingTheatres: "specimen-room-list",
  updateOperatingTheatreStatus: "specimen-rooms/update-status",
  operatingTheatre: "specimen-room",
  addOperatingTheatre: "pathology-specimen-room",
};

export { API_URL };
