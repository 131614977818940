import { useState, useEffect, useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { object, string, number } from "yup";

import { providerNumberRegExp, selectSurgeonSchema } from "../../yup";
import {
  useAddSurgeonMutation,
  useGetSpecialitiesQuery,
  useGetSurgeonsQuery,
  useUpdateSurgeonDefaultAddressMutation,
  useGetSurgeonDetailsQuery,
  useUpdateAssociatedSurgeonAddressMutation,
  useAssociateSurgeonAddressMutation,
  useUpdateSurgeonMutation,
} from "../../services/api";
import style from "./surgeon.module.css";
import { Button, LabelBox } from "../../components";
import { colors } from "../../theme/colors";
import { SingleSelectInput } from "../../components/SelectInput";
import { RootState, useAppSelector } from "../../store";
import AddressForm from "./components/AddressForm";
import AddressDetails from "./components/AddressDetails";
import { surgeonPrefix } from "../../utils/constants";
import { Input } from "../../components/UserInput";

type SurgeonFormState = typeof selectSurgeonSchema.__outputType;

const AddSurgeon = () => {
  const navigation = useNavigate();
  const { userProfileDetails } = useAppSelector(
    (state: RootState) => state.auth
  );

  const [message, setMessage] = useState<LabelMessageType | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSurgeonDetails, setSelectedSurgeonDetails] =
    useState<Surgeon>();
  const [isAddButton, setIsAddButton] = useState(false);
  const [isAddNewAddress, setIsAddNewAddress] = useState(false);
  const [defaultAddressDetails, setDefaultAddressDetails] =
    useState<AddressDetails>();
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [isSavingAddressDetails, setIsSavingAddressDetails] = useState(false);

  const { data: surgeonDetails } = useGetSurgeonDetailsQuery(
    (selectedSurgeonDetails?.id ?? "").toString(),
    { skip: !selectedSurgeonDetails?.id }
  );

  const [addSurgeon] = useAddSurgeonMutation();
  const [updateSurgeon] = useUpdateSurgeonMutation();

  const [updateAssociatedSurgeonAddress] =
    useUpdateAssociatedSurgeonAddressMutation();

  const [associateSurgeonAddress] = useAssociateSurgeonAddressMutation();

  const [updateSurgeonDefaultAddress] =
    useUpdateSurgeonDefaultAddressMutation();

  const { data: surgeonData } = useGetSurgeonsQuery({
    is_include_all_doctor: true,
    order_by_columns: "last_name",
    order: "asc",
    doctor_id: undefined,
    is_pagination_required: false,
    is_for_drop_off: false,
    is_active: true,
    is_doctor_copy: false,
  });

  const { data: specialityData } = useGetSpecialitiesQuery("");

  const surgeonSpecialities = specialityData?.items?.map((speciality: any) => {
    return {
      id: speciality?.id,
      label: speciality?.name,
      value: speciality?.name,
    };
  });

  const allSurgeons = surgeonData?.items?.map((surgeon: any) => {
    return {
      id: surgeon?.id,
      label: `${surgeon["last_name"].toUpperCase() ?? ""}, ${
        surgeon["first_name"] ?? ""
      } ${surgeon["prefix"] ?? ""}`,
      value: `${surgeon["last_name"].toUpperCase() ?? ""}, ${
        surgeon["first_name"] ?? ""
      } ${surgeon["prefix"] ?? ""}`,
    };
  });

  useEffect(() => {
    if (surgeonDetails) {
      setSelectedSurgeonDetails(surgeonDetails);
      setValue("prefix", surgeonDetails?.prefix ?? "");
      setValue("speciality_id", surgeonDetails?.speciality?.name ?? "");
      setValue("first_name", surgeonDetails?.first_name ?? "");
      setValue("last_name", surgeonDetails?.last_name ?? "");

      if (!isEmpty(surgeonDetails?.default_doctor_address_detail)) {
        const address = surgeonDetails?.doctor_delivery_addresses?.filter(
          (addr: AddressDetails) => {
            return (
              surgeonDetails?.default_doctor_address_detail?.[0]
                ?.doctor_delivery_address === addr?.id
            );
          }
        );
        if (address) {
          setDefaultAddressDetails(address[0]);
        }
      } else {
        if (
          surgeonDetails?.doctor_delivery_addresses &&
          surgeonDetails?.doctor_delivery_addresses?.length > 1
        ) {
          setDefaultAddressDetails(undefined);
        } else {
          setDefaultAddressDetails(
            surgeonDetails?.doctor_delivery_addresses?.[0]
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(surgeonDetails)]);

  const addSurgeonSchema = object().shape(
    {
      prefix: string().when("$selectedSurgeonDetails", {
        is: (v: any) => v && !isEmpty(selectedSurgeonDetails),
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required("Prefix is required"),
      }),
      first_name: string().when("$selectedSurgeonDetails", {
        is: (v: any) => v && !isEmpty(selectedSurgeonDetails),
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required("First Name is required"),
      }),
      last_name: string().when("$selectedSurgeonDetails", {
        is: (v: any) => v && !isEmpty(selectedSurgeonDetails),
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required("Last Name is required"),
      }),
      speciality_id: string().when("$selectedSurgeonDetails", {
        is: (v: any) =>
          v &&
          !isEmpty(selectedSurgeonDetails) &&
          selectedSurgeonDetails?.speciality &&
          selectedSurgeonDetails?.speciality_id,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required("Speciality is required"),
      }),
      street_address: string().when(["$isAddButton"], {
        is: (v: any) => v && isAddButton,
        then: (schema) => schema.required("Street address is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      suburb: string().when("$isAddButton", {
        is: (v: any) => v && isAddButton,
        then: (schema) => schema.required("Suburb is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      state: string().when(["$isAddButton"], {
        is: (v: any) => v && isAddButton,
        then: (schema) => schema.required("State is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      postcode: string().when(["$isAddButton"], {
        is: (v: any) => v && isAddButton,
        then: (schema) => schema.required("Postcode is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      latitude: number(),
      longitude: number(),
      telephone: string().when("telephone", {
        is: (val: string | string[]) => val === "",
        then: () => string().notRequired().nullable(),
        otherwise: () =>
          string()
            .min(10, "Telephone number must be at least 10 characters")
            .max(10, "Telephone number cannot be longer than 10 characters"),
      }),
      fax_number: string().when("fax_number", {
        is: (val: string | string[]) => val === "",
        then: () => string().notRequired().nullable(),
        otherwise: () =>
          string()
            .min(10, "Fax number must be at least 10 characters")
            .max(10, "Fax number cannot be longer than 10 characters"),
      }),
      health_link: string().when("health_link", {
        is: (val: string | string[]) => val === "",
        then: () => string().notRequired().nullable(),
        otherwise: () =>
          string()
            .min(5, "Health link must be at least 5 characters")
            .max(20, "Health link cannot be longer than 20 characters"),
      }),
      provider_number: string().when("provider_number", {
        is: (val: string | string[]) => val === "",
        then: () => string().notRequired().nullable(),
        otherwise: () =>
          string()
            .matches(providerNumberRegExp, "Invalid provider number")
            .test(
              "len",
              "Provider number should be of 8 characters",
              (val) => val?.toString().length === 8
            ),
      }),
      email: string().email("Email is invalid"),
      additional_address_detail: string(),
    },
    [
      ["prefix", "prefix"],
      ["first_name", "first_name"],
      ["last_name", "last_name"],
      ["speciality_id", "speciality_id"],
      ["street_address", "street_address"],
      ["state", "state"],
      ["suburb", "suburb"],
      ["postcode", "postcode"],
      ["telephone", "telephone"],
      ["fax_number", "fax_number"],
      ["health_link", "health_link"],
      ["provider_number", "provider_number"],
    ]
  );

  type AddSurgeonFormState = typeof addSurgeonSchema.__outputType;

  /*
   * useForm hook from react-hook-form
   * Manages form state, validation, and submission logic.
   * - register: Registers input fields and gathers their values.
   * - handleSubmit: Triggers the provided callback on form submission.
   * - formState: Provides access to form state, such as errors
   * - yupResolver(surgeonSchema): Restrict the type of payload data for required, min length etc
   */
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch: detailsWatch,
    clearErrors,
  } = useForm<AddSurgeonFormState>({
    resolver: yupResolver(addSurgeonSchema),
    mode: "onChange",
    context: {
      selectedSurgeonDetails,
      isAddButton,
    },
  });

  const { register: surgeonRegister, watch } = useForm<SurgeonFormState>({
    resolver: yupResolver(selectSurgeonSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (watch("surgeon")) {
      const filteredSurgeonID = allSurgeons?.filter((surgeon: any) => {
        return surgeon?.label === watch("surgeon");
      });
      const filteredSurgeonDetails = surgeonData?.items?.filter(
        (surgeon: any) => {
          return surgeon?.id === filteredSurgeonID?.[0]?.id;
        }
      );
      setSelectedSurgeonDetails(filteredSurgeonDetails[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("surgeon")]);

  const onSubmit = async (values: AddSurgeonFormState) => {
    try {
      if (selectedSurgeonDetails) {
        const selectedSpeciality = surgeonSpecialities?.find(
          (speciality: any) => speciality.value === values.speciality_id
        );
        let payload: EditSurgeonPayload = {
          id: Number(selectedSurgeonDetails?.id ?? "0"),
          prefix: values.prefix ?? "",
          first_name: values.first_name ?? "",
          last_name: values.last_name ?? "",
          speciality_id: selectedSpeciality?.id,
          is_doctor_copy: false,
        };
        const response = await updateSurgeon(payload).unwrap();
        if (response) {
          setIsLoading(false);
          navigation(-1);
        }
      } else {
        setIsLoading(true);
        const selectedSpeciality = surgeonSpecialities?.find(
          (speciality: any) => speciality.value === values.speciality_id
        );
        let payload: AddSurgeonPayload = {
          prefix: values.prefix ?? "",
          first_name: values.first_name ?? "",
          last_name: values.last_name ?? "",
          speciality_id: selectedSpeciality?.id,
          is_doctor_copy: false,
          addresses: [
            {
              street_address: values.street_address,
              suburb: values.suburb,
              state: values.state,
              postcode: values.postcode,
              latitude: values.latitude,
              longitude: values.longitude,
              email: values.email,
              telephone: values.telephone,
              health_link: values.health_link,
              provider_number: values.provider_number,
              fax_number: values.fax_number,
              additional_address_detail: values?.additional_address_detail,
            },
          ],
        };
        const response = await addSurgeon(payload).unwrap();
        if (response) {
          await manageDefaultAddress(
            response.id,
            response?.doctor_delivery_addresses?.[0]?.id
          );
        } else {
          setIsLoading(false);
        }
      }
    } catch (error: any) {
      setMessage({
        type: "error",
        text: error?.data?.detail?.toString() || "Please enter valid details",
      });
      setIsLoading(false);
    }
  };

  const manageDefaultAddress = useCallback(
    async (surgeonId: string | number, addressId: string | number) => {
      try {
        const payload: UpdateDeliveryAddressPayload = {
          doctor_id: Number(surgeonId),
          doctor_delivery_address_id: Number(addressId ?? 0),
          hospital_id: userProfileDetails?.hospital?.id ?? 0,
        };
        const response = await updateSurgeonDefaultAddress(payload);
        setIsLoading(false);
        if (response) {
          navigation(-1);
        }
      } catch (error) {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userProfileDetails]
  );

  const saveUpdatedAddress = async (addressPayload: DeliveryAddressPayload) => {
    try {
      setIsSavingAddressDetails(true);
      await updateAssociatedSurgeonAddress(addressPayload);
      setTimeout(() => {
        setIsSavingAddressDetails(false);
      }, 1000);
    } catch (error: any) {
      setMessage({
        type: "error",
        text: error?.message?.toString() || "Please enter valid details",
      });
      setIsSavingAddressDetails(false);
    }
  };

  const updateDefaultAddress = async (
    addressPayload: UpdateDeliveryAddressPayload
  ) => {
    try {
      await updateSurgeonDefaultAddress(addressPayload);
      setIsSavingAddressDetails(false);
      setIsAddNewAddress(false);
      setIsEditAddress(false);
    } catch (error: any) {
      setMessage({
        type: "error",
        text:
          error?.message?.toString() ||
          "Something went wrong. Please try again later.",
      });
    }
  };

  const addNewAddress = async (addressPayload: DeliveryAddressPayload) => {
    try {
      setIsSavingAddressDetails(true);
      const response = await associateSurgeonAddress(addressPayload).unwrap();
      if (response) {
        const address =
          response?.doctor_delivery_addresses?.[
            response?.doctor_delivery_addresses?.length - 1
          ];
        const payload: UpdateDeliveryAddressPayload = {
          doctor_delivery_address_id: Number(address?.id ?? "0"),
          doctor_id: Number(selectedSurgeonDetails?.id),
          hospital_id: userProfileDetails?.hospital?.id ?? 0,
        };
        updateDefaultAddress(payload);
      }
    } catch (error: any) {
      setMessage({
        type: "error",
        text: error?.message?.toString() || "Please enter valid details",
      });
      setIsSavingAddressDetails(false);
    }
  };

  return (
    <Box className={style.container}>
      <Typography className={style.heading}>Add Surgeon</Typography>
      <Typography className={style.description}>
        Enter the below details to start adding a new surgeon to Pegasus
        Cybersoft.
      </Typography>
      <Box>
        <Box className={style.flexTop}>
          {isEmpty(selectedSurgeonDetails) && !isAddButton ? (
            <Box>
              <Box className={style.row}>
                <SingleSelectInput
                  placeholder="Select Surgeon"
                  label="Select Surgeon"
                  required
                  register={surgeonRegister}
                  name="surgeon"
                  errors={errors}
                  data={allSurgeons ?? []}
                />
              </Box>
              <Box sx={{ marginTop: 3, marginBottom: 3 }}>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  Or
                </Typography>
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsAddButton(true);
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: "18px",
                      textDecoration: "underline",
                      fontWeight: "600",
                      marginTop: 2,
                    }}
                  >
                    Can't find the surgeon? Add Manually
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box>
              <Box style={{ marginTop: 10 }}>
                <Box className={style.flexTop}>
                  <Box className={style.row}>
                    <SingleSelectInput
                      placeholder="Select Prefix"
                      label="Select Prefix"
                      required
                      register={register}
                      name="prefix"
                      errors={errors}
                      data={surgeonPrefix ?? []}
                      value={detailsWatch("prefix") ?? ""}
                      disabled={selectedSurgeonDetails ? true : false}
                    />
                    <SingleSelectInput
                      placeholder="Select Speciality"
                      label="Select Speciality"
                      required
                      register={register}
                      name="speciality_id"
                      errors={errors}
                      data={surgeonSpecialities ?? []}
                      value={detailsWatch("speciality_id") ?? ""}
                      disabled={
                        selectedSurgeonDetails &&
                        (selectedSurgeonDetails?.speciality ||
                          selectedSurgeonDetails?.speciality_id)
                          ? true
                          : false
                      }
                    />
                  </Box>
                  <Box className={style.row}>
                    <Input
                      placeholder="First Name"
                      label="First Name"
                      required
                      register={register}
                      name="first_name"
                      errors={errors}
                      disabled={selectedSurgeonDetails ? true : false}
                    />
                    <Input
                      placeholder="Last Name"
                      label="Last Name"
                      required
                      register={register}
                      name="last_name"
                      errors={errors}
                      disabled={selectedSurgeonDetails ? true : false}
                    />
                  </Box>
                </Box>
                {selectedSurgeonDetails && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      className={style.surgeonDetailHeading}
                      sx={{ fontSize: "20px !important" }}
                    >
                      Report Delivery Address Details
                    </Typography>
                    <Button
                      variant="contained"
                      label={"Add New Address"}
                      sx={{
                        ":hover": {
                          backgroundColor: colors.primary,
                        },
                      }}
                      onClick={() => {
                        setIsAddNewAddress(true);
                      }}
                    />
                  </Box>
                )}
                {selectedSurgeonDetails?.doctor_delivery_addresses &&
                  selectedSurgeonDetails?.doctor_delivery_addresses?.map(
                    (address, index) => {
                      const isDefault =
                        address.id === defaultAddressDetails?.id;
                      return (
                        <Box key={index}>
                          <AddressDetails
                            address={address}
                            isDefault={isDefault}
                            isLoading={isSavingAddressDetails}
                            onSave={(
                              deliveryAddress: DeliveryAddressPayload
                            ) => {
                              const payload: DeliveryAddressPayload = {
                                ...deliveryAddress,
                                id: Number(address.id ?? "0"),
                                doctor_id: selectedSurgeonDetails?.id,
                              };
                              saveUpdatedAddress(payload);
                            }}
                            onUpdateDefaultAddress={() => {
                              const payload: UpdateDeliveryAddressPayload = {
                                doctor_delivery_address_id: Number(
                                  address.id ?? "0"
                                ),
                                doctor_id: selectedSurgeonDetails?.id,
                                hospital_id:
                                  userProfileDetails?.hospital?.id ?? 0,
                              };
                              updateDefaultAddress(payload);
                              setIsEditAddress(false);
                              setIsAddNewAddress(false);
                            }}
                            onCancel={() => {
                              setIsEditAddress(false);
                              setIsAddNewAddress(false);
                            }}
                          />
                        </Box>
                      );
                    }
                  )}
              </Box>
              {(isAddNewAddress || isAddButton) && (
                <Box sx={{ marginTop: "20px" }}>
                  <AddressForm
                    onCancel={() => {
                      setIsAddNewAddress(false);
                    }}
                    onSave={(deliveryAddress: DeliveryAddressPayload) => {
                      const payload: DeliveryAddressPayload = {
                        ...deliveryAddress,
                        doctor_id: selectedSurgeonDetails?.id ?? 0,
                      };
                      if (isAddButton) {
                        setValue("street_address", payload?.street_address);
                        setValue("state", payload?.state);
                        setValue(
                          "additional_address_detail",
                          payload.additional_address_detail
                        );
                        setValue("suburb", payload.suburb);
                        setValue("postcode", payload.suburb);
                        setValue("latitude", payload.latitude);
                        setValue("longitude", payload.longitude);
                        setValue("email", payload.email);
                        setValue("telephone", payload.telephone);
                        setValue("provider_number", payload.provider_number);
                        setValue("fax_number", payload.fax_number);
                        setValue("health_link", payload.health_link);
                      } else {
                        addNewAddress(payload);
                      }
                    }}
                    isLoading={isSavingAddressDetails}
                    formRegister={isAddButton ? register : null}
                    formSchema={isAddButton ? addSurgeonSchema : null}
                    formErrors={isAddButton ? errors : null}
                    setFormValue={isAddButton ? setValue : null}
                    clearFormErros={isAddButton ? clearErrors : null}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
        {message && (
          <Box mt={2}>
            <LabelBox
              boxProps={{ height: 50, display: "flex", alignItems: "center" }}
              data={message}
              onClose={() => setMessage(null)}
            />
          </Box>
        )}
        {(isAddButton ||
          (!isEmpty(selectedSurgeonDetails) &&
            !isAddNewAddress &&
            !isEditAddress)) && (
          <Box className={style.buttons} sx={{ marginBottom: "15px" }}>
            <Button
              sx={{
                width: "200px",
                height: "55px",
                ":hover": { backgroundColor: "transparent" },
              }}
              variant="outlined"
              onClick={() => navigation(-1)}
              label={"Back"}
            />
            <Button
              sx={{
                width: "200px",
                height: "55px",
                ":hover": { backgroundColor: colors.primary },
              }}
              variant="contained"
              label={"Save"}
              loading={isLoading}
              onClick={handleSubmit(onSubmit)}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AddSurgeon;
