import clsx from "clsx";
import { SyntheticEvent, useEffect, useState } from "react";
import { Box } from "@mui/material";
import OTPInput from "react-otp-input";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router-dom";

// importing css and images
import { ReactComponent as Pegasus } from "../../assets/svg/PegasusLogo.svg";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import style from "./authentication.module.css";
import { colors } from "../../theme/colors";

// importing custom components
import { useLoginMutation, useTwoFactorAuthMutation } from "../../services/api";
import { login as loginAction } from "../../store/authReducer";
import { useAppDispatch } from "../../store";
import { otpSchema } from "../../yup";
import { Loader } from "../../components";

function Authentication() {
  /*
   * useForm hook from react-hook-form
   * Manages form state, validation, and submission logic.
   * - handleSubmit: Triggers the provided callback on form submission.
   * - setValue: Used to set the email value in payload.
   * - formState: Provides access to form state, such as errors
   * - yupResolver(otpSchema): Restrict the type of payload data for required, min length etc
   */
  const { watch, setValue, getValues, handleSubmit, reset } =
    useForm<TwoFactorPayload>({
      resolver: yupResolver(otpSchema),
      defaultValues: {
        email: "",
        otp: "",
      },
    });

  // Handles the two-factor authentication mutation logic.
  const [
    twoFactorAuth,
    { isLoading: verifyLoading, isError, reset: resetError },
  ] = useTwoFactorAuthMutation();

  // Login mutation hook
  const [login, { isLoading: resendLoading }] = useLoginMutation();

  // for showing error messages
  const [message, setMessage] = useState<LabelMessageType | null>(null);

  // Retrieves the current location object, including state information.
  const { state } = useLocation();

  // Provides a function to navigate to different pages within the application.
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  /*
   * Handles the submission of the two-factor authentication form.
   * payload: Object containing data from the two-factor authentication form.
   * twoFactorAuth: Function to trigger the two-factor authentication mutation.
   */
  const onSubmit = async (payload: TwoFactorPayload) => {
    try {
      const data = await twoFactorAuth(payload).unwrap();
      const { access, refresh } = data.token;

      // Store the token in local storage and navigate to the dashboard
      if (access && refresh) {
        dispatch(
          loginAction({
            authToken: access,
            refreshToken: refresh,
            userRole: data.user_role,
          })
        );
        navigate("/home", { replace: true });
        return;
      }
      throw new Error("Token not found!");
    } catch (error: any) {
      setMessage({
        type: "error",
        text: error.message || error?.data?.detail || "Something went wrong!",
      });
    } finally {
      document.getElementById("otp-input-0")?.focus();
      reset({
        email: payload.email,
        otp: "",
      });
    }
  };

  // watch the otp have filled successfully
  const otpLength = watch("otp").length;
  const isInputFilled = otpLength >= 6 ? true : false;

  // useEffect to automatically submitting the form when the user enter the otp
  useEffect(() => {
    if (isInputFilled) {
      onSubmit(getValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInputFilled]);

  // useEffect to set the email value in the form based on the state from the router location
  useEffect(() => {
    if (state?.loginPayload) {
      setValue("email", state.loginPayload.email);
    } else {
      // if the email not found it redirect to the login page
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // method to resend verification code
  const resendCode = async (ev: SyntheticEvent) => {
    try {
      ev.preventDefault();
      resetError();
      const data = await login(state.loginPayload).unwrap();
      setMessage({
        type: "success",
        text:
          data?.message ||
          "A OTP has been sent to your email address to login.",
      });
    } catch (error: any) {
      setMessage({
        type: "error",
        text: error.message || error?.data?.detail || "Something went wrong!",
      });
    }
  };

  // Render the otp form for two factor authentication
  return (
    <Box
      sx={{
        display: "grid",
        placeItems: "center",
        height: "calc(100vh - 97px)",
      }}
    >
      <Box className={style.boxShadow}>
        <Box
          sx={{
            display: "grid",
            placeItems: "center",
            height: "100%",
          }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Pegasus />
          <Box className={style.inputs}>
            <Typography
              paddingBlockEnd={2}
              textAlign="center"
              noWrap
              component="p"
              fontSize={16}
              fontWeight={700}
            >
              Two-factor authentication
            </Typography>
            <OTPInput
              value={watch("otp")}
              onChange={(otp) => {
                // if we have error and the user is typing something so remove error message
                isError && resetError();
                setValue("otp", otp);
              }}
              numInputs={6}
              renderSeparator={<span></span>}
              renderInput={(props, index) => (
                <input
                  {...props}
                  // to remove the focus from the last input element
                  id={`otp-input-${index}`}
                  style={{
                    ...props.style,
                  }}
                />
              )}
              inputStyle={clsx(isError && style.optError, style.otpInput)}
              containerStyle={clsx(
                isError && style.errorAnimate,
                style.inputContainer
              )}
              inputType="tel"
            />
            {isError && message?.type === "error" && (
              <p className={style.error}>
                <ErrorOutlineOutlinedIcon
                  fontSize="small"
                  sx={{
                    color: colors.error,
                  }}
                />
                <Typography
                  paddingLeft="2px"
                  component="a"
                  fontSize="10px"
                  fontWeight={400}
                >
                  {message?.text
                    ? message.text
                    : "Incorrect verification code."}
                </Typography>
              </p>
            )}
          </Box>

          <Typography
            noWrap
            component="p"
            fontSize={16}
            fontWeight={500}
            textAlign="center"
            maxWidth="80%"
            sx={{
              overflow: "inherit",
              whiteSpace: "inherit",
              textOverflow: "inherit",
            }}
          >
            A message with a verification code has been sent to{" "}
            {state?.loginPayload.email}. Please enter the code to continue.
          </Typography>

          <Box minHeight="55px">
            {resendLoading || verifyLoading ? (
              <Box display="flex" alignItems="center">
                <Loader logoStyle={style.loderStyle} />
                <Typography paddingLeft="10px" fontSize="14px">
                  {verifyLoading && "Verifying...."}
                  {resendLoading && "Sending...."}
                </Typography>
              </Box>
            ) : (
              <Typography
                variant="body1"
                onClick={resendCode}
                color={colors.primary}
                sx={{
                  cursor: "pointer",
                  textDecoration: "none",
                  ":hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Did not get a verification code?
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default Authentication;
