import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import theme from "./theme";

// Create a root for rendering using ReactDOM.createRoot
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// Render the application inside the root
root.render(
  // Wrap the application in React Strict Mode for additional development checks
  <React.StrictMode>
    {/* 
        Use the Provider component to make the Redux store accessible to the entire application 
      */}
    <Provider store={store}>
      {/* 
        ThemeProvider provides the application with a theme. Wrap the entire application with the App component
      */}
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <Router>
            <App />
            <Toaster position="bottom-center" />
          </Router>
        </HelmetProvider>
      </ThemeProvider>

      {/* Toaster component for displaying toast notifications */}
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
