import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { FieldErrors } from "react-hook-form";

import style from "./input.module.css";
import { colors } from "../../theme/colors";

const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;

interface InputProps {
  name: string;
  register: any;
  label?: string;
  error?: boolean;
  errors?: FieldErrors;
  required?: boolean;
  multiple?: boolean;
  height?: string;
  width?: string;
  borderRadius?: string;
  defaultValue?: any;
  onImageSelected?: (args: any) => void;
  value?: any;
}

const ImageInput = (props: InputProps) => {
  const {
    multiple = false,
    height,
    onImageSelected,
    width,
    borderRadius,
    defaultValue,
    label,
    required,
    name,
    error,
  } = props;

  const [imageFiles, setImageFiles] = useState<any>([]);
  const [images, setImages] = useState([]);

  const changeHandler = (e: any) => {
    const { files } = e.target;
    const validImageFiles: any = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type.match(imageTypeRegex)) {
        validImageFiles.push(file);
      }
    }

    if (validImageFiles.length) {
      setImageFiles(validImageFiles);
      if (onImageSelected) {
        onImageSelected(validImageFiles);
      }
      return;
    }
  };

  useEffect(() => {
    const images: any = [],
      fileReaders: any = [];
    let isCancel = false;
    if (imageFiles.length) {
      imageFiles.forEach((file: any) => {
        const fileReader = new FileReader();
        fileReaders.push(fileReader);
        fileReader.onload = (e) => {
          const { result } = e.target as any;
          if (result) {
            images.push(result);
          }
          if (images.length === imageFiles.length && !isCancel) {
            setImages(images);
          }
        };
        fileReader.readAsDataURL(file);
      });
    }
    return () => {
      isCancel = true;
      fileReaders.forEach((fileReader: any) => {
        if (fileReader.readyState === 1) {
          fileReader.abort();
        }
      });
    };
  }, [imageFiles]);

  return (
    <Box sx={{ flex: 1, flexDirection: "column" }}>
      <Box
        className={style.imageField}
        sx={{
          flex: 1,
          width: width,
          minHeight: height,
          maxHeight: height,
          borderRadius: borderRadius ?? 8,
          borderColor: error && required ? colors.red : colors.grey,
        }}
      >
        <input
          // {...register(name)}
          key={name}
          type="file"
          onChange={changeHandler}
          accept="image/*"
          style={{
            position: "absolute",
            minHeight: height,
            maxHeight: height,
            width: width,
            opacity: 0,
            borderRadius: borderRadius ?? 8,
          }}
          multiple={multiple}
        />
        {images.length > 0 ? (
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              borderRadius: borderRadius ?? 12,
            }}
          >
            {images.map((image, idx) => {
              return (
                <img
                  src={image}
                  alt=""
                  style={{
                    padding: 5,
                    minHeight: height,
                    maxHeight: height,
                    objectFit: "contain",
                    overflow: "hidden",
                    borderRadius: borderRadius ?? 12,
                  }}
                />
              );
            })}
          </Box>
        ) : Boolean(defaultValue) ? (
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              borderRadius: borderRadius ?? 12,
            }}
          >
            <img
              src={defaultValue}
              alt=""
              style={{
                padding: 5,
                minHeight: height,
                maxHeight: height,
                objectFit: "contain",
                overflow: "hidden",
                borderRadius: borderRadius ?? 12,
              }}
            />
          </Box>
        ) : null}
      </Box>
      {error && required && (
        <Typography sx={{ color: colors.red, fontSize: "12px" }}>
          {`* ${label} is required`}
        </Typography>
      )}
    </Box>
  );
};
export default ImageInput;
