import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Button } from "../components";
import { colors } from "../theme/colors";

// Functional component representing page shown on an unknown url
function NotFound() {
  const navigation = useNavigate();
  return (
    <Box
      height="100vh"
      display="flex"
      rowGap={5}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Typography variant="h3">Not found</Typography>
      <Button
        size="large"
        variant="contained"
        onClick={() => navigation(-1)}
        label={"Back"}
        sx={{ ":hover": { backgroundColor: colors.primary } }}
      />
    </Box>
  );
}

export default NotFound;
