import { useCallback, useState } from "react";
import { Box, Input as MuiInput, Tabs, Tab, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { ReactComponent as SearchIcon } from "../../assets/svg/Search.svg";
import { colors } from "../../theme/colors";
import {
  useGetHospitalsQuery,
  useGetLaboratoriesQuery,
} from "../../services/api";
import { CustomTable, StatusBadge } from "../../components/DataTable";
import { LabelBox } from "../../components";
import style from "./user.module.css";

// Functional component representing the Home page
function Users() {
  const navigate = useNavigate();
  // all query parameters and pagination params
  const [queryParam, setQueryParam] = useState({
    name: "",
    limit: 10,
    offset: 0,
  });
  const [message, setMessage] = useState<LabelMessageType | null>(null);
  const [selectedTab, setSelectedTab] = useState("hospital");

  const { data: hospitalData, isSuccess: hopitalSuccess } =
    useGetHospitalsQuery(queryParam, {
      skip: selectedTab !== "hospital",
    });
  const { data: laboratoryData, isSuccess: laboratorySuccess } =
    useGetLaboratoriesQuery(queryParam, {
      skip: selectedTab !== "laboratory",
    });

  const AddressComponent = ({ item }: any) => {
    return (
      <Typography
        className={style.cell}
        sx={{
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        }}
      >
        {`${item["street_address"] ?? ""} ${item["suburb"] ?? ""} ${
          item["state"] ?? ""
        } ${item["postcode"] ?? ""}`}
      </Typography>
    );
  };

  // hospital columns
  const hospitalFields = [
    { id: "name", label: "Institution Name", flex: 2, sortable: true },
    {
      id: "street_address",
      label: "Address",
      type: "address",
      flex: 2.5,
      render: (item: Hospital) => <AddressComponent item={item} />,
    },
    {
      id: "general_enquiries_contact_number",
      label: "Phone Number",
      type: "phone_number",
    },
    {
      id: "is_active",
      label: "Status",
      type: "badge",
      sortable: true,
      render: (item: Hospital) => <StatusBadge isActive={item.is_active} />,
    },
  ];

  // laboratory columns
  const laboratoryFields = [
    { id: "name", label: "Institution Name", flex: 2, sortable: true },
    {
      id: "street_address",
      label: "Address",
      type: "address",
      flex: 2.5,
      render: (item: Laboratory) => <AddressComponent item={item} />,
    },
    {
      id: "general_enquiries_contact_number",
      label: "Phone Number",
      type: "phone_number",
    },
    {
      id: "is_active",
      label: "Status",
      type: "badge",
      sortable: true,
      render: (item: Laboratory) => <StatusBadge isActive={item.is_active} />,
    },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
    setQueryParam({ name: "", offset: 0, limit: 10 });
  };

  // for searching in the query
  const handleSearch = useCallback(
    (e: any) => {
      setQueryParam((prevParams) => ({
        ...prevParams,
        name: e.target.value,
        offset: 0, // Reset offset when performing a new search
      }));
    },
    [setQueryParam]
  );

  // handle the page change
  const handlePageChange = useCallback(
    (e: any, newOffset: number) => {
      setQueryParam((prevParams) => ({
        ...prevParams,
        offset: prevParams.limit * newOffset,
      }));
    },
    [setQueryParam]
  );

  // handle the page size change
  const handleSizeChange = useCallback(
    (e: any) => {
      setQueryParam((prevParams) => ({
        ...prevParams,
        limit: e.target.value,
      }));
    },
    [setQueryParam]
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: colors.lightGrey,
          alignItems: "center",
          padding: "10px 15px",
          marginTop: "15px",
        }}
        gap={2}
      >
        <Box width={"100%"} sx={{ backgroundColor: "primary.light" }}>
          <Tabs value={selectedTab} onChange={handleChange} variant="fullWidth">
            <Tab value="hospital" label="Hospital" wrapped />
            <Tab value="laboratory" label="Laboratory" />
            <Tab value="global" label="Global" />
            <Tab value="other" label="Other" />
          </Tabs>
        </Box>
        <MuiInput
          disableUnderline
          placeholder={`Search by ${selectedTab}....`}
          type="search"
          startAdornment={<SearchIcon width={20} height={20} />}
          value={queryParam.name}
          onChange={handleSearch}
          sx={{
            width: "400px",
            gap: "8px",
            marginBottom: 0,
            backgroundColor: "primary.light",
            borderRadius: "50px",
          }}
        />
      </Box>
      {message && (
        <Box mt={2}>
          <LabelBox
            boxProps={{ height: 50, display: "flex", alignItems: "center" }}
            data={message}
            onClose={() => setMessage(null)}
          />
        </Box>
      )}
      <Box
        style={{
          maxHeight: "calc(100vh - 195px)",
          overflow: "auto",
          marginTop: "10px",
        }}
      >
        {selectedTab === "hospital" && hopitalSuccess && (
          <CustomTable<Hospital>
            fields={hospitalFields}
            data={hospitalData?.items || []}
            onDoubleClick={(prop: any) => {
              navigate(`${prop?.id}`, {
                state: {
                  label: prop.name,
                },
              });
            }}
            paginationRequired={true}
            pagination={queryParam}
            count={hospitalData?.count}
            handlePageChange={handlePageChange}
            onSizeChange={handleSizeChange}
            defaultOrderBy={"name"}
          />
        )}
        {selectedTab === "laboratory" && laboratorySuccess && (
          <CustomTable<Laboratory>
            fields={laboratoryFields}
            data={laboratoryData?.items || []}
            onDoubleClick={(prop: any) => {
              navigate(`${prop?.id}`, {
                state: {
                  label: prop.name,
                },
              });
            }}
            paginationRequired={true}
            pagination={queryParam}
            count={laboratoryData?.count}
            handlePageChange={handlePageChange}
            onSizeChange={handleSizeChange}
            defaultOrderBy={"name"}
          />
        )}
      </Box>
    </Box>
  );
}
export default Users;
