import { Route, Routes } from "react-router-dom";

// layout imports
import AuthLayout from "./layouts/AuthLayout";
import BasicLayout from "./layouts/BasicLayout";

import AboutUs from "./pages/AboutUs";
import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login/Login";
import Contact from "./pages/Contact";
import Authentication from "./pages/Authentication/Authentication";
import ForgotPassword from "./pages/ForgotPassword";
import Services from "./pages/Services";
import Technology from "./pages/Technology";
import Home from "./pages/Home";
import Hospitals from "./pages/Hospital/Hospitals";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import AddHospital from "./pages/Hospital/AddHospital";
import HospitalDetails from "./pages/Hospital/HospitalDetails";
import EditHospital from "./pages/Hospital/EditHospital";
import Laboratories from "./pages/Laboratory/Laboratories";
import AddLaboratory from "./pages/Laboratory/AddLaboratory";
import LaboratoryDetails from "./pages/Laboratory/LaboratoryDetails";
import EditLaboratory from "./pages/Laboratory/EditLaboratory";
import ViewSpecimens from "./pages/Specimen/ViewSpecimens";
import SpecimenForm from "./pages/Specimen/SpecimenForm";
import Specimens from "./pages/Specimen/Specimens";
import Users from "./pages/User/Users";
import AddUser from "./pages/User/AddUser";
import UserRoles from "./pages/User/UserRoles";
import AddUserRole from "./pages/User/AddUserRole";
import Nurses from "./pages/Nurse/Nurses";
import AddNurse from "./pages/Nurse/AddNurse";
import UserRoleDetails from "./pages/User/UserRoleDetails";
import EditUserRole from "./pages/User/EditUserRole";
import Admin from "./pages/Admin/Admin";
import AddAdmin from "./pages/Admin/AddAdmin";
import AdminDetails from "./pages/Admin/AdminDetails";
import EditAdmin from "./pages/Admin/EditAdmin";
import UserList from "./pages/User/UserList";
import SpecimenDetails from "./pages/Specimen/SpecimenDetails";
import Surgeons from "./pages/Surgeon/Surgeons";
import SurgeonDetails from "./pages/Surgeon/SurgeonDetails";
import AddSurgeon from "./pages/Surgeon/AddSurgeon";
import EditSurgeon from "./pages/Surgeon/EditSurgeon";
import OperatingTheatres from "./pages/OperatingTheatre/OperatingTheatres";
import OperatingTheatreDetails from "./pages/OperatingTheatre/OperatingTheatreDetails";
import AddOperatingTheatre from "./pages/OperatingTheatre/AddOperatingTheatre";
import EditOperatingTheatre from "./pages/OperatingTheatre/EditOperatingTheatre";
import NurseDetails from "./pages/Nurse/NurseDetails";
import EditNurse from "./pages/Nurse/EditNurse";

// Main App component
function App() {
  return (
    // BrowserRouter provides the navigation context for the app
    // {/* Routes component manages the application's routing */}
    <Routes>
      {/* Route for pages with the BasicLayout (non-authenticated pages) */}
      <Route element={<BasicLayout />}>
        <Route path="/" element={<Services />} />
        <Route path="/technology" element={<Technology />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/login" element={<Login />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/authentication" element={<Authentication />} />
        <Route path="/changepassword" element={<ChangePassword />} />
      </Route>
      {/* Routes for pages with AuthLayout */}
      <Route element={<AuthLayout />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/home" element={<Home />} />
        <Route path="/hospitals" element={<Hospitals />} />
        <Route path="/hospitals/add" element={<AddHospital />} />
        <Route path="/hospitals/details/:id" element={<HospitalDetails />} />
        <Route path="/hospitals/edit/:id" element={<EditHospital />} />
        <Route path="/laboratories" element={<Laboratories />} />
        <Route path="/laboratories/add" element={<AddLaboratory />} />
        <Route
          path="/laboratories/details/:id"
          element={<LaboratoryDetails />}
        />
        <Route path="/laboratories/edit/:id" element={<EditLaboratory />} />
        <Route path="/users" element={<Users />} />
        <Route path="/users/:id" element={<UserList />} />
        <Route path="/user/add" element={<AddUser />} />
        <Route path="/user/roles" element={<UserRoles />} />
        <Route path="/user/roles/add" element={<AddUserRole />} />
        <Route path="/user/roles/details/:id" element={<UserRoleDetails />} />
        <Route path="/user/roles/edit/:id" element={<EditUserRole />} />

        <Route path="/nurses" element={<Nurses />} />
        <Route path="/nurses/add" element={<AddNurse />} />
        <Route path="/nurses/details/:id" element={<NurseDetails />} />
        <Route path="/nurses/edit/:id" element={<EditNurse />} />

        {/* Admin routes  */}
        <Route path="/admins" element={<Admin />} />
        <Route path="/admins/add" element={<AddAdmin />} />
        <Route path="/admins/details/:id" element={<AdminDetails />} />
        <Route path="/admins/edit/:id" element={<EditAdmin />} />

        <Route path="/specimens" element={<Specimens />} />
        <Route path="/specimens/view/:id" element={<ViewSpecimens />} />
        <Route path="/specimens/form/:id" element={<SpecimenForm />} />
        <Route path="/specimens/details/:id" element={<SpecimenDetails />} />

        <Route path="/surgeons" element={<Surgeons />} />
        <Route path="/surgeons/details/:id" element={<SurgeonDetails />} />
        <Route path="/surgeons/add" element={<AddSurgeon />} />
        <Route path="/surgeons/edit/:id" element={<EditSurgeon />} />

        <Route path="/operating-theatres" element={<OperatingTheatres />} />
        <Route
          path="/operating-theatres/details/:id"
          element={<OperatingTheatreDetails />}
        />
        <Route
          path="/operating-theatres/add"
          element={<AddOperatingTheatre />}
        />
        <Route
          path="/operating-theatres/edit/:id"
          element={<EditOperatingTheatre />}
        />
      </Route>
      {/* Route for handling 404 errors */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

// Export the App component as the default export
export default App;
