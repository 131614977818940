import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import authReducer from "./authReducer";
import { api } from "../services/api";

// Create the Redux store with configuration
export const store = configureStore({
  reducer: {
    // Include the authentication reducer in the store
    auth: authReducer,

    // Include the API reducer from RTK Query in the store
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    // Concatenate the default middleware with the RTK Query middleware
    getDefaultMiddleware().concat(api.middleware),
});

// Define the RootState type for the entire Redux store state
export type RootState = ReturnType<typeof store.getState>;

// Define the AppDispatch type for the Redux store dispatch function
export type AppDispatch = typeof store.dispatch;

// Create a custom hook for useDispatch with the AppDispatch type
export const useAppDispatch: () => AppDispatch = useDispatch;

// Create a custom hook for useSelector with the RootState type
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
