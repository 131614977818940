import { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

interface DateProp {
  label: string;
  value: string | any; // value should be required for controlled component
  handleDate: (date: any | null) => void; // define type for handleDate
}

export default function CustomDatePicker(props: DateProp) {
  const { label, value, handleDate } = props;

  const momentValue = typeof value === "string" ? moment(value) : value;

  // Use local state to manage the value
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(
    momentValue
  );

  const handleChange = (date: moment.Moment | null) => {
    setSelectedDate(date); // Update local state
    handleDate(date); // Call the handler passed from parent
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label={label}
        slotProps={{
          textField: {
            fullWidth: true,
          },
        }}
        value={selectedDate ? moment(selectedDate) : null} // Pass selectedDate as value
        onChange={handleChange} // Use handleChange instead of handleDate directly
      />
    </LocalizationProvider>
  );
}
