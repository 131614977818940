import { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { usePlacesWidget } from "react-google-autocomplete";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import { hospitalSchema } from "../../yup";
import Input from "../../components/UserInput/Input";
import {
  useUpdateHospitalDetailsMutation,
  useGetHospitalDetailsQuery,
} from "../../services/api";
import style from "./hospital.module.css";
import { Button, LabelBox } from "../../components";
import { appConfig } from "../../hooks/useConfig";
import { colors } from "../../theme/colors";

type FormState = typeof hospitalSchema.__outputType;

function EditHospital() {
  const [updateHospital] = useUpdateHospitalDetailsMutation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [faxNumberSelected, setFaxNumberSelected] = useState(false);
  const [emailSelected, setEmailSelected] = useState(false);
  const [error, setError] = useState(false);
  const [deliveryModeError, setDeliveryModeError] = useState(false);
  const [isSavingHospital, setIsSavingHospital] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  // Get Hospital Details mutation hook
  const {
    data: hospitalDetails = {},
    isLoading,
    isFetching,
  } = useGetHospitalDetailsQuery(id ?? "");

  const [message, setMessage] = useState<LabelMessageType | null>(null);

  /*
   * useForm hook from react-hook-form
   * Manages form state, validation, and submission logic.
   * - register: Registers input fields and gathers their values.
   * - handleSubmit: Triggers the provided callback on form submission.
   * - formState: Provides access to form state, such as errors
   * - yupResolver(hospitalSchema): Restrict the type of payload data for required, min length etc
   */
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
    clearErrors,
  } = useForm<FormState>({
    resolver: yupResolver(hospitalSchema),
    mode: "onChange",
  });

  const { ref: materialRef } = usePlacesWidget({
    apiKey: appConfig?.GOOGLE_API_KEY,
    inputAutocompleteValue: "off",
    options: {
      types: [
        "street_address",
        "street_number",
        "intersection",
        "route",
        "health",
      ],
      componentRestrictions: { country: "au" },
    },
    onPlaceSelected: (place) => {
      let suburb = "";
      let state = "";
      let postal = "";
      if (place) {
        clearErrors("postcode");
        clearErrors("suburb");
        clearErrors("state");
        const address_comp = place?.address_components;
        if (address_comp) {
          for (const c of address_comp) {
            const types: Array<string> = c.types;
            if (
              !suburb &&
              (types.includes("locality") ||
                types.includes("sublocality") ||
                types.includes("sublocality_level_1") ||
                types.includes("postal_town") ||
                types.includes("administrative_area_level_3"))
            ) {
              suburb = c.short_name;
            }

            if (types.includes("administrative_area_level_1")) {
              state = c.short_name;
            } else if (types.includes("postal_code")) {
              postal = c.short_name;
            }
          }
          const address_arr = place?.formatted_address?.split(", ");
          if (address_arr.length > 3) {
            setValue("street_address", address_arr.slice(0, 2).join(", "));
          } else {
            setValue("street_address", address_arr[0]);
          }
          setValue("state", state);
          setValue("suburb", suburb);
          setValue("postcode", postal);
          const lat: any = get(place, "geometry.location.lat", () => {});
          const lng: any = get(place, "geometry.location.lng", () => {});
          setLatitude(lat() ?? 0);
          setLongitude(lng() ?? 0);
        }
      }
    },
  });

  useEffect(() => {
    if (!isEmpty(hospitalDetails)) {
      setFaxNumberSelected(hospitalDetails?.is_delivery_by_fax ?? false);
      setEmailSelected(hospitalDetails?.is_delivery_by_mail ?? false);
      reset({
        name: hospitalDetails?.name,
        provider_number: hospitalDetails?.provider_number,
        general_enquiries_contact_number:
          hospitalDetails?.general_enquiries_contact_number,
        account_manager_email_address:
          hospitalDetails?.account_manager_email_address,
        fax_number: hospitalDetails?.fax_number ?? "",
        delivery_email: hospitalDetails?.delivery_email ?? "",
        postcode: hospitalDetails?.postcode,
        state: hospitalDetails?.state,
        suburb: hospitalDetails?.suburb,
        street_address: hospitalDetails?.street_address,
        mob_operating_theatre_manager:
          hospitalDetails?.mob_operating_theatre_manager,
      });
      setLatitude(hospitalDetails?.latitude);
      setLongitude(hospitalDetails?.longitude);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospitalDetails]);

  const onSubmit = async (values: FormState) => {
    try {
      const {
        street_address,
        fax_number,
        delivery_email,
        is_delivery_by_fax,
        is_delivery_by_mail,
        mob_operating_theatre_manager,
        general_enquiries_contact_number,
        ...rest
      } = values;
      if (delivery_email === "" && fax_number === "") {
        setDeliveryModeError(true);
        return;
      }
      if (!faxNumberSelected && !emailSelected) {
        setError(true);
        return;
      }
      setIsSavingHospital(true);
      const payload: EditHospitalPayload = {
        ...rest,
        id: parseInt(id ?? "0"),
        general_enquiries_contact_number:
          general_enquiries_contact_number?.replace(/ /g, ""),
        mob_operating_theatre_manager: mob_operating_theatre_manager?.replace(
          / /g,
          ""
        ),
        fax_number: fax_number?.replace(/ /g, "") as any,
        delivery_email,
        street_address,
        is_delivery_by_fax: faxNumberSelected,
        is_delivery_by_mail: emailSelected,
        latitude,
        longitude,
      };
      await updateHospital(payload).unwrap();
      setIsSavingHospital(false);
      navigate(-1);
    } catch (error: any) {
      setMessage({
        type: "error",
        text: error?.message?.toString() || "Please enter valid details",
      });
      setIsSavingHospital(false);
    }
  };

  return (
    <Box className={style.container}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        {(isFetching || isLoading) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              width: "calc(100vw - 400px)",
              zIndex: 999,
              backgroundColor: "transparent",
              position: "absolute",
            }}
          >
            <CircularProgress size={40} />
          </Box>
        )}
        <Box>
          <Typography
            className={style.detailHeading2}
            sx={{ marginTop: "10px" }}
          >
            Hospital Details
          </Typography>
          <Box className={style.flexTop}>
            <Box className={style.row}>
              <Input
                placeholder="Enter name"
                label="Hospital Name"
                required
                register={register}
                name="name"
                errors={errors}
              />
              <Input
                placeholder="Enter hospital provider name"
                label="Hospital Provider Number"
                required
                register={register}
                name="provider_number"
                errors={errors}
              />
            </Box>
            <Box className={style.row}>
              <Input
                placeholder="Enter street address"
                label="Street Address"
                required
                register={register}
                name="street_address"
                errors={errors}
                inputRef={materialRef}
              />
              <Input
                placeholder="Select suburb"
                label="Suburb"
                required
                register={register}
                name="suburb"
                errors={errors}
              />
            </Box>
            <Box className={style.row}>
              <Input
                placeholder="Select state"
                label="State"
                required
                register={register}
                name="state"
                errors={errors}
              />
              <Input
                placeholder="Enter postcode"
                label="Postcode"
                required
                register={register}
                name="postcode"
                errors={errors}
              />
            </Box>
            <Typography className={style.detailHeading}>
              Contact Details
            </Typography>
            <Box className={style.flexBottom}>
              <Box className={style.row}>
                <Input
                  placeholder="Enter phone number"
                  label="General Inquiries"
                  required
                  register={register}
                  name="general_enquiries_contact_number"
                  errors={errors}
                />
                <Input
                  placeholder="Enter operating theatre manager contact number"
                  label="Operating Theatre Manager"
                  required
                  register={register}
                  name="mob_operating_theatre_manager"
                  errors={errors}
                />
              </Box>
              <Box className={style.row}>
                <Input
                  placeholder="Enter accounts manager email address"
                  label="Email Address (Accounts Manager)"
                  required
                  register={register}
                  name="account_manager_email_address"
                  errors={errors}
                />
              </Box>
              <Typography className={style.detailHeading}>
                Request Form Delivery Details (Medical Records)
              </Typography>
              <Box className={style.row}>
                <Input
                  placeholder="Enter email address"
                  label="Email"
                  register={register}
                  name="delivery_email"
                  errors={errors}
                  onFocus={() => {
                    setDeliveryModeError(false);
                  }}
                  onChange={(event) => {
                    setValue("delivery_email", event.target.value);
                    if (event.target.value === "") {
                      setEmailSelected(false);
                    }
                  }}
                />
                <Input
                  placeholder="Enter fax number"
                  label="Fax Number"
                  register={register}
                  name="fax_number"
                  errors={errors}
                  onFocus={() => {
                    setDeliveryModeError(false);
                  }}
                  onChange={(event) => {
                    setValue("fax_number", event.target.value, {
                      shouldValidate: true,
                    });
                    clearErrors("fax_number");
                    if (event.target.value === "") {
                      setFaxNumberSelected(false);
                    }
                  }}
                />
              </Box>
              {deliveryModeError && (
                <Typography sx={{ color: colors.red, fontSize: "12px" }}>
                  * Please provide either Email or Fax Number.
                </Typography>
              )}
              <Typography className={style.detailHeading}>
                Mode of Delivery
              </Typography>
              <Box sx={{ marginLeft: 1 }}>
                <FormControlLabel
                  sx={{ "& span": { py: 0, px: 0.3 } }}
                  control={
                    <Checkbox
                      {...register("is_delivery_by_mail")}
                      sx={{ color: error ? colors.red : colors.black }}
                      onChange={() => {
                        setError(false);
                        setEmailSelected(!emailSelected);
                      }}
                      checked={emailSelected}
                    />
                  }
                  label="Email"
                  disabled={!watch("delivery_email")}
                />
                <FormControlLabel
                  sx={{ "& span": { py: 0, px: 0.3 } }}
                  control={
                    <Checkbox
                      {...register("is_delivery_by_fax")}
                      sx={{ color: error ? colors.red : colors.black }}
                      onChange={() => {
                        setError(false);
                        setFaxNumberSelected(!faxNumberSelected);
                      }}
                      checked={faxNumberSelected}
                    />
                  }
                  label="Fax Number"
                  disabled={!watch("fax_number")}
                />
              </Box>
              {error && (
                <Typography sx={{ color: colors.red, fontSize: "12px" }}>
                  * Please select atleast one mode of delivery
                </Typography>
              )}
            </Box>
          </Box>
          {message && (
            <Box mt={2}>
              <LabelBox
                boxProps={{
                  height: 50,
                  display: "flex",
                  alignItems: "center",
                }}
                data={message}
                onClose={() => setMessage(null)}
              />
            </Box>
          )}
          <Box className={style.buttons}>
            <Button
              sx={{
                width: "200px",
                height: "55px",
                ":hover": { backgroundColor: "transparent" },
              }}
              variant="outlined"
              onClick={() => navigate(-1)}
              label={"Cancel"}
            />
            <Button
              sx={{
                width: "200px",
                height: "55px",
                ":hover": { backgroundColor: colors.primary },
              }}
              variant="contained"
              type="submit"
              label={"Save"}
              loading={isSavingHospital}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default EditHospital;
