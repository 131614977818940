import { useState } from "react";
import {
  Box,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  Table as DataTable,
  TableSortLabel,
  TablePagination,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import orderBy from "lodash/orderBy";

import styles from "./table.module.css";

interface Field {
  id: string;
  label: string;
  type?: string | undefined;
  flex?: number;
  sortable?: boolean;
  render?: (prop: any) => JSX.Element;
}

interface CustomTableProps<T> {
  fields: Field[];
  data: ResultType<T> | any;
  isLargeRow?: boolean;
  onDoubleClick?: (item: any) => void;
  paginationRequired?: boolean;
  defaultOrder?: "asc" | "desc";
  defaultOrderBy?: string;
  handlePageChange: (e: any, value: number) => void;
  onSizeChange?: any;
  count?: number | undefined | any;
  pagination?: {
    name?: string | undefined;
    limit: any;
    offset: any;
  };
  onOrderChange?: () => void;
  onOrderByChange?: (arg: string) => void;
}

function CustomTable<T>(props: CustomTableProps<T>) {
  // Destructuring props to extract specific values
  const {
    data,
    count,
    fields,
    isLargeRow,
    onDoubleClick,
    paginationRequired = true,
    defaultOrder = "asc",
    defaultOrderBy = "",
    handlePageChange, // for changing the page
    onSizeChange, // for changing the size fo the table
    pagination, // for all pagination related properties
    onOrderChange,
    onOrderByChange,
  } = props;

  const [order, setOrder] = useState<"asc" | "desc">(defaultOrder);
  const [orderby, setOrderby] = useState(defaultOrderBy);

  const createSortHandler = (property: any) => {
    const isDesc = orderby === property && order === "desc";
    setOrderby(property);
    setOrder(isDesc ? "asc" : "desc");
  };

  const getOrderedList = () => {
    return orderBy(data, [(item: any) => item[orderby]], order);
  };

  const orderedList: any[] = getOrderedList();

  // Render the table component
  return (
    <Box>
      <TableContainer
        sx={{
          background: "transparent",
        }}
      >
        <DataTable>
          <TableHead>
            <TableRow
              className={clsx(styles.rowBox, styles.tableHead, styles.tableRow)}
            >
              {fields.map(
                ({ sortable, label, id, flex = 1 }: any, index: number) => {
                  return (
                    <TableCell
                      key={index}
                      className={styles.headerCell}
                      align="center"
                      width="100%"
                      sx={{ flex, borderBottomWidth: "0px !important" }}
                      sortDirection={orderby === id ? order : false}
                    >
                      {sortable ? (
                        <TableSortLabel
                          active={orderby === id}
                          direction={orderby === id ? order : "asc"}
                          onClick={() => {
                            createSortHandler(id);
                            if (onOrderChange) {
                              onOrderChange();
                            }
                            if (onOrderByChange) {
                              onOrderByChange(id);
                            }
                          }}
                        >
                          {label}
                        </TableSortLabel>
                      ) : (
                        <>{label}</>
                      )}
                    </TableCell>
                  );
                }
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {orderedList.map((item: any, index: number) => (
              <TableRow
                className={clsx(
                  styles.rowBox,
                  isLargeRow && styles.largeRow,
                  styles.tableRow
                )}
                key={index}
                hover
                sx={{ cursor: "pointer" }}
                onDoubleClick={() => onDoubleClick && onDoubleClick(item)}
              >
                {fields.map(
                  ({ id, flex = 1, render = false }: any, index: any) => {
                    return (
                      <TableCell
                        key={index}
                        className={styles.cell}
                        align="center"
                        width="100%"
                        sx={{
                          flex,
                          display: "flex",
                          borderBottomWidth: "0px !important",
                        }}
                      >
                        {render ? (
                          render(item)
                        ) : (
                          <Typography
                            className={styles.cell}
                            sx={{
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: "2",
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                            }}
                          >
                            {item[id] ?? "-"}
                          </Typography>
                        )}
                      </TableCell>
                    );
                  }
                )}
              </TableRow>
            ))}
          </TableBody>
        </DataTable>
        {paginationRequired && count > 10 && (
          <TablePagination<any>
            component="div"
            count={count}
            page={pagination?.offset / pagination?.limit}
            onPageChange={handlePageChange}
            rowsPerPage={pagination?.limit}
            onRowsPerPageChange={onSizeChange}
            showFirstButton
            showLastButton
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginLeft: "-20px",
            }}
          />
        )}
      </TableContainer>
    </Box>
  );
}

export default CustomTable;
