import { useCallback, useState } from "react";
import { Box, Input as MuiInput, Tabs, Tab, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

import { ReactComponent as SearchIcon } from "../../assets/svg/Search.svg";
import { LabelBox } from "../../components";
import { colors } from "../../theme/colors";
import { useGetUsersListQuery } from "../../services/api";
import { CustomTable } from "../../components/DataTable";
import style from "./../Admin/admin.module.css";
import { CustomActionMenu } from "../../components/Menu";
import { ReactComponent as DeactivateIcon } from "../../assets/svg/deactivate.svg";
import { ReactComponent as EditIcon } from "../../assets/svg/edit.svg";
import { ReactComponent as EyeIcon } from "../../assets/svg/eye.svg";
import Logs from "../../assets/images/logs.png";

// Functional component representing the Home page
function Users() {
  const { id } = useParams();

  const [selectedTab, setSelectedTab] = useState("OT_ADMIN");
  const [message, setMessage] = useState<LabelMessageType | null>(null);

  const [queryParam, setQueryParam] = useState({
    search_key: "",
    limit: 10,
    offset: 0,
    hospital_id: id,
    order_by_columns: "created_at",
    order: "dsc",
    is_pagination_required: true,
    user_role: "OT_ADMIN",
  });

  const fields = [
    {
      id: "last_name",
      label: "Name",
      flex: 2,
      sortable: true,
      type: "name",
      render: (item: Users) => (
        <Typography
          className={style.cell}
          sx={{
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {`${item?.last_name?.toUpperCase() ?? ""}, ${
            item?.first_name ?? ""
          } ${item?.prefix ?? ""}`}
        </Typography>
      ),
    },
    {
      id: "email",
      label: "Email Address",
      flex: 2.5,
      type: "name",
      render: (item: Users) => (
        <Typography
          className={style.cell}
          sx={{
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {item?.email ?? ""}
        </Typography>
      ),
    },
    {
      id: "created_at",
      label: "Added",
      flex: 1,
      type: "date",
      sortable: true,
      render: (item: Users) => (
        <Typography
          className={style.cell}
          sx={{
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {`${dayjs(item["created_at"]).format("DD/MM/YYYY") ?? ""}`}
        </Typography>
      ),
    },
    {
      id: "is_active",
      label: "Status",
      type: "badge",
      flex: 1.5,
      sortable: true,
      render: (item: Users) => (
        <Box
          className={style.statusContainer}
          sx={{
            backgroundColor: item?.has_changed_initial_password
              ? item?.is_active
                ? colors.lightGreen
                : "#fecaca"
              : colors.lightGreen,
          }}
        >
          <Typography
            className={style.cell}
            sx={{
              color: item?.has_changed_initial_password
                ? item?.is_active
                  ? "#15803d"
                  : "#b91c1c"
                : "#15803d",
            }}
          >
            {!item?.has_changed_initial_password
              ? "Pending Activation"
              : item?.is_active
              ? "Active"
              : "Inactive"}
          </Typography>
        </Box>
      ),
    },
    {
      id: "logo_s3_key_name",
      label: "Actions",
      type: "action",
      flex: 0.5,
      render: (item: Users) => (
        <CustomActionMenu
          item={item}
          isActive={item?.is_active ?? false}
          handleChange={handleMenu}
          actions={
            !item?.has_changed_initial_password
              ? [
                  {
                    label: "View",
                    value: "view",
                    icon: <EyeIcon height={24} width={24} />,
                  },
                  {
                    label: "Edit",
                    value: "edit",
                    icon: <EditIcon height={24} width={24} />,
                  },
                  {
                    label: "Logs",
                    value: "logs",
                    icon: <img src={Logs} alt="Logs" width={24} />,
                  },
                ]
              : [
                  {
                    label: "View",
                    value: "view",
                    icon: <EyeIcon height={24} width={24} />,
                  },
                  {
                    label: "Edit",
                    value: "edit",
                    icon: <EditIcon height={24} width={24} />,
                  },
                  {
                    label: "Logs",
                    value: "logs",
                    icon: <img src={Logs} alt="Logs" width={24} />,
                  },
                  {
                    label: item.is_active ? "Deactivate" : "Activate",
                    value: item.is_active ? "deactivate" : "activate",
                    icon: item.is_active ? (
                      <DeactivateIcon height={24} width={24} />
                    ) : (
                      <PowerSettingsNewIcon height={24} width={24} />
                    ),
                  },
                ]
          }
        />
      ),
    },
  ];

  const { data: adminData } = useGetUsersListQuery(queryParam);

  const handleMenu = (value: string, admin: Users) => {};

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  // for searching in the query
  const handleSearch = useCallback(
    (e: any) => {
      setQueryParam((prevParams) => ({
        ...prevParams,
        search_key: e.target.value === "" ? undefined : e.target.value,
        offset: 0, // Reset offset when performing a new search
      }));
    },
    [setQueryParam]
  );

  // handle the page change
  const handlePageChange = useCallback(
    (e: any, newOffset: number) => {
      setQueryParam((prevParams) => ({
        ...prevParams,
        offset: prevParams.limit * newOffset,
      }));
    },
    [setQueryParam]
  );

  // handle the page size change
  const handleSizeChange = useCallback(
    (e: any) => {
      setQueryParam((prevParams) => ({
        ...prevParams,
        limit: e.target.value,
      }));
    },
    [setQueryParam]
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: colors.lightGrey,
          alignItems: "center",
          padding: "10px 15px",
        }}
        gap={2}
      >
        <Box width={"100%"} sx={{ backgroundColor: "primary.light" }}>
          <Tabs value={selectedTab} onChange={handleChange} variant="fullWidth">
            <Tab value="NURSE" label="Nurse" wrapped />
            <Tab value="DOCTOR" label="Doctor" />
            <Tab value="OT_ADMIN" label="Admin" />
            <Tab value="it" label="IT" />
          </Tabs>
        </Box>
        <MuiInput
          disableUnderline
          placeholder={`Search by ${selectedTab}....`}
          type="search"
          startAdornment={<SearchIcon width={20} height={20} />}
          value={queryParam.search_key}
          onChange={handleSearch}
          sx={{
            width: "400px",
            gap: "8px",
            marginBottom: 0,
            backgroundColor: "primary.light",
            borderRadius: "50px",
          }}
        />
      </Box>
      {message && (
        <Box mt={2}>
          <LabelBox
            boxProps={{ height: 50, display: "flex", alignItems: "center" }}
            data={message}
            onClose={() => setMessage(null)}
          />
        </Box>
      )}
      <Box
        style={{
          maxHeight: "calc(100vh - 195px)",
          overflow: "auto",
          marginTop: "10px",
        }}
      >
        {selectedTab === "OT_ADMIN" && (
          <CustomTable<Users>
            fields={fields}
            paginationRequired={true}
            count={adminData?.count}
            data={adminData?.items || []}
            handlePageChange={handlePageChange}
            onSizeChange={handleSizeChange}
            pagination={queryParam}
            onDoubleClick={(admin: Users) => {
              // navigate(`/admins/details/${admin?.id}`);
            }}
            defaultOrderBy={"created_at"}
            defaultOrder={queryParam?.order === "dsc" ? "desc" : "asc"}
            onOrderChange={() => {
              setQueryParam((prevParams) => ({
                ...prevParams,
                order: prevParams?.order === "asc" ? "dsc" : "asc",
              }));
            }}
            onOrderByChange={(column: string) => {
              setQueryParam((prevParams) => ({
                ...prevParams,
                order_by_columns: column,
              }));
            }}
          />
        )}
      </Box>
    </Box>
  );
}
export default Users;
