import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { cloneDeep } from "lodash";

import { userSchema } from "../../yup";
import Input from "../../components/UserInput/Input";
import style from "./user.module.css";
import { Button, LabelBox } from "../../components";
import { colors } from "../../theme/colors";
import { SingleSelectInput } from "../../components/SelectInput";
import {
  useCreateUserAdminMutation,
  useGetHospitalsQuery,
  useGetUserRolesQuery,
  useGetLaboratoriesQuery,
} from "../../services/api";

type FormState = typeof userSchema.__outputType;

function AddUser() {
  const navigation = useNavigate();

  const { data: hospitalData, isSuccess: hospitalSuccsss } =
    useGetHospitalsQuery("");
  const { data: adminRoleList, isSuccess: roleListSuccess } =
    useGetUserRolesQuery({ is_active: true });
  const { data: laboratoryData, isSuccess: laboratoryListSuccess } =
    useGetLaboratoriesQuery({ is_active: true });

  const [createUser] = useCreateUserAdminMutation();

  const [message, setMessage] = useState<LabelMessageType | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [hospitalList, setHospitalList] = useState([]);
  const [role, setRole] = useState([]);
  const [laboratories, setLaboratories] = useState([]);

  // when the laborator get successfully get so we have to update the list
  useEffect(() => {
    if (hospitalSuccsss) {
      // formate the api data
      const formateData = hospitalData.items.map((hospital: any) => {
        return {
          id: hospital?.id,
          label: hospital?.name,
          value: hospital?.name,
        };
      });
      // set the hospital list to the formated data
      setHospitalList(formateData ?? []);
    }
  }, [hospitalData?.items, hospitalSuccsss]);

  useEffect(() => {
    if (roleListSuccess) {
      // formate the api data
      const formatttedData = adminRoleList?.items?.map((role: any) => {
        return {
          id: role?.id,
          label: role?.name,
          value: role?.name,
        };
      });
      // set the user role list to the formated data
      setRole(formatttedData ?? []);
    }
  }, [adminRoleList, roleListSuccess]);

  useEffect(() => {
    if (roleListSuccess) {
      // formate the api data
      const formatttedData = adminRoleList?.items?.map((role: any) => {
        return {
          id: role?.id,
          label: role?.name,
          value: role?.name,
        };
      });
      // set the user role list to the formated data
      setRole(formatttedData ?? []);
    }
  }, [adminRoleList, roleListSuccess]);

  useEffect(() => {
    if (laboratoryListSuccess) {
      // formate the api data
      const formatttedData = laboratoryData?.items?.map((laboratory: any) => {
        return {
          id: laboratory?.id,
          label: laboratory?.name,
          value: laboratory?.name,
        };
      });
      // set the user role list to the formated data
      setLaboratories(formatttedData ?? []);
    }
  }, [laboratoryData, laboratoryListSuccess]);

  /*
   * useForm hook from react-hook-form
   * Manages form state, validation, and submission logic.
   * - register: Registers input fields and gathers their values.
   * - handleSubmit: Triggers the provided callback on form submission.
   * - formState: Provides access to form state, such as errors
   * - yupResolver(userSchema): Restrict the type of payload data for required, min length etc
   */
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FormState>({
    resolver: yupResolver(userSchema),
    mode: "onChange",
  });

  const isLabSelected =
    watch("user_role")?.toLowerCase().includes("lab") ||
    watch("user_role")?.toLowerCase().includes("courier");

  const onSubmit = async (values: any) => {
    try {
      const payload = cloneDeep(values);

      const selectedInstitution: any = isLabSelected
        ? laboratories.filter((e: any) => e.label === payload.hospital_id)
        : hospitalList.filter((e: any) => e.label === payload.hospital_id);

      payload.hospital_id = isLabSelected
        ? undefined
        : `${selectedInstitution[0].id}`;
      payload.laboratory_id = isLabSelected
        ? `${selectedInstitution[0].id}`
        : undefined;
      payload.create_automatic_password = true;
      payload.password_change_required_for_first_sign_in = true;
      payload.email_user_creds = true;
      payload.prefix = "Dr.";
      payload.device_mob_number = payload.device_mob_number ?? "";
      await createUser(payload).unwrap();
      navigation(-1);
    } catch (error: any) {
      setMessage({
        type: "error",
        text: error?.data?.detail?.toString() || "Please enter valid details",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setValue("hospital_id", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("user_role")]);

  return (
    <Box className={style.container}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Typography className={style.detailHeading2}>User Details</Typography>
        <Box className={style.flexTop}>
          <Box className={style.row}>
            <Input
              placeholder="Enter first name"
              label="First Name"
              required
              register={register}
              name="first_name"
              errors={errors}
            />
            <Input
              placeholder="Enter last name"
              label="Last Name"
              required
              register={register}
              name="last_name"
              errors={errors}
            />
          </Box>
          <Box className={style.row}>
            <Input
              placeholder="Eg. OT Manager"
              label="Title"
              required
              register={register}
              name="title"
              errors={errors}
            />
            <Input
              placeholder="Enter email address"
              label="Email Address"
              required
              register={register}
              name="email"
              errors={errors}
            />
          </Box>
          <Box className={style.row}>
            <SingleSelectInput
              placeholder="Select user role"
              label="User Role"
              required
              register={register}
              name="user_role"
              errors={errors}
              data={role ?? []}
              error={!!errors.user_role}
            />
            <Input
              placeholder="Enter phone number"
              label="Phone Number"
              required
              register={register}
              name="contact_number"
              errors={errors}
            />
          </Box>
          <Box className={style.row}>
            <Input
              placeholder="Mobile Number"
              label="Mobile Number"
              required={isLabSelected}
              register={register}
              name="device_mob_number"
              errors={errors}
            />
            <SingleSelectInput
              placeholder="Select Institution"
              label="Institution Name"
              required
              value={watch("hospital_id")}
              register={register}
              data={
                watch("user_role") === ""
                  ? []
                  : watch("user_role")?.toLowerCase().includes("lab") ||
                    watch("user_role")?.toLowerCase().includes("courier")
                  ? laboratories
                  : hospitalList ?? []
              }
              name="hospital_id"
              errors={errors}
            />
          </Box>
        </Box>
        {message && (
          <Box mt={2}>
            <LabelBox
              boxProps={{ height: 50, display: "flex", alignItems: "center" }}
              data={message}
              onClose={() => setMessage(null)}
            />
          </Box>
        )}
        <Box className={style.buttons}>
          <Button
            sx={{
              width: "200px",
              height: "55px",
              ":hover": { backgroundColor: "transparent" },
            }}
            variant="outlined"
            onClick={() => navigation(-1)}
            label={"Back"}
          />
          <Button
            sx={{
              width: "200px",
              height: "55px",
              ":hover": { backgroundColor: colors.primary },
            }}
            variant="contained"
            type="submit"
            label={"Save & Send Invitation"}
            loading={isLoading}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default AddUser;
